import React, { useState } from "react";
import "./BankPaymentOffersPopup.css"; // Make sure this file exists and contains the required styles
import Close from "../../assets/images/close.svg"; // Import the Close icon
import BanxaIcon from "../../assets/images/Payment/banxa.png";
import MoonpayIcon from "../../assets/images/Payment/moonpay.svg";
import TransakIcon from "../../assets/images/Payment/transak.svg";
import WertIcon from "../../assets/images/Payment/wert.svg";
import { useTranslation } from "react-i18next";
import { getTokenCheckExpiryAndRefreshIfNeeded } from "../../utils/auth";
import ProceedButton from "../ProceedButton/ProceedButton";
import SpinnerLoading from "../Loading/SpinnerLoading";

const IMAGES_MAP = {
  transak: TransakIcon,
  wert: WertIcon,
  moonpay: MoonpayIcon,
  banxa: BanxaIcon,
};

function capitalizeFirstLetter(string) {
  if (typeof string !== "string" || !string) {
    return ""; // or return string; if you want to keep the original value
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const BankPaymentOffersPopup = ({
  onBankPayment,
  onClose,
  responseData,
  amount,
  userId,
  signedInUsername,
}) => {
  const [selectedOffer, setSelectedOffer] = useState(null); // State to track selected offer
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle list item click
  const handleItemClick = (offer) => {
    setSelectedOffer(offer);
  };
  const handleSubmit = async () => {
    let hasError = false;
    if (selectedOffer === null) {
      setErrorMessage(t("selectError"));
      hasError = true;
    } else {
      setErrorMessage("");
    }
    if (!hasError) {
      try {
        setLoading(true);
        const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

        if (!accessToken) {
          console.error("Access token not found"); // set user logged out
          return;
        }
        console.log(amount);
        console.log(selectedOffer.fee);
        const response = await fetch(
          `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/createChangellyDeposit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              amount: Number(amount) + Number(selectedOffer.fee),
              providerCode: selectedOffer.providerCode,
            }),
          }
        );

        if (response.ok) {
          setLoading(false);
          const data = await response.json();
          onBankPayment(amount, data.data);
        } else {
          setLoading(false);
          const errorData = await response.json();
          setErrorMessage(
            errorData.errors[0].errorType +
              " - " +
              errorData.errors[0].errorMessage
          );
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage("Error fetching data: " + error.message);
      }
    }
  };
  return (
    <div className="bank-payment-popup-offers-overlay">
      {responseData == null ? (
        <SpinnerLoading />
      ) : (
        <div className="bank-payment-popup-offers">
          <div className="popup-header">
            <h2 className="bankPopupOffersHeader">Deposit Providers</h2>
            <button className="close" onClick={onClose}>
              <img src={Close} alt="close" />
            </button>
          </div>

          <div className="popup-content-offers">
            {responseData && responseData.length > 0 ? (
              <ul className="provider-list">
                {responseData.map((offer, index) => (
                  <li
                    key={index}
                    className={`provider-item ${
                      offer?.providerCode === selectedOffer?.providerCode
                        ? "provider-selected"
                        : ""
                    } `}
                    onClick={() => handleItemClick(offer)} // Add click event
                    style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickability
                  >
                    <p>
                      <strong>Provider:</strong>{" "}
                      {IMAGES_MAP[offer.providerCode] ? (
                        <div className="img-wrapper">
                          <img alt="" src={IMAGES_MAP[offer.providerCode]} />
                        </div>
                      ) : (
                        capitalizeFirstLetter(offer.providerCode)
                      )}
                    </p>
                    <p>
                      <strong>Fee:</strong> {offer.fee}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No offers available.</p>
            )}
          </div>

          <div className="bank-popup-offers-footer">
            <ProceedButton
              text={t("proceed")}
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BankPaymentOffersPopup;
