import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import './GamePage.css';
import BackArrow from '../../assets/images/BackArrow.svg';
import FullScreen from '../../assets/images/full-screen.svg';
import ExitFullScreen from '../../assets/images/exit-full-screen.svg'; // Import exit full screen icon
import { useTranslation } from "react-i18next";
import { RecentlyPlayedContext } from '../../context/RecentlyPlayedContext';
import { getTokenCheckExpiryAndRefreshIfNeeded } from "../../utils/auth";

const GamePage = () => {
    const { gameName, gameName2 } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { gameProvider, rootPage, buttonImage, gameTitle, hasLive = false, searchBarValue, selectedCategories, selectedProviders, sortOption, typeOption, category } = location.state;
    const [bonusGameUrl, setBonusGameUrl] = useState('');
    const [cashGameUrl, setCashGameUrl] = useState('');
    const [funGameUrl, setFunGameUrl] = useState('');
    const [mode, setMode] = useState('cash');
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    const { addRecentlyPlayed } = useContext(RecentlyPlayedContext);
    const iframeRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const navigateHomeIfDeep = useCallback(() => {
        const state = {
            searchBarValue,
            selectedCategories,
            selectedProviders,
            sortOption,
            typeOption,
            category
        };

        if (rootPage === "home") {
            navigate('/', { state });
        } else if (rootPage === "viewAll") {
            navigate('/viewall', { state });
        } else if (rootPage === "categories") {
            navigate('/categories/' + category, { state });
        } else if (rootPage === "livegames") {
            navigate('/livegames', { state });
        } else {
            navigate('/casino', { state });
        }
    }, [navigate, rootPage, searchBarValue, selectedCategories, selectedProviders, sortOption, typeOption, category]);

    useEffect(() => {
        const createGameSession = async () => {
            const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

            try {
                const response = await fetch(`${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/sessions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                        'accept': 'text/plain'
                    },
                    body: JSON.stringify({
                        gameName: gameTitle,
                        gameIdentifier: gameName2,
                        goWinWalletName: mode,
                        isLive: hasLive
                    }),
                });
                const data = await response.json();

                if (!response.ok) {
                    console.log(response)
                    // Check for the specific error message
                    const error = data.errors && data.errors.find(e => e.errorMessage.includes('Country is not allowed'));
                    if (error) {
                        setErrorMessage(t('gameNotAvailableInCountry'));
                    } else {
                        throw new Error('Failed to create game session');
                    }
                    return;
                }

                if (mode === 'cash') {
                    setCashGameUrl(data.data.launch_options.game_url);
                } else if (mode === 'bonus') {
                    setBonusGameUrl(data.data.launch_options.game_url);
                } else {
                    setFunGameUrl(data.data.launch_options.game_url);
                }

                // Add the game to recently played
                addRecentlyPlayed({
                    gameName,
                    gameName2,
                    gameProvider,
                    rootPage,
                    buttonImage,
                    gameTitle,
                    hasLive
                });

                // If the device is mobile, activate full-screen mode
                if (isMobile) {
                    setIsFullScreen(true);
                }

            } catch (error) {
                console.error('Error creating game session:', error);
            }
        };

        createGameSession();
    }, [gameName, gameName2, gameProvider, gameTitle, mode, addRecentlyPlayed, buttonImage, rootPage, hasLive, t, isMobile]);

    const handleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const currentGameUrl = mode === 'cash' ? cashGameUrl : mode === 'bonus' ? bonusGameUrl : funGameUrl;

    return (
        <div className={`game-page-container ${isFullScreen ? 'full-screen' : ''} ${isMobile ? 'mobile' : ''}`}>
            <div className="game-page-header">
                <button className="back-button" onClick={navigateHomeIfDeep}>
                    <img src={BackArrow} alt="Back" className="back-arrow-icon" />
                </button>
                <h1 className="game-title">{gameTitle}</h1>
                <button className="fullscreen-button" onClick={handleFullScreen}>
                    <img src={isFullScreen ? ExitFullScreen : FullScreen} alt="Full Screen" className="fullscreen-icon" />
                </button>
            </div>

            {currentGameUrl ? (
                <iframe
                    ref={iframeRef}
                    src={currentGameUrl}
                    title={gameName}
                    className="responsive-iframe"
                ></iframe>
            ) : (
                <p className="loadingGame">{errorMessage || t('loadingGame')}</p>
            )}
            {!isFullScreen && (
                <div className="toggle-container">
                    <button
                        className={`toggle-button ${mode === 'cash' ? 'active' : ''}`}
                        onClick={() => setMode('cash')}
                    >
                        {t('cashWalletUpper')}
                    </button>
                    <button
                        className={`toggle-button ${mode === 'bonus' ? 'active' : ''}`}
                        onClick={() => setMode('bonus')}
                    >
                        {t('bonusTokensUpper')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default GamePage;
