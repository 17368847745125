import React from "react";
import "./sports.css";
import EventsPageBannerCards from "../../components/EventsPageBannerCards/EventsPageBannerCards";
import Subheader from "../../components/PageSubheader/PageSubheader";
import CategorySection from '../../components/CategorySection/CategorySection';
import { useTranslation } from 'react-i18next';

//Category Images
import BasketballImage from '../../assets/images/SportsImages/Basketball.jpg';
import FootballImage from '../../assets/images/SportsImages/Football.jpg';
import RugbyImage from '../../assets/images/SportsImages/Rugby.jpg';
import BoxingImage from '../../assets/images/SportsImages/Boxing.jpg';
import HorseRacingImage from '../../assets/images/SportsImages/HorseRacing.jpg';

import Meta from '../../utils/Meta';


const Sports = () => {
    const { t } = useTranslation();
    const categories = [
        { text: t('basketball'), image: BasketballImage },
        { text: t('football'), image: FootballImage },
        { text: t('rugby'), image: RugbyImage },
        { text: t('boxing'), image: BoxingImage },
        { text: t('horseRacing'), image: HorseRacingImage },
    ];

    return (
        <div className="sports">
            <Meta
                title="Secure Crypto Betting Experience with Top Sportsbook 2024 - GO WIN"
                description="World-class sports betting with GO WIN's sports book. Enjoy a secure, regulated platform to place bets on your teams and events using latest crypto technology."
            />

            <EventsPageBannerCards className="sports-banner-cards" TypeOfPage="sports" />
            <Subheader className="sports-subheader" headerText={t('sportsBetting')} />
            <CategorySection className="sports-categories" sectionName={t('sportsBettingCategories')} categories={categories} />

            <div className="coming-soon">
                <h1>{t('comingSoon')}</h1>
            </div>

        </div>
    )
};
export default Sports;