import React from "react";
import "./AlertDialog.css";
import { useTranslation } from "react-i18next";

const AlertDialog = ({ isOpen, onClose, message }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;
  return (
    <div className="alert-dialog-overlay" onClick={onClose}>
      <div className="alert-dialog-content" onClick={e => e.stopPropagation()}>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default AlertDialog;
