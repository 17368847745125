import React, { useState, useEffect } from 'react';
import './AccountInfo.css';
import CustomButton from '../../components/Button/button';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getUserById, getCountryList, updateUser, createOtp, validateOtp } from '../../utils/api';
import OneTimePasswordNoSecurityWords from '../../components/OneTimePasswordNoSecurityWordsPopup/OneTimePasswordNoSecurityWordsPopup';
import { scrollToCenter, scrollToBottom } from "../../utils/scrollToTop";

const AccountInfo = () => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState({
        email: '',
        userName: '',
        btcAddress: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        country: '',
        city: '',
        addressLine1: '',
        phoneNumber: ''
    });
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [otpVisible, setOtpVisible] = useState(false);

    useEffect(() => {
        const userId = localStorage.getItem('userId');

        const fetchData = async () => {
            try {
                const [userResponse, countryResponse] = await Promise.all([
                    getUserById(userId),
                    getCountryList()
                ]);

                if (userResponse.success && countryResponse.success) {
                    const userDetails = userResponse.data;
                    const countryOptions = countryResponse.data.map(country => ({
                        value: country.id,
                        label: country.name
                    }));

                    const userCountry = countryOptions.find(country => country.value === userDetails.countryId);
                    const dateOfBirth = userDetails.dateOfBirth ? new Date(userDetails.dateOfBirth) : '';

                    setUserData({
                        id: userDetails.id,
                        firstName: userDetails.firstName || '',
                        lastName: userDetails.lastName || '',
                        userName: userDetails.userName || '',
                        btcAddress: userDetails.btcAddress || '',
                        email: userDetails.email || '',
                        phoneNumber: userDetails.phoneNumber || '',
                        addressLine1: userDetails.addressLine1 || '',
                        city: userDetails.city || '',
                        country: userCountry ? userCountry.label : '',
                        dateOfBirth: dateOfBirth,
                        countryId: userDetails.countryId || ''
                    });
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        setUserData(prevState => ({
            ...prevState,
            dateOfBirth: date
        }));
    };

    const validate = () => {
        const nameRegex = /^[a-zA-Z][a-zA-Z .-]*$/;
        const phoneRegex = /^\+?[0-9]+(-[0-9]+)*$/;
        const btcAddressRegex = /^[a-zA-Z0-9]*$/;

        if (userData.btcAddress.trim()) {
            if (!btcAddressRegex.test(userData.btcAddress)) {
                return t('invalidBtcAddress');
            }
            if (userData.btcAddress.length > 62) {
                return t('btcAddressTooLong');
            }
        }

        if (!userData.firstName.trim() || !userData.lastName.trim()) {
            return t('nameRequired');
        } else if (!nameRegex.test(userData.firstName) || !nameRegex.test(userData.lastName)) {
            return t('invalidName');
        }

        if (!userData.dateOfBirth) {
            return t('dateOfBirthRequired');
        } else {
            const age = new Date().getFullYear() - userData.dateOfBirth.getFullYear();
            if (age < 18) {
                return t('ageRequirement');
            }
        }

        if (!userData.phoneNumber.trim()) {
            return t('phoneNumberRequired');
        } else if (!phoneRegex.test(userData.phoneNumber)) {
            return t('invalidPhoneNumber');
        }

        return '';
    };


    const handleSave = async () => {
        setLoading(true);
        setAlertMessage('');
        scrollToBottom();

        const validationError = validate();
        if (validationError) {
            setAlertMessage(validationError);
            setAlertType('danger');
            setLoading(false);
            return;
        }

        try {
            await createOtp(userData.email);
            scrollToCenter();
            setOtpVisible(true);
        } catch (error) {
            console.error("Error creating OTP: ", error);
            setAlertMessage(t('failedToSendCode'));
            setAlertType('danger');
            setLoading(false);
        }
    };

    const handleOtpClose = async (otp) => {
        setOtpVisible(false);
        if (otp) {
            setLoading(true);
            try {
                const otpResponse = await validateOtp(userData.email, otp);
                if (otpResponse.success) {
                    const updateData = {
                        id: userData.id,
                        userName: userData.userName,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        dateOfBirth: userData.dateOfBirth,
                        email: userData.email,
                        phoneNumber: userData.phoneNumber,
                        addressLine1: userData.addressLine1 || "",
                        city: userData.city || "",
                        countryId: userData.countryId,
                        btcAddress: userData.btcAddress,
                        emailConfirmed: true,
                        goWin: true
                    };

                    const response = await updateUser(updateData);
                    if (response.success) {
                        setAlertMessage(t('profileUpdatedSuccessfully'));
                        scrollToBottom();
                        setAlertType('success');
                    } else {
                        setAlertMessage(response.message || t('updateFailed'));
                        setAlertType('danger');
                    }
                } else {
                    setAlertMessage(t('invalidCode'));
                    setAlertType('danger');
                }
            } catch (error) {
                console.error("Error updating user: ", error);
                setAlertMessage(t('updateFailed'));
                setAlertType('danger');
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <div className="account-info-container">
            <div className="account-info-form">
                <div className="form-group">
                    <input type="hidden" id="userId" value={userData.id || ''} readOnly />
                </div>
                <div className="form-group">
                    <label>{t('email')}</label>
                    <input type="email" value={userData.email || ''} readOnly />
                </div>
                <div className="form-group">
                    <label>{t('username')}</label>
                    <input type="text" value={userData.userName || ''} readOnly />
                </div>
                <div className="form-group">
                    <label>{t('BTCAddress')}</label>
                    <input type="text" name="btcAddress" value={userData.btcAddress || ''} onChange={handleInputChange} />
                </div>
                <div className="section-divider"></div>

                <div className="inline-group">
                    <div className="form-group">
                        <label>{t('firstName')}</label>
                        <input type="text" name="firstName" value={userData.firstName || ''} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label>{t('lastName')}</label>
                        <input type="text" name="lastName" value={userData.lastName || ''} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="form-group">
                    <label>{t('dateOfBirth')}</label>
                    <DatePicker
                        selected={userData.dateOfBirth}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        placeholderText="Select a date"
                    />
                </div>
                <div className="section-divider"></div>

                <div className="inline-group">
                    <div className="form-group">
                        <label>{t('country')}</label>
                        <input type="text" value={userData.country || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>{t('city')}</label>
                        <input type="text" name="city" value={userData.city || ''} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="form-group">
                    <label>{t('address')}</label>
                    <input type="text" name="addressLine1" value={userData.addressLine1 || ''} onChange={handleInputChange} />
                </div>
                <div className="section-divider"></div>

                <div className="form-group">
                    <label>{t('phoneNumber')}</label>
                    <input type="tel" name="phoneNumber" value={userData.phoneNumber || ''} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <CustomButton
                        buttonType="loggedOutYellowButton"
                        text={loading ? <div className="otp-loading-spinner"></div> : t('saveChanges')}
                        secondaryText=""
                        onClick={handleSave}
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    {alertMessage && (
                        <div className={`alert alert-${alertType} mt-3`}>
                            {alertMessage}
                        </div>
                    )}
                </div>
            </div>
            {otpVisible && <OneTimePasswordNoSecurityWords onClose={handleOtpClose} email={userData.email} />}
        </div>
    );
};

export default AccountInfo;
