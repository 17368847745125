import React, { useState, useRef, useEffect } from "react";
import "./OneTimePasswordNoSecurityWordsPopup.css";
import { useTranslation } from "react-i18next";
import { validateOtp, createOtp } from "../../utils/api";
import Close from "../../assets/images/close.svg";

const OneTimePasswordNoSecurityWords = ({ onClose, email }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(120);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [error, setError] = useState("");
  const inputs = useRef([]);
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.value !== "" && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);
    try {
      await createOtp(email); // Call the createOtp API
      setTimer(120); // Reset timer
      timerRef.current = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      setError(""); // Clear any previous error
    } catch (error) {
      console.error("Error resending OTP: ", error);
      setError(t('failedToSendCode'));
    } finally {
      setResendLoading(false);
    }
  };

  const handleValidate = async () => {
    setLoading(true);
    const enteredOtp = otp.join("");
    try {
      const response = await validateOtp(email, enteredOtp); // Call the validateOtp API
      if (response.success && response.data.status !== "ValidationFailed") {
        onClose(enteredOtp);
      } else {
        setError(t('invalidCode'));
      }
    } catch (error) {
      console.error("Error validating OTP: ", error);
      setError(t('invalidCode'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="otp-popup-mobile">
      <div className="otp-mobile-page-1">
        <div className="otp-popup-header">
          {t('otpVerification')}
          <button className="otp-close" onClick={() => onClose()}>
            <img src={Close} alt="close" />
          </button>
        </div>
        <div className="otp-popup-form">
          <p className="otpSubtext">{t('otpPromptNoSecurity')}</p>
          <div className="otp-input-container">
            {otp.map((data, index) => {
              return (
                <input
                  className="otp-input"
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={e => handleChange(e.target, index)}
                  onKeyDown={e => handleKeyDown(e, index)}
                  onFocus={e => e.target.select()}
                  ref={el => inputs.current[index] = el}
                />
              );
            })}
          </div>
          {error && <div className="otp-error">{error}</div>}
          <div className="otp-timer">
            {timer > 0 ? (
              <span>{`${t('resendCodeIn')} ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}</span>
            ) : (
              <button onClick={handleResendCode} className="resend-code" disabled={resendLoading}>
                {resendLoading ? t('resendingCode') : t('resendCodeUpper')}
              </button>
            )}
          </div>
          <div className="otp-button-container">
            <button className="validate-button" onClick={handleValidate} disabled={loading}>
              {loading ? <div className="otp-loading-spinner"></div> : t('validate')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneTimePasswordNoSecurityWords;
