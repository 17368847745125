export const loadAllProviderJsonFiles = () => {
    const context = require.context("../assets/json", false, /\.json$/);
    const jsonFiles = context.keys()
        .filter(key => !key.includes('upcoming.json')) // Exclude upcoming.json
        .map(context);
    return jsonFiles;
};


// Function to load a single JSON file (upcoming.json)
export const loadUpcomingJsonFile = () => {
    return require('../assets/json/upcoming.json');
};
