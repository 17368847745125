import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import "./home.css";
import SubBannerCards from "../../components/SubBannerCards/SubBannerCards";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import { useTranslation } from 'react-i18next';
import { parseJsonToGames, filterGamesByReleaseDate, shuffleArray, generateProviderImageUrl } from '../../utils/gameUtils';
import { loadAllProviderJsonFiles, loadUpcomingJsonFile } from '../../utils/jsonLoader';
import { featureGroupsMapping, providers } from '../../utils/constants';
import SearchBar from "../../components/SearchBar/SearchBar";
import CategorySection from "../../components/CategorySection/CategorySection";
import EventSection from "../../components/EventSection/EventSection";
import CasinoSection from "../../components/CasinoSection/CasinoSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import eventsIconBlue from "../../assets/images/NavBarIcons/Blue/events-icon-blue.svg";
import casinoIconBlue from "../../assets/images/NavBarIcons/Blue/casino-icon-blue.svg";

// Import the worker
import Worker from '../../utils/searchWorkerWrapper';

// Import the Meta component
import Meta from '../../utils/Meta';

const Home = ({ categories }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [newReleases, setNewReleases] = useState([]);
  const [liveGames, setLiveCasinos] = useState([]);
  const [allCasinos, setAllCasinos] = useState([]);
  const [limitedCasinos, setLimitedCasinos] = useState([]);
  const { searchBarValue: initialSearchBarValue } = location.state || {};
  const [searchBarValue, setSearchBarValue] = useState(initialSearchBarValue || '');
  const [filteredCasinos, setFilteredCasinos] = useState([]);

  useEffect(() => {
    const rootPage = 'home';

    const jsonContents = loadAllProviderJsonFiles();
    const upcomingJsonContent = loadUpcomingJsonFile();

    // Filter games by release date, provider, and feature group for new releases
    const allGames = Object.values(upcomingJsonContent).flat();
    const newReleaseGames = filterGamesByReleaseDate(allGames, providers, featureGroupsMapping);

    // Parse the new release games
    const parsedNewReleaseGames = providers.flatMap(provider =>
      parseJsonToGames(newReleaseGames, rootPage, featureGroupsMapping, provider)
    );
    setNewReleases(parsedNewReleaseGames);

    // Standard Casino Games
    const allParsedGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider)
    );
    setAllCasinos(allParsedGames);

    // Display only the limited number of games initially
    const limitedParsedGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider, { limit: 20 })
    );
    setLimitedCasinos(shuffleArray(limitedParsedGames));

    // Live Casino Games
    const parsedLiveGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider, { hasLive: true })
    );
    setLiveCasinos(shuffleArray(parsedLiveGames));

    setFilteredCasinos(shuffleArray(limitedParsedGames));

  }, []);

  const partners = useMemo(() => providers.map(provider => ({
    image: generateProviderImageUrl(provider),
    partnerName: provider
  })), []);

  const filterItems = useCallback((items, searchTerm, keys) => {
    if (!searchTerm) return items;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter(item =>
      keys.some(key => {
        const value = item[key];
        return value && typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchTerm);
      })
    );
  }, []);

  const handleSearch = useCallback((searchTerm) => {
    if (!searchTerm) {
      setFilteredCasinos(limitedCasinos);
      return;
    }

    const worker = new Worker();

    worker.postMessage({ items: allCasinos, searchTerm, keys: ['gameProvider', 'gameTitle'] });

    worker.onmessage = (e) => {
      setFilteredCasinos(e.data);
    };

    worker.onerror = (error) => {
      console.error('Search worker error:', error);
    };

    return () => {
      worker.terminate();
    };
  }, [allCasinos, limitedCasinos]);

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  useEffect(() => {
    debouncedHandleSearch(searchBarValue);
  }, [searchBarValue, debouncedHandleSearch]);

  const filteredCategories = useMemo(() => filterItems(categories, searchBarValue, ['text']), [categories, searchBarValue, filterItems]);
  const filteredNewReleases = useMemo(() => filterItems(newReleases, searchBarValue, ['gameProvider', 'gameTitle']), [newReleases, searchBarValue, filterItems]);
  const filteredLiveGames = useMemo(() => filterItems(liveGames, searchBarValue, ['gameTitle']), [liveGames, searchBarValue, filterItems]);
  const newReleasesSortOption = { value: 'New Releases', label: `${t('newReleases')}` };
  const liveGamesSortOption = { value: 'Live Games', label: `${t('liveGames')}` };

  return (
    <div>
      <Meta
        title="GO WIN: Entertainment Platform with Licensed Casino Games and Sportsbook"
        description="GO WIN's licensed Casino and Sportsbook. Play various slots, table games, live dealer and more. Bet on your favourite sports and win big rewards. Join Now"
      />
      <HomeBanner />
      <SubBannerCards />
      <div className="home-search-container">
        <SearchBar
          searchBarValue={searchBarValue}
          setSearchBarValue={setSearchBarValue}
        />
      </div>
      <PartnersSection sectionName={t("partners")} partners={partners} />
      <CategorySection
        sectionName={t("casinoCategories")}
        categories={filteredCategories}
      />
      <EventSection
        sectionName={t("liveGames")}
        src={eventsIconBlue}
        alt="Live Games Icon"
        events={filteredLiveGames}
        typeOption={liveGamesSortOption}
      />
      <CasinoSection
        sectionName={t("casinoGames")}
        src={casinoIconBlue}
        alt="Casino Icon"
        casinos={filteredCasinos}
      />
      <CasinoSection
        sectionName={t("newReleases")}
        src={casinoIconBlue}
        alt="Casino Icon"
        casinos={filteredNewReleases}
        typeOption={newReleasesSortOption}
      />
    </div>
  );
}

export default React.memo(Home);
