import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../assets/images/go-win-logo.png";
import CustomButton from "../../components/Button/button";
import DropdownButton from '../../components/DropdownButton/DropdownButton';
import UserDropdown from '../../components/UserDropdown/UserDropdown';
import { useTranslation } from 'react-i18next';
import { useViewport } from "../../hooks/ViewportProvider";
import PopupHandler from "../../components/PopupHandler/popupHandler";
import { getFlagIcon } from "../../utils/flagIcons";
import defaultFlagIcon from "../../assets/images/NavBarIcons/flag-fill.svg";
import { useLanguage } from '../../context/LanguageContext';

const Header = ({ isLoggedIn, handleSignInClick, handleRegisterClick, handleLogout, setShowLocalizationPopup }) => {
  const { t } = useTranslation();
  const { width } = useViewport();
  const breakpoint = 900;
  const { language } = useLanguage();

  const [languageIcon, setLanguageIcon] = useState(getFlagIcon(language) || defaultFlagIcon);

  useEffect(() => {
    setLanguageIcon(getFlagIcon(language) || defaultFlagIcon);
  }, [language]);

  const signedInUsername = localStorage.getItem("signedInUsername");
  const logoClassName = width < breakpoint ? "mobileLogo" : "desktopLogo";

  const MobileHeader = (handleDepositCashClick) => (
    <header className="header-mobile">
      <div className="spacer-left"></div>
      <img className="mobileLogo" alt="Logo" src={Logo} />
      <div className="mobile-localization-button-container-div">
        <button
          className="nav-button-lang"
          onClick={() => setShowLocalizationPopup(true)}
        >
          <img src={languageIcon} alt="Language selector" />
        </button>
      </div>
    </header>
  );

  const DesktopHeader = (handleDepositCashClick) => (
    <header className="header">
      <img className={logoClassName} alt="Logo" src={Logo} />
      <div className="headerButtons">
        {!isLoggedIn && (
          <>
            <CustomButton
              buttonType="loggedOutYellowButton"
              text={t('signIn')}
              onClick={handleSignInClick}
            />
            <CustomButton
              buttonType="buyTokenBlueButton"
              text={t('register')}
              onClick={handleRegisterClick}
            />
          </>
        )}
        {isLoggedIn && (
          <>
            <UserDropdown handleLogout={handleLogout} />
            <div className="welcomeText">{t('welcome')},</div>
            <div className="welcomeUsername">{signedInUsername}</div>
            <CustomButton
              buttonType="depositCashYellowButton"
              text={`${t("wallet")}`}
              secondaryText=""
              onClick={handleDepositCashClick}
            />
            <DropdownButton />
          </>
        )}
      </div>
      <div className="localization-button-container-div">
        <button
          className="nav-button-lang"
          onClick={() => setShowLocalizationPopup(true)}
        >
          <img src={languageIcon} alt="Language selector" />
        </button>
      </div>
    </header>
  );

  return (
    <PopupHandler>
      {(handleDepositCashClick) => (
        <>
          {width < breakpoint
            ? MobileHeader(handleDepositCashClick)
            : DesktopHeader(handleDepositCashClick)}
        </>
      )}
    </PopupHandler>
  );
};

export default Header;
