import React, { useEffect, useState } from 'react';
import './Referrals.css';
import { getUserReferrals } from '../../utils/api';
import ReferralColumnsProvider from './ReferralColumns';
import InputWithCopyIcon from '../../components/InputWithCopyIcon/InputWithCopyIcon';
import { encodeSponsorUsername } from '../../utils/codeSponsorUsername';
import DTableReferrals from '../../components/DTableReferrals/DTableReferrals';
import { useTranslation } from 'react-i18next';

const Referrals = () => {
    const [data, setData] = useState(null);
    const [totalReferralProfit, setTotalReferralProfit] = useState(0);
    const { t } = useTranslation();
    const userId = localStorage.getItem('userId');
    const [referralLink, setReferralLink] = useState('');

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const signedInUsername = localStorage.getItem('signedInUsername');

        if (userId && signedInUsername) {
            const stringToEncode = `${userId}:${signedInUsername}`;
            const encodedUsername = encodeSponsorUsername(stringToEncode);
            const baseUrl = window.location.origin;
            const referralLink = `${baseUrl}/ref/${encodedUsername}`;
            setReferralLink(referralLink);
        }

        async function fetchReferrals() {
            try {
                const result = await getUserReferrals(userId);
                setData(result.data.referralsList);
                setTotalReferralProfit(result.data.totalReferralProfit);
            } catch (error) {
                console.error('Error fetching referrals:', error);
            }
        }
        fetchReferrals();
    }, [userId]);

    return (
        <div className="referrals-page-container">
            <div className="referrals-content">
                <div className="referral-link-container">
                    <h2>{t('referralLink')}</h2>
                    <InputWithCopyIcon
                        CryptoDepositAddress={referralLink}
                    />
                </div>
                <div className="referrals-table-container">
                    {data ? (
                        <ReferralColumnsProvider>
                            {(columns) => (
                                <DTableReferrals
                                    rows={data}
                                    columns={columns}
                                    totalReferralProfit={totalReferralProfit}
                                />
                            )}
                        </ReferralColumnsProvider>
                    ) : (
                        <div className="dtable-referrals-loading">{t('loadingText')}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Referrals;
