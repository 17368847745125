import React, { useCallback } from "react";
import "./SubBannerCard.css";
import useRedirect from '../../../hooks/Redirect';
import { useTranslation } from 'react-i18next';

const SubBannerCard = React.memo(({ buttonImage, redirectTo }) => {
  const redirect = useRedirect();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    redirect(redirectTo);
  }, [redirect, redirectTo]);

  return (
    <button
      className="sub-banner-card"
      onClick={handleClick}
      style={{ "--button-bg-image": `url(${buttonImage})` }} // Using CSS custom properties
    >
      <span>{t('enter')}</span>
    </button>
  );
});

export default SubBannerCard;
