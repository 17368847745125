import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import "./DTableReferrals.css";
import { useViewport } from "../../hooks/ViewportProvider";
import DatePickerButton from "../DTableAccountSettings/DatePickerButtonAccountSettings/DatePickerButtonAccountSettings";
import { ReactComponent as ClearFilterButton } from "../../assets/images/cancel.svg";
import { useTranslation } from 'react-i18next';

function DTableReferrals({ rows, columns, totalReferralProfit }) {
  const { t } = useTranslation();
  const [data, setData] = useState(rows);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange; // Destructuring the date range for easier access
  const [perPage, setPerPage] = useState(10);
  const { width: viewportWidth, height: viewportHeight } = useViewport();

  const customDTableStyles = {
    responsiveWrapper: {
      style: {
        maxHeight: "60%",
        overflowY: "auto",
        overflowX: "auto",
      },
    },
    headRow: {
      style: {
        minHeight: "unset",
        height: "fit-content",
        minWidth: "unset",
        maxWidth: "unset",
        backgroundImage: "linear-gradient(90deg, rgba(2, 96, 152, 1), rgba(7, 16, 125, 1))",
      },
    },
    headCells: {
      style: {
        fontSize:
          viewportWidth >= 1040
            ? "16px"
            : viewportWidth >= 655
              ? "10px"
              : "7px",
        fontWeight: 600,
        color: "#fff",
        fontFamily: "var(--main-font-family)",
        minHeight: "unset",
        height: "fit-content",
        padding: viewportHeight >= 400 ? "10px" : "0",
      },
    },
    cells: {
      style: {
        fontSize:
          viewportWidth >= 690 ? "14px" : viewportWidth >= 550 ? "10px" : "7px",
        fontWeight: 300,
        fontFamily: "var(--main-font-family)",
        backgroundColor: "transparent",
        borderBottom: "1px solid white",
        height: "fit-content",
        padding: viewportHeight >= 400 ? "10px" : "0",
        whiteSpace: "normal",
        wordBreak: "break-word",
        color: "white",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid white",
        minHeight: "unset",
        height: "fit-content",
        backgroundColor: "transparent",
      },
    },
    table: {
      style: {
        backgroundColor: "transparent",
      },
    },
    pagination: {
      style: {
        fontFamily: "var(--main-font-family)",
        fontWeight: 400,
        color: "white",
        backgroundColor: "transparent",
        minHeight: "unset",
        fontSize: viewportHeight >= 595 ? "14px" : "10px",
        height: "fit-content",
      },
      pageButtonsStyle: {
        fontFamily: "var(--main-font-family)",
        fontWeight: 400,
        color: "white",
        backgroundColor: "transparent",
        "&:hover:not(:disabled)": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      },
    },
  };

  useEffect(() => {
    setLoading(false);
    setData(rows);
  }, [rows]);

  useEffect(() => {
    const handleResize = () => {
      const rowHeight = 65; // Assuming each row is roughly 65px high
      const availableHeight = window.innerHeight - 250; // Subtract non-table space
      const visibleRows = Math.floor(availableHeight / rowHeight);
      setPerPage(visibleRows > 5 ? visibleRows : 5); // Ensure at least 5 rows
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleDateRange(dates) {
    setDateRange(dates);

    const [start, end] = dates;

    if (start && end) {
      const adjustedStart = new Date(start.setHours(0, 0, 0, 0));
      const adjustedEnd = new Date(end.setHours(23, 59, 59, 999));

      const filteredRows = rows.filter((row) => {
        const rowDate = new Date(row.dateOfDeposit);
        return rowDate >= adjustedStart && rowDate <= adjustedEnd;
      });

      setData(filteredRows);
    } else {
      setData(rows); // Reset data if date range is cleared
    }
  }

  function clearDateRange() {
    setDateRange([null, null]);
    setData(rows);
  }

  let options = [5, 10, 15, 20, 25, 30].filter((option) => option !== perPage);
  options.unshift(perPage);

  return (
    <>
      <div className="d-table-referrals-container">
        <div className="d-table-referrals-input-group">
          <div className="total-referral-profit">
            {t('totaldirectBonusAmount')}: <span className="profit-value">{totalReferralProfit.toLocaleString()}</span>
          </div>
          <div className="date-picker-group">
            {startDate && endDate && (
              <button className="clear-filter-button" onClick={clearDateRange}>
                <ClearFilterButton />
              </button>
            )}
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRange}
              dateFormat="dd-MMM-yyyy"
              className="referrals-date-picker"
              maxDate={new Date()}
              showYearDropdown
              scrollableMonthYearDropdown
              customInput={<DatePickerButton />}
            />
          </div>
        </div>
        {loading ? (
          <div className="spinner" />
        ) : (
          <DataTable
            key={perPage}
            columns={columns}
            data={data}
            noDataComponent={<div className="no-data-component">{t('noReferralsToDisplay')}</div>}
            defaultSortAsc={false}
            fixedHeader
            responsive
            pagination
            paginationRowsPerPageOptions={options}
            paginationDefaultPage={1}
            paginationPerPage={perPage}
            customStyles={customDTableStyles}
          />
        )}
      </div>
    </>
  );
}

export default DTableReferrals;
