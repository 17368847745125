import React, { useRef, useEffect, useMemo, useReducer, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EventButton from "../EventButton/eventButton";
import ViewAllButton from "../../components/ViewAllButton/ViewAllButton";
import { useTranslation } from "react-i18next";
import { useViewport } from "../../hooks/ViewportProvider.js";
import "./EventSection.css";

const initialState = {
  containerWidth: 0,
  singleButtonWidth: 300,
  elementHeight: 0,
  showViewAllButton: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTAINER_WIDTH":
      return {
        ...state,
        containerWidth: action.payload,
        singleButtonWidth: action.payload - 20,
      };
    case "SET_ELEMENT_HEIGHT":
      return {
        ...state,
        elementHeight: action.payload,
      };
    case "SET_SHOW_VIEW_ALL_BUTTON":
      return {
        ...state,
        showViewAllButton: action.payload,
      };
    default:
      return state;
  }
};

const EventSection = React.memo(({ sectionName, src, events, alt, typeOption }) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { width: viewportWidth } = useViewport();
  const [state, dispatch] = useReducer(reducer, initialState);
  const gap = 20;
  const elementWidth = 200; // width + gap

  const responsive = useMemo(
    () => ({
      extraLarge: {
        breakpoint: { max: 4000, min: 1600 },
        items: 7,
        partialVisibilityGutter: 80,
      },
      large: {
        breakpoint: { max: 1800, min: 900 },
        items: 6,
        partialVisibilityGutter: 40,
      },
      mediumLarge: {
        breakpoint: { max: 1600, min: 600 },
        items: 5,
        partialVisibilityGutter: 40,
      },
      medium: {
        breakpoint: { max: 1400, min: 600 },
        items: 4,
        partialVisibilityGutter: 40,
      },
      small: {
        breakpoint: { max: 1200, min: 600 },
        items: 4,
        partialVisibilityGutter: 40,
      },
      extraSmall: {
        breakpoint: { max: 1090, min: 0 },
        items: 3,
        partialVisibilityGutter: 40,
      },
      extraExtraSmall: {
        breakpoint: { max: 740, min: 0 },
        items: 2,
        partialVisibilityGutter: 40,
      },
      tiny: {
        breakpoint: { max: 500, min: 0 },
        items: 2,
        partialVisibilityGutter: 40,
      },
    }),
    []
  );

  const slidesToSlide = useMemo(() => {
    if (viewportWidth <= responsive.tiny.breakpoint.max) return responsive.tiny.items;
    if (viewportWidth <= responsive.extraExtraSmall.breakpoint.max) return responsive.extraExtraSmall.items;
    if (viewportWidth <= responsive.extraSmall.breakpoint.max) return responsive.extraSmall.items;
    if (viewportWidth <= responsive.small.breakpoint.max) return responsive.small.items;
    if (viewportWidth <= responsive.medium.breakpoint.max) return responsive.medium.items;
    if (viewportWidth <= responsive.mediumLarge.breakpoint.max) return responsive.mediumLarge.items;
    if (viewportWidth <= responsive.large.breakpoint.max) return responsive.large.items;
    if (viewportWidth <= responsive.extraLarge.breakpoint.max) return responsive.extraLarge.items;
    return 1;
  }, [viewportWidth, responsive]);

  const aspectRatio = 12 / 23;

  useEffect(() => {
    dispatch({
      type: "SET_ELEMENT_HEIGHT",
      payload: slidesToSlide === 1 ? state.singleButtonWidth * aspectRatio + gap : 120 + gap,
    });
  }, [state.singleButtonWidth, slidesToSlide, aspectRatio, gap]);

  const handleResize = useCallback(() => {
    const containerWidth = containerRef.current.offsetWidth;
    dispatch({ type: "SET_CONTAINER_WIDTH", payload: containerWidth });
    const totalButtonWidth = events.length * elementWidth; // Assuming each button + gap is 220px
    dispatch({ type: "SET_SHOW_VIEW_ALL_BUTTON", payload: totalButtonWidth > containerWidth });
  }, [events.length, elementWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleViewAllClick = useCallback(() => {
    const state = { searchBarValue: '' };
    if (typeOption) {
      state.typeOption = typeOption;
    }
    navigate('/viewall', { state });
  }, [navigate, typeOption]);

  const springProps = useSpring({
    height: state.elementHeight,
    from: { height: state.elementHeight },
    config: { duration: 500 },
  });

  return (
    <>
      <div className="category-header">
        <img className="homeCategoryIcon" src={src} alt={alt} />
        {sectionName}
        {state.showViewAllButton && (
          <ViewAllButton
            onClick={handleViewAllClick}
            text={t("viewAll")}
          />
        )}
      </div>
      <animated.div
        ref={containerRef}
        style={{ ...springProps, overflow: "hidden" }}
        className="event-section-wrapper"
      >
        <Carousel
          responsive={responsive}
          arrows
          autoPlay={false}
          autoPlaySpeed={500}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay={false}
          showDots={false}
          sliderClass=""
          slidesToSlide={slidesToSlide}
          swipeable
          transitionDuration={1000}
        >
          {events.map((event, index) => (
            <EventButton
              key={index}
              buttonImage={event.image}
              slidesToSlide={slidesToSlide}
              singleButtonWidth={state.singleButtonWidth}
              gameProvider={event.gameProvider}
              gameName={event.gameName}
              gameName2={event.gameName2}
              rootPage={event.rootPage}
              gameTitle={event.gameTitle}
              hasLive={event.hasLive}
            />
          ))}
        </Carousel>
      </animated.div>
    </>
  );
});

export default EventSection;
