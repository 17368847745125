import React from "react";
import "./DatePickerButtonAccountSettings.css";
import { ReactComponent as CalendarIcon } from "../../../assets/images/calendar_icon_white.svg";
import { useTranslation } from 'react-i18next';

const DatePickerButtonAccountSettings = React.forwardRef(({ value, onClick }, ref) => {
  const { t } = useTranslation();

  let displayValue = t('selectDate');
  if (value) {
    displayValue = value;
  }
  return (
    <button className="account-settings-date-picker-button" onClick={onClick} ref={ref}>
      {displayValue}
      <CalendarIcon />
    </button>
  );
});

export default DatePickerButtonAccountSettings;
