// Utility to remove duplicates based on the identifier
const removeDuplicates = (games) => {
    const seen = new Set();
    return games.filter(game => {
        const duplicate = seen.has(game.identifier);
        seen.add(game.identifier);
        return !duplicate;
    });
};

// Generates the image URL for games
export const generateImageUrl = (gameProvider, gameName) => {
    return `https://cdn.softswiss.net/i/s2/${gameProvider}/${gameName}.png`;
};

export const generateProviderImageUrl = (provider) => {
    return `https://cdn.softswiss.net/logos/providers/white/${provider}.svg`;
};

// Generates the featured image URL for games
export const generateFeaturedImageUrl = (gameProvider, gameName) => {
    return `https://cdn.softswiss.net/i/s3/${gameProvider}/${gameName}.png`;
};

// Filters games by release date, provider, and producer
export const filterGamesByReleaseDate = (games, providers, featureGroupsMapping) => {
    const currentDate = new Date().toISOString();

    return games.filter(game => {
        const isReleased = game.released_at < currentDate;
        const matchesProvider = providers.length ? providers.includes(game.provider) : true;
        const matchesFeatureGroup = featureGroupsMapping[game.provider] && featureGroupsMapping[game.provider].includes(game.feature_group);
        return isReleased && matchesProvider && matchesFeatureGroup;
    }).sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
};



export const filterUpcomingGames = (games, provider = null, producer = null) => {
    const currentDate = new Date().toISOString();

    return games.filter(game => {
        const isUpcoming = game.released_at >= currentDate;
        const matchesProvider = provider ? game.provider === provider : true;
        return isUpcoming && matchesProvider;
    });
};

export const parseJsonToGames = (games, rootPage, featureGroupsMapping, provider, options = {}) => {
    const { hasLive = false, limit } = options;
    try {
        // Filter games based on featureGroupsMapping for the specific provider
        const featureGroups = featureGroupsMapping[provider] || [];
        const now = new Date();

        let filteredGames = games.filter(game => {
            const releaseDate = new Date(game.released_at);
            const recalledDate = new Date(game.recalled_at);
            const isRecalled = game.recalled === true && recalledDate <= now;

            return game.provider === provider &&
                featureGroups.includes(game.feature_group) &&
                (!game.released_at || releaseDate <= now) &&
                !isRecalled;
        });

        // Filter games based on the hasLive parameter
        if (hasLive) {
            filteredGames = filteredGames.filter(game => game.has_live === true);
        }

        // Limit the number of games
        if (limit !== undefined && limit !== null) {
            filteredGames = filteredGames.slice(0, limit);
        }

        filteredGames = filteredGames.filter(
            (game) =>
              game.producer !== "rfranco" && game.feature_group !== "rfranco_basic"
          );
          
        return filteredGames.map(game => ({
            gameProvider: game.provider,
            gameName: game.identifier2,
            gameName2: game.identifier,
            gameTitle: game.title,
            rootPage: rootPage,
            image: generateImageUrl(game.provider, game.identifier.split(':')[1]),
            category: game.category,
            producer: game.producer,
            hasLive: game.has_live,
            released_at: game.released_at
        }));
    } catch (error) {
        console.error('Error processing games:', error);
        return [];
    }
};



// Updated parseJsonToFeaturedGames function
export const parseJsonToFeaturedGames = (jsonContents, rootPage, featureGroupsMapping, provider, limit = 5) => {
    try {
        let games = [];
        const now = new Date();

        // Combine all JSON contents into one array
        jsonContents.forEach(jsonContent => {
            if (Array.isArray(jsonContent)) {
                games = games.concat(jsonContent);
            } else {
                console.error('One of the JSON contents is not an array:', jsonContent);
            }
        });

        // Remove duplicates
        games = removeDuplicates(games);

        // Filter out games with a future release date
        games = games.filter(game => {
            const releaseDate = new Date(game.released_at);
            const recalledDate = new Date(game.recalled_at);
            const isRecalled = game.recalled === true && recalledDate <= now;

            return (!game.released_at || releaseDate <= now) && !isRecalled;
        });

        // Filter games based on featureGroupsMapping for the specific provider
        const featureGroups = featureGroupsMapping[provider] || [];
        let filteredGames = games.filter(game => game.provider === provider && featureGroups.includes(game.feature_group));

        // Limit the number of games
        filteredGames = filteredGames.slice(0, limit);

        return filteredGames.map(game => ({
            gameProvider: game.provider,
            gameName: game.identifier2,
            gameName2: game.identifier,
            rootPage: rootPage,
            gameTitle: game.title,
            image: generateImageUrl(game.provider, game.identifier.split(':')[1]),
            category: game.category,
            producer: game.producer,
            hasLive: game.has_live,
            released_at: game.released_at
        }));
    } catch (error) {
        console.error('Error processing games:', error);
        return [];
    }
};


// Parses upcoming JSON content (with date keys) into games
export const parseUpcomingJsonToGames = (upcomingJsonContent, rootPage, featureGroups = null) => {
    try {
        let games = [];

        // Extract all games from the date keys
        if (typeof upcomingJsonContent === 'object' && upcomingJsonContent !== null) {
            games = Object.values(upcomingJsonContent).flat();
        } else {
            console.error('Upcoming JSON content is not an object:', upcomingJsonContent);
        }

        // Remove duplicates
        games = removeDuplicates(games);

        // If featureGroups is provided, filter games based on it
        let filteredGames = games;
        if (featureGroups && Array.isArray(featureGroups)) {
            filteredGames = games.filter(game => featureGroups.includes(game.feature_group));
        }

        return filteredGames.map(game => ({
            gameProvider: game.provider,
            gameName: game.identifier2,
            gameName2: game.identifier,
            rootPage: rootPage,
            gameTitle: game.title,
            image: generateImageUrl(game.provider, game.identifier.split(':')[1]),
            category: game.category,
            producer: game.producer,
            hasLive: game.has_live
        }));
    } catch (error) {
        console.error('Error processing upcoming JSON:', error);
        return [];
    }
};


// Function to shuffle an array
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}