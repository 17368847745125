// constants.js
const featureGroupsMapping = {
    'softswiss': ['new', 'basic', 'standard'],
    'bgaming': ['new', 'basic', 'premium'],
    'apparat': ['basic'],
    'betsolutions': ['basic_table', 'basic_crash', 'basic_slots'],
    'habanero': ['basic'],
    'livesolutions': ['basic'],
    'platipus': ['basic', 'basic_cu', 'live'],
    '5men': ['basic'],
    'alg': ['7mojos_basic', '7mojos_live', 'altente_basic', 'basic'],
    'avatarux': ['basic'],
    'everymatrix': ['spearhead_basic', 'spearhead_basic_lv', 'spearhead_branded_lv'],
    'gameart': ['basic', 'basic_mga', 'branded', 'branded_mga', 'premium', 'premium_mga'],
    'groove': ['basic', 'felixgaming'],
    'igrosoft': ['basic'],
    'kagaming': ['basic'],
    'kalamba': ['basic'],
    'lucky': ['basic'],
    'mascot': ['basic'],
    'mrslotty': ['basic', 'eagaming'],
    'quickspin': ['basic', 'rtp', 'rtp94'],
    'thunderkick': ['basic', 'basicde_lv', 'basic_lv', 'basic_rtp94_lv', 'basic_rtp_lv'],
    'turbogames': ['basic'],
    'wazdan': ['basic', 'basic_lv', 'rtp'],
    'atmosphera': ['basic'],
    'belatra': ['basic'],
    'bsg': ['standard', 'vip'],
    'technology': ['basic', 'basic_lv'],
    'evoplay': ['basic', 'basic_mt', 'basic_slt'],
    'gamzix': ['basic'],
    'mancala': ['basic'],
    'netgame': ['basic'],
    'nucleus': ['standard'],
    'onlyplay': ['basic'],
    'swntt': ['basic', 'basic_live', 'premium', 'premium_live'],
    'gamebeat': ['basic'],
    '3oaks': ['basic'],
    'luckystreak': ['basic'],
    'mplay': ['basic'],
    'pragmaticexternal': ['basic', 'basic_lv', 'live', 'live_classic', 'live_vip', 'live_native'],
    'reevo': ['reevo_basic'],
    'smartsoft': ['basic', 'basic_mt'],
    'spadegaming': ['basic', 'basic_mt'],
    'zillion': ['basic'],
    'booming': ['basic'],
    'tomhornnative': ['basic'],
    'endorphina': ['standard'],
    'vibragaming': ['basic', 'basic_rtp91'],
    'pateplay': ['basic'],
    'yggdrasil': ['basic'],
    '1spin4win': ['basic'],
    'bluebird': ['basic'],
    'epicmedia': ['kiron', 'merkur'],
};

const providers = [
    'softswiss',
    'atmosphera',
    'belatra',
    'bsg',
    'technology',
    'epicmedia',
    'evoplay',
    'gamzix',
    'mancala',
    'netgame',
    'nucleus',
    'onlyplay',
    'swntt',
    'apparat',
    'betsolutions',
    'habanero',
    'livesolutions',
    'platipus',
    '5men',
    'alg',
    'avatarux',
    'everymatrix',
    'gameart',
    'groove',
    'igrosoft',
    'kagaming',
    'kalamba',
    'lucky',
    'mascot',
    'mrslotty',
    'quickspin',
    'thunderkick',
    'turbogames',
    'wazdan',
    'gamebeat',
    '3oaks',
    'luckystreak',
    'mplay',
    'pragmaticexternal',
    'reevo',
    'smartsoft',
    'spadegaming',
    'zillion',
    'tomhornnative',
    'endorphina',
    'vibragaming',
    'pateplay',
    'yggdrasil',
    '1spin4win',
    'bluebird',
    'booming'

];

module.exports = { providers, featureGroupsMapping };
