import { Base64 } from 'js-base64';

export const decodeSponsorUsername = (encodedUsername) => {
    try {
        return Base64.decode(encodedUsername);
    } catch (error) {
        console.error('Invalid encoded username:', error);
        return null;
    }
};
export const encodeSponsorUsername = (username) => {
    try {
        return Base64.encode(username);
    } catch (error) {
        console.error('Error encoding username:', error);
        return null;
    }
};