import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./eventJoinedButton.css";

const EventJoinedButton = ({
  buttonImage,
  gameProvider,
  gameName,
  gameName2,
  rootPage,
  gameTitle,
  slidesToSlide,
  singleButtonWidth,
  buttonHeight,
  hasLive
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  const handleClick = () => {
    const encodedGameName2 = encodeURIComponent(gameName2);
    navigate(`/games/${encodedGameName2}`, { state: { gameProvider, rootPage, buttonImage, gameTitle, hasLive } });
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const buttonStyleInitial = {
    "--button-bg-image": `url(${buttonImage})`,
  };

  const singleAndDoubleButtonStyle = {
    ...buttonStyleInitial,
    width: `${singleButtonWidth}px`,
    height: `${buttonHeight}px`,
  };

  return (
    <div style={{ position: "relative" }} onClick={handleClick}>
      <div className="live-icon">{t("live")}</div>
      <div
        className="event-joined-button"
        style={
          slidesToSlide <= 2 ? singleAndDoubleButtonStyle : buttonStyleInitial
        }
      >
        {!imageError ? (
          <img
            src={buttonImage}
            alt={gameTitle}
            className="hidden-image"
            onError={handleImageError}
          />
        ) : (
          <span className="button-text">{gameTitle}</span>
        )}
      </div>
    </div>
  );
};

export default EventJoinedButton;
