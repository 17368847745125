import React from "react";
import { useTranslation } from "react-i18next";

const HeaderSelectionMobile = ({ currentMode, handleModeChange }) => {
    const { t } = useTranslation();

    return (
        <div className="header-selection">
            <h1 className={currentMode === 'deposit' ? 'active' : ''} onClick={() => handleModeChange('deposit')}>
                {t('deposit')}
            </h1>
            <h1 className={currentMode === 'withdraw' ? 'active' : ''} onClick={() => handleModeChange('withdraw')}>
                {t('withdraw')}
            </h1>
        </div>
    );
};

export default HeaderSelectionMobile;
