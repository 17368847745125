import React, { useState } from "react";
import styled from "styled-components";
import useTranslationHelper from "../../hooks/useTranslationHelper";
import { cancelWithdrawalRequest } from "../../utils/api";
import { ReactComponent as CancelIcon } from "../../assets/images/cancel.svg";
import CancelWithdrawalPopup from "../../components/CancelWithdrawalPopup/CancelWithdrawalPopup";
import CancelWithdrawalConfirmationPopup from "../../components/CancelWithdrawalConfirmationPopup/CancelWithdrawalConfirmationPopup";

// Function to determine CSS class based on value
function getCssClass(value) {
  if (value > 0) return "positive";
  return "negative";
}

function getStatusCssClass(status) {
  switch (status) {
    case "Completed":
      return "completed";
    case "Pending":
      return "pending";
    case "Rejected":
      return "rejected";
    default:
      return "";
  }
}

// Styled component for cells
const StyledCell = styled.div`
  &.positive {
    color: #17d449 !important;
    font-weight: 600;
  }
  &.negative {
    color: #ff002b;
    font-weight: 600;
  }
  &.status {
    color: red;
  }
`;

const StyledStatus = styled.div`
  &.completed {
    color: green !important;
    font-weight: 600;
  }
  &.pending {
    color: orange !important;
    font-weight: 600;
  }
  &.rejected {
    color: red !important;
    font-weight: 600;
  }
`;

const DescriptionCell = styled.div`
  white-space: normal;
  word-break: break-word;
`;

const CancelCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ColumnsProvider = ({ children, filter }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const t = useTranslationHelper();

  const translateStatus = (status) => {
    switch (status) {
      case "Completed":
        return t("completed");
      case "Pending":
        return t("pending");
      case "Rejected":
        return t("rejected");
      default:
        return status;
    }
  };

  const handleCancelClick = (row) => {
    setSelectedRow(row);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedRow(null);
  };

  const closeConfirmationPopup = () => {
    setIsConfirmationPopupOpen(false);
  };

  const handleConfirmCancel = async () => {
    if (selectedRow) {
      try {
        const response = await cancelWithdrawalRequest({
          serialNumber: selectedRow.serialNumber,
        });
        if (response.success) {
          console.log("Successfully cancelled withdrawal request");
          setIsConfirmationPopupOpen(true);
          window.location.reload(); // Refresh the data
        }
      } catch (error) {
        console.error("Error cancelling withdrawal request:", error);
      }
    }
    closePopup();
  };

  const columns =
    filter === "withdrawalHistory"
      ? [
          {
            name: `${t("serial")} #`,
            selector: (row) => row.serialNumber,
            sortable: true,
          },
          {
            name: t("date"),
            selector: (row) => row.date.toLocaleDateString(),
            sortable: true,
          },
          {
            name: t("description"),
            selector: (row) => row.description,
            sortable: true,
            cell: (row) => <DescriptionCell>{row.description}</DescriptionCell>,
            grow: 2, // Allow this column to grow more
          },
          {
            name: t("amount"),
            selector: (row) => Math.floor(row.amount).toLocaleString(),
            sortable: true,
            width: "120px", // Set a fixed width for the amount column
          },
          {
            name: t("status"),
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
              <StyledStatus className={getStatusCssClass(row.status)}>
                {translateStatus(row.status)}
              </StyledStatus>
            ),
            width: "120px", // Set a fixed width for the status column
          },
          {
            name: t("cancel"),
            selector: () => null,
            sortable: true,
            cell: (row) =>
              row.status === "Pending" && (
                <CancelCell onClick={() => handleCancelClick(row)}>
                  <CancelIcon />
                </CancelCell>
              ),
            width: "120px", // Set a fixed width for the cancel column
          },
        ]
      : [
          {
            name: `${t("serial")} #`,
            selector: (row) => row.serialNumber,
            sortable: true,
          },
          {
            name: t("date"),
            selector: (row) => row.date.toLocaleDateString(),
            sortable: true,
          },
          {
            name: t("description"),
            selector: (row) => row.description,
            sortable: true,
            cell: (row) => <DescriptionCell>{row.description}</DescriptionCell>,
          },
          {
            name: t("walletType"),
            selector: (row) => row.walletType,
            sortable: true,
          },
          {
            name: `${t("amount")}`,
            selector: (row) => row.amountInGoToken,
            sortable: true,
            cell: (row) => (
              <StyledCell className={getCssClass(row.amountInGoToken)}>
                {row.amountInGoToken.toLocaleString()}
              </StyledCell>
            ),
          },
        ];

  return (
    <>
      {children(columns)}
      {isPopupOpen && (
        <CancelWithdrawalPopup
          row={selectedRow}
          onClose={closePopup}
          onConfirm={handleConfirmCancel}
        />
      )}
      {isConfirmationPopupOpen && (
        <CancelWithdrawalConfirmationPopup onClose={closeConfirmationPopup} />
      )}
    </>
  );
};

export default ColumnsProvider;
