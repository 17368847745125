import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select';
import CasinoButton from "../../components/CasinoButton/casinoButton";
import SearchBar from "../../components/SearchBar/SearchBar";
import BackArrow from '../../assets/images/BackArrow.svg';
import { parseJsonToGames } from "../../utils/gameUtils";
import { loadAllProviderJsonFiles } from '../../utils/jsonLoader';
import { useTranslation } from "react-i18next";
import { featureGroupsMapping, providers as allProviders } from '../../utils/constants';
import { scrollToTop } from "../../utils/scrollToTop";
import "./Category.css";

const Category = () => {
  const { category } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    searchBarValue: initialSearchBarValue,
    selectedProviders: initialSelectedProviders
  } = location.state || {};

  const [games, setGames] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState(initialSearchBarValue || "");
  const [selectedProviders, setSelectedProviders] = useState(initialSelectedProviders || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage, setGamesPerPage] = useState(36);
  const { t } = useTranslation();
  const [providers, setProviders] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.matchMedia("(max-width: 768px)").matches);

  const categoryFilterCriteria =
    category === "Slot Battles"
      ? "slots"
      : category === "videoPoker"
        ? "video_poker"
        : category === "virtualSports"
          ? "virtual_sports"
          : category.toLowerCase();

  const translatedCategory =
    category === "Slot Battles"
      ? t("slotBattles")
      : category === "videoPoker"
        ? t("videoPoker")
        : category === "virtualSports"
          ? t("virtualSports")
          : t(category);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const rootPage = "categories";

    const jsonContents = loadAllProviderJsonFiles();

    // Filter games by category
    const gamesFilteredByCategory = jsonContents.flatMap((jsonContent) => {
      if (Array.isArray(jsonContent)) {
        return jsonContent.filter(
          (game) => game.category.toLowerCase() === categoryFilterCriteria.toLowerCase()
        );
      } else {
        console.error("One of the JSON contents is not an array:", jsonContent);
        return [];
      }
    });

    // Parse the filtered games using providers and featureGroupsMapping
    const parsedGames = allProviders.flatMap(provider =>
      parseJsonToGames(gamesFilteredByCategory, rootPage, featureGroupsMapping, provider)
    );

    setGames(parsedGames);

    // Extract unique providers
    const uniqueProviders = [...new Set(parsedGames.map(game => game.producer).filter(Boolean))]
      .sort((a, b) => a.localeCompare(b));
    setProviders(uniqueProviders.map(producer => ({ value: producer, label: capitalizeFirstLetter(producer) })));

  }, [categoryFilterCriteria]);

  useEffect(() => {
    const updateGamesPerPage = () => {
      if (window.matchMedia("(max-width: 592px)").matches) {
        setGamesPerPage(10); // Number of games per page for mobile view
      } else {
        setGamesPerPage(36); // Number of games per page for desktop view
      }
      setIsMobileView(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener('resize', updateGamesPerPage);
    updateGamesPerPage(); // Initial check

    return () => window.removeEventListener('resize', updateGamesPerPage);
  }, []);

  const handleProviderChange = (selectedOptions) => {
    setSelectedProviders(selectedOptions);
    setCurrentPage(1);
    resetGamesPerPage();
  };

  useEffect(() => {
    setCurrentPage(1);
    resetGamesPerPage();
  }, [searchBarValue]);

  const resetGamesPerPage = () => {
    if (window.matchMedia("(max-width: 592px)").matches) {
      setGamesPerPage(10);
    } else {
      setGamesPerPage(36);
    }
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontFamily: 'var(--main-font-family)',
      fontSize: '16px',
      fontWeight: 400,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '2px solid var(--main-font-color)',
      color: 'white',
      ':hover': {
        border: '2px solid var(--main-font-color)',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',
      textAlign: 'start'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#043b5d',
      border: '2px solid var(--main-font-color)',
      color: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--main-font-color)' : 'transparent',
      color: state.isFocused ? 'black' : 'white',
      ':active': {
        backgroundColor: 'var(--main-font-color)',
        color: 'black',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '1px solid var(--main-font-color)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'rgb(248 190 0)',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'rgb(248 190 0)',
      },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'rgb(248 190 0)',
      },
    }),
  };

  const gamesFilteredBySearchBar = games
    .filter(
      item =>
        (!selectedProviders.length || selectedProviders.some(prov => prov.value === item.producer))
    )
    .filter(
      item =>
        item.producer.toLowerCase().includes(searchBarValue.toLowerCase()) ||
        item.gameTitle.toLowerCase().includes(searchBarValue.toLowerCase())
    );

  const totalPages = Math.ceil(gamesFilteredBySearchBar.length / gamesPerPage);

  const currentGames = gamesFilteredBySearchBar.slice(
    (currentPage - 1) * gamesPerPage,
    currentPage * gamesPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handleLoadMore = () => {
    setGamesPerPage(gamesPerPage + 10);
  };

  return (
    <div className="category-page">
      <header className="category-page-header">
        <div className="header-top-row">
          <div className="header-left">
            <img
              src={BackArrow}
              alt="Back"
              className="back-arrow"
              onClick={() => navigate('/casino')}
            />
          </div>
          <div className="header-center">
            <div className="main-category-header">
              {`${translatedCategory} ${t("Games")}`}
            </div>
          </div>
          <div className="header-right"></div>
        </div>
        <div className="category-icon-container">
          <SearchBar
            className="category-search-bar"
            searchBarValue={searchBarValue}
            setSearchBarValue={setSearchBarValue}
          />
          <div className="category-filter-select">
            <Select
              options={providers}
              styles={customStyles}
              isMulti
              placeholder={t('provider')}
              value={selectedProviders}
              onChange={handleProviderChange}
            />
          </div>
        </div>
      </header>
      <div className="category-games-container">
        {currentGames.map((casino, index) => (
          <CasinoButton
            key={index}
            buttonImage={casino.image}
            gameProvider={casino.gameProvider}
            gameName={casino.gameName}
            gameName2={casino.gameName2}
            rootPage={casino.rootPage}
            gameTitle={casino.gameTitle}
            hasLive={casino.hasLive}
            searchBarValue={searchBarValue}
            selectedProviders={selectedProviders}
            category={category}
          />
        ))}
      </div>
      {isMobileView ? (
        <>
          <div className="showing-text">
            {t('showing')} <span className="gold-text">{currentGames.length}</span> {t('of')} <span className="gold-text">{gamesFilteredBySearchBar.length}</span>
          </div>
          <div className="load-more-container">
            <button onClick={handleLoadMore} className="load-more-button">
              {t('loadMore')}
            </button>
            <button onClick={scrollToTop} className="go-to-top-button">
              {t('goToTop')}
            </button>
          </div>
        </>
      ) : (
        <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>{t('previous')}</button>
          <span>{currentPage} {t('of')} {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>{t('next')}</button>
        </div>
      )}
    </div>
  );
};

export default Category;
