import React, { useState } from "react";
import "./DepositCashPopup.css";
import { useTranslation } from "react-i18next";
import MobilePopup from "./MobilePopup";
import DesktopPopup from "./DesktopPopup";
import { useViewport } from "../../hooks/ViewportProvider";
import { getTokenCheckExpiryAndRefreshIfNeeded } from "../../utils/auth";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, parseUnits, Contract, formatUnits } from 'ethers'
import { convertCryptoToUsd } from "../../utils/api";
import { getEthBalanceFromWalletConnect } from "../../utils/walletConnect";

const USDTAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount)',
  'event Transfer(address indexed from, address indexed to, uint amount)'
]

const DepositCashPopup = ({ onClose , onBankPayment}) => {
  const { width } = useViewport();
  const breakpoint = 900;
  const [loading, setLoading] = useState(false);
  const [loadingDepositWalletConnect, setloadingDepositWalletConnect] = useState(false);
  const { t } = useTranslation();

  const CryptoCurrencyOptionEnum = Object.freeze({
    BTC: { id: 1, name: "", symbol: "BTC" },
    ETH: { id: 2, name: "ERC20", symbol: "ETH" },
    USDT: { id: 3, name: "ERC20", symbol: "USDT" },
    USDC: { id: 4, name: "ERC20", symbol: "USDC" },
  });
  
  const [currentPage, setCurrentPage] = useState(1);
  const [amount, setAmount] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [currentMode, setCurrentMode] = useState('deposit'); // New state for mode
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [depositConnectWalletErrorMessage, setDepositConnectWalletErrorMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [walletConnectionErrorMessage, setWalletConnectionErrorMessage] = useState("");
  const [selectedPaymentWalletConnect, setSelectedPaymentWalletConnect] = useState(CryptoCurrencyOptionEnum.ETH.symbol);
  const [selectedPaymentIdWalletConnect, setSelectedPaymentIdWalletConnect] = useState(null);
  const [walletAmountUsdtAndUsdc, setWalletAmountUsdtAndUsdc] = useState(0);
  const [walletAmountUsdtAndUsdcToUsd, setWalletAmountUsdtAndUsdcToUsd] = useState(0);
  const [walletAmountEth, setWalletAmountEth] = useState(null);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    onClose();
  };

  const handleSubmit = async () => {
    let hasError = false;

    if (currentPage === 1) {
      if (amount === "" || Number(amount) < 50) {
        setErrorMessage(t("amountError"));
        hasError = true;
      } else {
        setErrorMessage("");
      }

      if (!selectedPayment) {
        setPaymentErrorMessage(t("selectPaymentMethod"));
        hasError = true;
      } else {
        setPaymentErrorMessage("");
      }
      if (selectedPayment === "bank") {
        if (!hasError) {
          try {
            onBankPayment(amount);
            setLoading(true);
            const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();
  
            if (!accessToken) {
              console.error("Access token not found"); // set user logged out
              return;
            }
  
            const response = await fetch(
              `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/getChangellyOffers?Amount=${encodeURIComponent(amount)}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  Accept: "application/json",
                },
              }
            );
  
            if (response.ok) {
              setLoading(false);
              const data = await response.json();
                onBankPayment(amount,data.data);
              return;
            } else {
              setLoading(false);
              const errorData = await response.json();
              setErrorMessage(
                errorData.errors[0].errorType +
                " - " +
                errorData.errors[0].errorMessage
              );
            }
          } catch (error) {
            setLoading(false);
            setErrorMessage("Error fetching data: " + error.message);
          }
        }
      }
      if (!hasError) {
        try {
          setLoading(true);
          const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

          if (!accessToken) {
            console.error("Access token not found"); // set user logged out
            return;
          }

          const response = await fetch(
            `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/createDepositRequest`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                amountUSD: Number(amount),
                cryptoCurrencyOptionEnumId: selectedPaymentId,
              }),
            }
          );

          if (response.ok) {
            setLoading(false);
            const data = await response.json();
            setResponseData(data.data);
            handleNextPage();
          } else {
            setLoading(false);
            const errorData = await response.json();
            setErrorMessage(
              errorData.errors[0].errorType +
              " - " +
              errorData.errors[0].errorMessage
            );
          }
        } catch (error) {
          setLoading(false);
          setErrorMessage("Error fetching data: " + error.message);
        }
      }
    } else if (currentPage === 2) {
      onClose();
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };
  
  const handlePaymentSelection = (event) => {
    const selectedSymbol = event.target.value;
    setSelectedPayment(selectedSymbol);

    if (selectedSymbol === "bank") {
      setSelectedPaymentId("bank"); // Or any appropriate value for "bank"
    } else {
      const selectedOption = CryptoCurrencyOptionEnum[selectedSymbol];
      if (selectedOption) {
        setSelectedPaymentId(selectedOption.id);
      }
    }
  };

  const toggleDisclaimer = (e) => {
    setIsVisible(!isVisible);
  };

  const handleModeChange = (mode) => {
    setCurrentMode(mode);
    setCurrentPage(1);
    setAmount("");
    setSelectedPayment("");
    setSelectedPaymentId(null);
    setErrorMessage("");
    setPaymentErrorMessage("");
    setResponseData(null);
  };

  const handleSubmitWalletConnect = () => {
    setCurrentPage(3);
  }

  const backSubmit = () => {
    setCurrentPage(1);
  }

  const handleWalletConnectSubmit = async () => {
    if(!isConnected){
      setWalletConnectionErrorMessage(t("pleaseConnectWallet"));
      return;
    }

    let hasError = false;

    const connectWalletInputAmount = localStorage.getItem("depositWalletConnectAmount");
    const walletConnectUsdAmount = localStorage.getItem("walletConnectUsdAmount");
    if (connectWalletInputAmount === '0' || connectWalletInputAmount === ''){
      setDepositConnectWalletErrorMessage(t("amountErrorWalletConnect"));
      hasError = true;
      return;
    }
    if (walletConnectUsdAmount === '0' || walletConnectUsdAmount === ''){
    setDepositConnectWalletErrorMessage(t("insufficientWallet"));
    hasError = true;
    return;
    }
    if (parseFloat(connectWalletInputAmount) > parseFloat(walletConnectUsdAmount)){
    setDepositConnectWalletErrorMessage(t("insufficientWallet"));
    hasError = true;
    return;
    }
    if (currentPage === 3) {
      if (connectWalletInputAmount === "" || Number(connectWalletInputAmount) < 50) {
        setDepositConnectWalletErrorMessage(t("amountErrorWalletConnect"));
        hasError = true;
      } else {
        setDepositConnectWalletErrorMessage("");
      }

      if (!hasError) {
        try {
          setloadingDepositWalletConnect(true);
          const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

          if (!accessToken) {
            console.error("Access token not found"); // set user logged out
            return;
          }

          const response = await fetch(
            `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/createDepositRequest`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                amountUSD: Number(connectWalletInputAmount),
                cryptoCurrencyOptionEnumId: selectedPaymentIdWalletConnect ?? CryptoCurrencyOptionEnum.ETH.id,
              }),
            }
          );

          if (response.ok) {
            setloadingDepositWalletConnect(false);
            const data = await response.json();
            if(data.data.cryptoCurrencyOptionEnumId === CryptoCurrencyOptionEnum.ETH.id){
              handleWalletConnectPaymnetEth(data.data.cryptoCurrencyAmount, data.data.cryptoCurrencyDepositAddress);
            } else if(data.data.cryptoCurrencyOptionEnumId === CryptoCurrencyOptionEnum.USDT.id || data.data.cryptoCurrencyOptionEnumId === CryptoCurrencyOptionEnum.USDC.id){
              handleWalletConnectPaymnetUsdtAndUsdc(data.data.cryptoCurrencyAmount, data.data.cryptoCurrencyDepositAddress);
            }
            
          } else {
            setloadingDepositWalletConnect(false);
            const errorData = await response.json();
            setDepositConnectWalletErrorMessage(
              errorData.errors[0].errorType +
              " - " +
              errorData.errors[0].errorMessage
            );
          }
        } catch (error) {
          setloadingDepositWalletConnect(false);
          setDepositConnectWalletErrorMessage("Error fetching data: " + error.message);
        }
      }
    }
  };

  const handleWalletConnectPaymnetEth= async (cryptoCurrencyAmount, cryptoCurrencyDepositAddress) => {
    try {
      setloadingDepositWalletConnect(true);
      const ethAddress = address;
      const amountInWei = parseUnits(cryptoCurrencyAmount, 'ether').toString(); 
      const recipientAddress = cryptoCurrencyDepositAddress;
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const transactionParameters = {
        to: recipientAddress,
        from: ethAddress,
        value: amountInWei, 
        chainId: chainId,
        gasLimit: 21000,
      };
      const txHash = await signer.sendTransaction(transactionParameters);
      if(txHash.hash !== ""){
        setIsDialogOpen(true);
        setDialogMessage(t("walletConnectPaymentSuccessfully"));
      } else {
        setIsDialogOpen(true);
        setDialogMessage(t("walletConnectPaymentNotSuccessfully"));
      }
      setloadingDepositWalletConnect(false);
    } catch (error) {
      setIsDialogOpen(true);
      setDialogMessage(t("walletConnectPaymentNotSuccessfully"));
      setloadingDepositWalletConnect(false);
    }
  };

  const handleWalletConnectPaymnetUsdtAndUsdc = async (cryptoCurrencyAmount, cryptoCurrencyDepositAddress) => {
    try {
      setloadingDepositWalletConnect(true);
      const ethersProvider = new BrowserProvider(walletProvider);
      const amountInWei = parseUnits(cryptoCurrencyAmount, 6);
      const signer = await ethersProvider.getSigner();
      const USDTContract = new Contract(localStorage.getItem("ContractAddress"), USDTAbi, signer);
      const tx = await USDTContract.transfer(cryptoCurrencyDepositAddress, amountInWei);
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setIsDialogOpen(true);
        setDialogMessage(t("walletConnectPaymentSuccessfully"));
      } else {
        setIsDialogOpen(true);
        setDialogMessage(t("walletConnectPaymentNotSuccessfully"));
      }
    } catch (error) {
      setIsDialogOpen(true);
      setDialogMessage(t("walletConnectPaymentNotSuccessfully"));
    } finally {
      setloadingDepositWalletConnect(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    onClose();
  };

  const handlePaymentSelectionWalletConnect = (event) => {
    const selectedSymbol = event.target.value;
    setSelectedPaymentWalletConnect(selectedSymbol);
    setSelectedPaymentIdWalletConnect(CryptoCurrencyOptionEnum[selectedSymbol].id);
    localStorage.setItem("selectedSymbolWalletConnect", selectedSymbol);
    getUsdtAndUsdcBalance(selectedSymbol);
  };

  const getUsdtAndUsdcBalance = async (selectedSymbol) => {
    if (!isConnected){
      return;
    }
    if(CryptoCurrencyOptionEnum[selectedSymbol].id === 3 || CryptoCurrencyOptionEnum[selectedSymbol].id === 4){
      setloadingDepositWalletConnect(true);
      if(CryptoCurrencyOptionEnum[selectedSymbol].id === 3){
        localStorage.setItem("ContractAddress", '0xdac17f958d2ee523a2206206994597c13d831ec7'); //USDTAddress
      } else if(CryptoCurrencyOptionEnum[selectedSymbol].id === 4){
        localStorage.setItem("ContractAddress", '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'); //USDCAddress
      } 
  
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(localStorage.getItem("ContractAddress"), USDTAbi, signer)
      const balance = await contract.balanceOf(address);
      const formatUnitsBalance = parseFloat(formatUnits(balance, 6)).toFixed(2);
      setWalletAmountUsdtAndUsdc(formatUnitsBalance);
      if(formatUnitsBalance > 0){
        const { usdAmount } = await convertCryptoToUsd(formatUnitsBalance, CryptoCurrencyOptionEnum[selectedSymbol].id);
        setWalletAmountUsdtAndUsdcToUsd(usdAmount);
        localStorage.setItem("walletConnectUsdAmount", usdAmount);
        setloadingDepositWalletConnect(false);
      } else {
        localStorage.setItem("walletConnectUsdAmount", '0');
        setWalletAmountUsdtAndUsdcToUsd(0);
        setloadingDepositWalletConnect(false);
      }
    } else{
      const { balanceInEth, usdAmount } = await getEthBalanceFromWalletConnect(CryptoCurrencyOptionEnum.ETH.id, address, walletProvider);
      localStorage.setItem("walletConnectUsdAmount", usdAmount);
      setWalletAmountEth(balanceInEth);
      setWalletAmountUsdtAndUsdcToUsd(0);
      setloadingDepositWalletConnect(false);
    }
    
  };

  return width < breakpoint ? (
    <MobilePopup
      {...{
        currentPage,
        amount,
        selectedPayment,
        errorMessage,
        paymentErrorMessage,
        responseData,
        isVisible,
        loading,
        handleNextPage,
        handlePreviousPage,
        handleSubmit,
        handleAmountChange,
        handlePaymentSelection,
        toggleDisclaimer,
        onClose,
        CryptoCurrencyOptionEnum,
        currentMode,
        handleModeChange, // Pass mode change handler
        handleSubmitWalletConnect,
        backSubmit,
        handleWalletConnectSubmit,
        loadingDepositWalletConnect,
        depositConnectWalletErrorMessage,
        isDialogOpen,
        handleCloseDialog,
        dialogMessage,
        walletConnectionErrorMessage,
        handlePaymentSelectionWalletConnect,
        selectedPaymentWalletConnect,
        walletAmountUsdtAndUsdc,
        walletAmountUsdtAndUsdcToUsd,
        walletAmountEth,
      }}
    />
  ) : (
    <DesktopPopup
      {...{
        currentPage,
        amount,
        selectedPayment,
        errorMessage,
        paymentErrorMessage,
        responseData,
        isVisible,
        loading,
        handleNextPage,
        handlePreviousPage,
        handleSubmit,
        handleAmountChange,
        handlePaymentSelection,
        toggleDisclaimer,
        onClose,
        CryptoCurrencyOptionEnum,
        currentMode,
        handleModeChange, // Pass mode change handler
        handleSubmitWalletConnect,
        backSubmit,
        handleWalletConnectSubmit,
        loadingDepositWalletConnect,
        depositConnectWalletErrorMessage,
        isDialogOpen,
        handleCloseDialog,
        dialogMessage,
        walletConnectionErrorMessage,
        handlePaymentSelectionWalletConnect,
        selectedPaymentWalletConnect,
        walletAmountUsdtAndUsdc,
        walletAmountUsdtAndUsdcToUsd,
        walletAmountEth,
      }}
    />
  );
};
export default DepositCashPopup;
