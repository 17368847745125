import React from 'react';
import './button.css'
import GoTokensCoin from '../../assets/images/go_tokens_coin.svg';


const index = ({ buttonType, text, secondaryText, onClick }) => {
    return (
        <button className={buttonType} onClick={onClick}>
            <span>{text}</span>
            <small>{secondaryText}</small>
            {buttonType === "currentTokens" && <img src={GoTokensCoin} alt="Bonus Tokens Coin" className="svgIcon" />}
        </button>
    )
}



export default index
