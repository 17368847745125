import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./RegistrationOneTimePassword.css";

const RegistrationOneTimePassword = ({ onValidate, onResend, errorMessage, loading }) => {
    const { t } = useTranslation();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(120);
    const inputs = useRef([]);
    const timerRef = useRef();

    useEffect(() => {
        startTimer();
        return () => clearInterval(timerRef.current);
    }, []);

    const startTimer = () => {
        clearInterval(timerRef.current);
        setTimer(120); // Reset timer to 2:00
        timerRef.current = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.value !== "" && index < 5) {
            inputs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && index > 0 && !otp[index]) {
            inputs.current[index - 1].focus();
        }
    };

    const handleResendCode = () => {
        onResend();
        startTimer(); // Restart timer
    };

    const handleValidate = () => {
        const enteredOtp = otp.join("");
        onValidate(enteredOtp);
    };

    return (
        <div className="otp-container">
            {/* <p className="otpSubtext">{t('otpPrompt')}</p> */}
            <div className="otp-input-container">
                {otp.map((data, index) => {
                    return (
                        <input
                            className="otp-input"
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleChange(e.target, index)}
                            onKeyDown={e => handleKeyDown(e, index)}
                            onFocus={e => e.target.select()}
                            ref={el => inputs.current[index] = el}
                        />
                    );
                })}
            </div>
            {errorMessage && <div className="otp-error">{errorMessage}</div>}
            <div className="otp-timer">
                {timer > 0 ? (
                    <span>{`${t('resendCodeIn')} ${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}</span>
                ) : (
                    <button onClick={handleResendCode} className="resend-code">{t('resendCodeUpper')}</button>
                )}
            </div>
            <div className="otp-button-container">
                <button className="validate-button" onClick={handleValidate} disabled={loading}>
                    {loading ? <div className="otp-loading-spinner"></div> : t('validate')}
                </button>
            </div>
        </div>
    );
};

export default RegistrationOneTimePassword;
