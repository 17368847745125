import React, { useEffect, useState } from 'react';
import './Activity.css';
import ColumnsProvider from './ActivityColumns';
import { useTranslation } from 'react-i18next';
import { getWalletTransactionRows, getWalletBetRows, getWithdrawalRequestRows, getPendingBonusBalance } from '../../utils/api';
import DTableAccountSettings from '../../components/DTableAccountSettings/DTableAccountSettings';

const Activity = () => {
    const [data, setData] = useState(null);
    const [filter, setFilter] = useState('transactions');
    const { t } = useTranslation();
    const [pendingBonusBalance, setPendingBonusBalance] = useState(0);


    useEffect(() => {
        async function fetchData() {
            try {
                let result;
                if (filter === 'transactions') {
                    result = await getWalletTransactionRows();
                } else if (filter === 'bets') {
                    result = await getWalletBetRows();
                } else if (filter === 'withdrawalHistory') {
                    result = await getWithdrawalRequestRows();
                }
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        const fetchPendingBonusBalance = async () => {
            try {
                const balance = await getPendingBonusBalance();
                setPendingBonusBalance(balance.data.walletBalance);
            } catch (error) {
                console.error("Error fetching pending bonus balances:", error);
            }
        };

        fetchData();
        fetchPendingBonusBalance();

    }, [filter]);

    return (
        <div className="activity-page-container">
            <div className="activity-table-container">
                {data ? (
                    <ColumnsProvider filter={filter}>
                        {(columns) => (
                            <DTableAccountSettings
                                rows={data}
                                columns={columns}
                                filter={filter}
                                setFilter={setFilter}
                                pendingBonusBalance={pendingBonusBalance}
                            />
                        )}
                    </ColumnsProvider>
                ) : (
                    <div className="dtable-account-settings-loading">{t('loadingText')}</div>
                )}
            </div>
        </div>
    );
};

export default Activity;
