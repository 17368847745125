import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useViewport } from "../../hooks/ViewportProvider";
import CustomButton from "../../components/Button/button";
import PopupHandler from "../../components/PopupHandler/popupHandler";
import MobileDropdownButton from "../../components/MobileDropdownButton/MobileDropdownButton";
import MobileUserDropdown from "../../components/MobileUserDropdown/MobileUserDropdown";

import homeIconBlue from "../../assets/images/NavBarIcons/Blue/home-icon-blue.svg";
import eventsIconBlue from "../../assets/images/NavBarIcons/Blue/events-icon-blue.svg";
import casinoIconBlue from "../../assets/images/NavBarIcons/Blue/casino-icon-blue.svg";
import sportsIconBlue from "../../assets/images/NavBarIcons/Blue/sports-icon-blue.svg";
import referralsIconBlue from "../../assets/images/NavBarIcons/Blue/referrals-icon-blue.svg";


import "./navbar.css";

const MobileNav = ({
  handleDepositCashClick,
  isLoggedIn,
  handleSignInClick,
  handleRegisterClick,
  handleLogout,
  hamburgerOpen,
  setHamburgerOpen,
}) => {
  const { t } = useTranslation();
  const signedInUsername = localStorage.getItem("signedInUsername");

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <>
      <div
        className="mobileNavIcon"
        onClick={() => setHamburgerOpen(!hamburgerOpen)}
      >
        <div className={`burger-icon ${hamburgerOpen ? "open" : ""}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div
        className={`mobile-navbar-container-div ${hamburgerOpen ? "open" : ""}`}
      >
        <div className="mobile-nav-buttons-container-div">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "nav-button-active" : "nav-button"
            }
            onClick={() => setHamburgerOpen(false)}
          >
            <img src={homeIconBlue} alt="Home icon" />
            <label>{t("home")}</label>
          </NavLink>
          <NavLink
            to="/casino"
            className={({ isActive }) =>
              isActive ? "nav-button-active" : "nav-button"
            }
            onClick={() => setHamburgerOpen(false)}
          >
            <img src={casinoIconBlue} alt="Casino icon" />
            <label>{t("casino")}</label>
          </NavLink>
          <NavLink
            to="/livegames"
            className={({ isActive }) =>
              isActive ? "nav-button-active" : "nav-button"
            }
            onClick={() => setHamburgerOpen(false)}
          >
            <img src={eventsIconBlue} alt="Events icon" />
            <label>{t("liveGamesCapital")}</label>
          </NavLink>
          <NavLink
            to="/sports"
            className={({ isActive }) =>
              isActive ? "nav-button-active" : "nav-button"
            }
            onClick={() => setHamburgerOpen(false)}
          >
            <img src={sportsIconBlue} alt="Sports icon" />
            <label>{t("sports")}</label>
          </NavLink>
          <NavLink
            to="/settings/referrals"
            className={({ isActive }) =>
              isActive ? "nav-button-active" : "nav-button"
            }
            onClick={() => setHamburgerOpen(false)}
          >
            <img src={referralsIconBlue} alt="Referrals icon" />
            <label>{t("referrals")}</label>
          </NavLink>
          {!isLoggedIn && (
            <>
              <CustomButton
                buttonType="loggedOutYellowButton"
                text={t('signIn')}
                secondaryText=""
                onClick={handleSignInClick}
              />
              <CustomButton
                buttonType="buyTokenBlueButton"
                text={t('register')}
                secondaryText=""
                onClick={handleRegisterClick}
              />
            </>
          )}
          {isLoggedIn && (
            <>
              <div className="welcomeTextMobile">
                {t("welcome")}, {signedInUsername}
              </div>
              <MobileUserDropdown
                isOpen={openDropdown === 'userDropdown'}
                toggleDropdown={() => toggleDropdown('userDropdown')}
                handleLogout={handleLogout}
              />
              <CustomButton
                buttonType="depositCashYellowButton"
                text={`${t("wallet")}`}
                secondaryText=""
                onClick={handleDepositCashClick}
              />
              <div className="mobileDropdown">
                <MobileDropdownButton
                  isOpen={openDropdown === 'balanceDropdown'}
                  toggleDropdown={() => toggleDropdown('balanceDropdown')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const DesktopNav = ({ selectedLanguage }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        selectedLanguage === "de"
          ? "for-german navbar-container-div"
          : "navbar-container-div"
      }
    >
      <div className="nav-buttons-container-div">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "nav-button-active" : "nav-button"
          }
        >
          <img src={homeIconBlue} alt="Home icon" />
          <label>{t("home")}</label>
        </NavLink>

        <NavLink
          to="/casino"
          className={({ isActive }) =>
            isActive ? "nav-button-active" : "nav-button"
          }
        >
          <img src={casinoIconBlue} alt="Casino icon" />
          <label>{t("casino")}</label>
        </NavLink>
        <NavLink
          to="/livegames"
          className={({ isActive }) =>
            isActive ? "nav-button-active" : "nav-button"
          }
        >
          <img src={eventsIconBlue} alt="Events icon" />
          <label>{t("liveGamesCapital")}</label>
        </NavLink>
        <NavLink
          to="/sports"
          className={({ isActive }) =>
            isActive ? "nav-button-active" : "nav-button"
          }
        >
          <img src={sportsIconBlue} alt="Sports icon" />
          <label>{t("sports")}</label>
        </NavLink>
        <NavLink
          to="/settings/referrals"
          className={({ isActive }) =>
            isActive ? "nav-button-active" : "nav-button"
          }
        >
          <img src={referralsIconBlue} alt="Referrals icon" />
          <label>{t("referrals")}</label>
        </NavLink>
      </div>
    </div>
  );
};

const NavBar = ({
  isLoggedIn,
  handleSignInClick,
  handleRegisterClick,
  handleLogout,
  hamburgerOpen,
  setHamburgerOpen,
  selectedLanguage,
}) => {
  const { width } = useViewport();
  const breakpoint = 900;

  return (
    <PopupHandler>
      {(handleDepositCashClick) =>
        width < breakpoint ? (
          <MobileNav
            handleDepositCashClick={handleDepositCashClick}
            isLoggedIn={isLoggedIn}
            handleSignInClick={handleSignInClick}
            handleRegisterClick={handleRegisterClick}
            handleLogout={handleLogout}
            hamburgerOpen={hamburgerOpen}
            setHamburgerOpen={setHamburgerOpen}
          />
        ) : (
          <DesktopNav selectedLanguage={selectedLanguage} />
        )
      }
    </PopupHandler>
  );
};

export default NavBar;
