import React, { useState, useEffect } from 'react';
import CopyIcon from "../../assets/images/DepositCashPopup/copy.svg";
import QRCodeLogo from "../../assets/images/qr-code-logo.png";
import { QRCode } from 'react-qrcode-logo';
import './InputWithCopyIcon.css';
import { useTranslation } from 'react-i18next';

const InputWithCopyIcon = ({ CryptoDepositAddress }) => {
  const [inputValue, setInputValue] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (CryptoDepositAddress) {
      setInputValue(CryptoDepositAddress);
    }
  }, [CryptoDepositAddress]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(inputValue).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Show "Copied" for 2 seconds
    });
  };

  return (
    <div className="copy-address">
      <div className="qr-code-container">
        <QRCode value={inputValue} size={180} logoPadding={3} eyeRadius={10} fgColor="#5185e9" removeQrCodeBehindLogo={true} logoImage={QRCodeLogo} logoPaddingStyle="circle" qrStyle="square" />
      </div>
      <div className="input-container">
        <input
          type="text"
          className="text-input"
          value={isCopied ? t('copied') : inputValue}
          readOnly
        />
        <img
          src={CopyIcon}
          className="copy-icon"
          alt="Copy"
          onClick={handleCopyClick}
        />
      </div>

    </div>
  );
};

export default InputWithCopyIcon;
