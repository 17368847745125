import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import Select from "react-select";
import "./DTableAccountSettings.css";
import { useViewport } from "../../hooks/ViewportProvider";
import DatePickerButton from "./DatePickerButtonAccountSettings/DatePickerButtonAccountSettings";
import { ReactComponent as ClearFilterButton } from "../../assets/images/cancel.svg";
import { useTranslation } from "react-i18next";

const WrappedCell = styled.div`
  white-space: normal;
  word-break: break-word;
`;

const NoDataComponent = styled.div`
  color: white;
  font-size: 14px;
  width: 100%;
  font-family: var(--main-font-family);
  text-align: center;
  padding: 20px;
  background-color: #172644;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function DTableAccountSettings({
  rows,
  columns,
  filter,
  setFilter,
  pendingBonusBalance,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState(rows);
  const [loading, setLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [perPage, setPerPage] = useState(10);
  const { width: viewportWidth, height: viewportHeight } = useViewport();

  const filterOptions = [
    { value: "transactions", label: t("transactions") },
    { value: "bets", label: t("bets") },
    { value: "withdrawalHistory", label: t("withdrawalHistoryLower") },
  ];

  const customDTableStyles = {
    responsiveWrapper: {
      style: {
        maxHeight: "60%",
        overflowY: "auto",
        overflowX: "auto",
      },
    },
    headRow: {
      style: {
        minHeight: "unset",
        height: "fit-content",
        minWidth: "unset",
        maxWidth: "unset",
        backgroundImage:
          "linear-gradient(90deg, rgba(2, 96, 152, 1), rgba(7, 16, 125, 1))",
      },
    },
    headCells: {
      style: {
        fontSize:
          viewportWidth >= 1040
            ? "16px"
            : viewportWidth >= 655
            ? "10px"
            : "7px",
        fontWeight: 600,
        color: "#fff",
        fontFamily: "var(--main-font-family)",
        minHeight: "unset",
        height: "fit-content",
        padding: viewportHeight >= 400 ? "10px" : "0",
      },
    },
    cells: {
      style: {
        fontSize:
          viewportWidth >= 690 ? "14px" : viewportWidth >= 550 ? "10px" : "7px",
        fontWeight: 300,
        fontFamily: "var(--main-font-family)",
        backgroundColor: "transparent",
        borderBottom: "1px solid white",
        height: "auto",
        padding: viewportHeight >= 400 ? "10px" : "0",
        whiteSpace: "normal",
        wordBreak: "break-word",
        color: "white",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid white",
        minHeight: "unset",
        height: "fit-content",
        backgroundColor: "transparent",
      },
    },
    table: {
      style: {
        backgroundColor: "transparent",
      },
    },
    pagination: {
      style: {
        fontFamily: "var(--main-font-family)",
        fontWeight: 400,
        color: "white",
        backgroundColor: "transparent",
        minHeight: "unset",
        fontSize: viewportHeight >= 595 ? "14px" : "10px",
        height: "fit-content",
      },
      pageButtonsStyle: {
        fontFamily: "var(--main-font-family)",
        fontWeight: 400,
        color: "white",
        backgroundColor: "transparent",
        "&:hover:not(:disabled)": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      },
    },
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#172644",
      borderColor: "var(--main-font-color)",
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#172644",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#5185E9" : "#172644",
      color: "white",
      "&:hover": {
        backgroundColor: "#5185E9",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    setLoading(false);
    setData(rows);
    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
    // eslint-disable-next-line
  }, [rows]);

  useEffect(() => {
    const handleResize = () => {
      const rowHeight = 65; // Assuming each row is roughly 65px high
      const availableHeight = window.innerHeight - 250; // Subtract non-table space
      const visibleRows = Math.floor(availableHeight / rowHeight);
      setPerPage(visibleRows > 5 ? visibleRows : 5); // Ensure at least 5 rows
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isInitialLoad) {
      setLoading(true);
    }
    // eslint-disable-next-line
  }, [filter]);

  function handleDateRange(dates) {
    setDateRange(dates);

    const [start, end] = dates;

    if (start && end) {
      const adjustedStart = new Date(start.setHours(0, 0, 0, 0));
      const adjustedEnd = new Date(end.setHours(23, 59, 59, 999));

      const filteredRows = rows.filter((row) => {
        const rowDate = new Date(row.date);
        return rowDate >= adjustedStart && rowDate <= adjustedEnd;
      });

      setData(filteredRows);
    } else {
      setData(rows); // Reset data if date range is cleared
    }
  }

  function clearDateRange() {
    setDateRange([null, null]);
    setData(rows);
  }

  let options = [5, 10, 15, 20, 25, 30].filter((option) => option !== perPage);
  options.unshift(perPage);

  const updatedColumns = columns.map((column, index) => {
    if (index === 2) {
      return {
        ...column,
        cell: (row) => <WrappedCell>{column.selector(row)}</WrappedCell>,
      };
    }
    return column;
  });

  const noDataMessage = () => {
    switch (filter) {
      case "transactions":
        return t("noTransactionsToDisplay");
      case "bets":
        return t("noBetsToDisplay");
      case "withdrawalHistory":
        return t("noWithdrawalHistoryToDisplay");
      default:
        return t("noDataToDisplay");
    }
  };

  return (
    <>
      <div className="d-table-account-settings-container">
        <div className="pending-bonus-balance">
          {t("pendingBonusTokenCashback")}:{" "}
          <span className="profit-value">
            {pendingBonusBalance.toLocaleString()}
          </span>
        </div>
        <div className="d-table-account-settings-input-group">
          <div className="filter-group">
            <label className="filter-label">{t("filterBy")}</label>
            <Select
              options={filterOptions}
              value={filterOptions.find((opt) => opt.value === filter)}
              isSearchable={false}
              onChange={(selected) => setFilter(selected.value)}
              styles={customSelectStyles}
              className="filter-dropdown"
            />
          </div>
          <div className="date-picker-group">
            {startDate && endDate && (
              <button className="clear-filter-button" onClick={clearDateRange}>
                <ClearFilterButton />
              </button>
            )}
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRange}
              dateFormat="dd-MMM-yyyy"
              className="account-settings-date-picker"
              maxDate={new Date()}
              showYearDropdown
              scrollableMonthYearDropdown
              customInput={<DatePickerButton />}
            />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            key={perPage}
            columns={updatedColumns}
            data={data}
            noDataComponent={
              <NoDataComponent>{noDataMessage()}</NoDataComponent>
            }
            defaultSortAsc={false}
            fixedHeader
            responsive
            pagination
            paginationRowsPerPageOptions={options}
            paginationDefaultPage={1}
            paginationPerPage={perPage}
            customStyles={customDTableStyles}
          />
        )}
      </div>
    </>
  );
}

export default DTableAccountSettings;
