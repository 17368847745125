import React, { useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./SubBannerCards.css";

import SubBannerCardImg1 from "../../assets/images/SubBannerCardImages/casino_button_bg.jpg";
import SubBannerCardImg2 from "../../assets/images/SubBannerCardImages/poker_button_bg.jpg";
import SubBannerCardImg3 from "../../assets/images/SubBannerCardImages/sports_button_bg.png";
import SubBannerCard from "./SubBannerCard/SubBannerCard";

const SubBannerCards = React.memo(() => {
  const responsive = useMemo(() => ({
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 900, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
  }), []);

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay={false}
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable={false}
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <SubBannerCard buttonImage={SubBannerCardImg1} redirectTo="/casino" />
      <SubBannerCard buttonImage={SubBannerCardImg2} redirectTo="/poker" />
      <SubBannerCard buttonImage={SubBannerCardImg3} redirectTo="/sports" />
    </Carousel>
  );
});

export default SubBannerCards;
