import React from 'react';
import './ViewAllButton.css';

const ViewAllButton = ({ onClick, text }) => {
  return (
    <button className="view-all-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default ViewAllButton;
