import React from 'react';
import PropTypes from 'prop-types';
import './partnerButton.css';

const PartnerButton = React.memo(({ buttonImage, altText, onClick }) => {
    return (
        <div className="partner-button" onClick={onClick}>
            <img src={buttonImage} alt={altText} className="partner-button-image" />
        </div>
    );
});

PartnerButton.propTypes = {
    buttonImage: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PartnerButton;
