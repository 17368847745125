const TemporaryLanguageList = [
  { name: "English", code: "en", icon: require('../../assets/images/Flags/en.png') },
  { name: "Arabic", code: "ar", icon: require('../../assets/images/Flags/ar.png') },
  { name: "German", code: "de", icon: require('../../assets/images/Flags/de.png') },
  { name: "Spanish", code: "es", icon: require('../../assets/images/Flags/es.png') },
  { name: "French", code: "fr", icon: require('../../assets/images/Flags/fr.png') },
  { name: "Italian", code: "it", icon: require('../../assets/images/Flags/it.png') },
  { name: "Japanese", code: "jp", icon: require('../../assets/images/Flags/jp.png') },
  { name: "Korean", code: "kr", icon: require('../../assets/images/Flags/kr.png') },
  { name: "Polish", code: "pl", icon: require('../../assets/images/Flags/pl.png') },
  { name: "Portuguese", code: "pt", icon: require('../../assets/images/Flags/pt.png') },
  { name: "Russian", code: "ru", icon: require('../../assets/images/Flags/ru.png') },
  { name: "Thai", code: "th", icon: require('../../assets/images/Flags/th.png') },
  { name: "Turkish", code: "tr", icon: require('../../assets/images/Flags/tr.png') },
  { name: "Vietnamese", code: "vn", icon: require('../../assets/images/Flags/vn.png') },
  { name: "Chinese", code: "cn", icon: require('../../assets/images/Flags/cn.png') },
];

export default TemporaryLanguageList;
