import { useContext, useEffect } from "react";
import { Context } from "../App";
import { getTokenCheckExpiryAndRefreshIfNeeded } from "../utils/auth";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useContext(Context);

  useEffect(() => {
    const checkAuth = async () => {
      const token = await getTokenCheckExpiryAndRefreshIfNeeded();
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        localStorage.removeItem("accessToken");
      }
    };

    checkAuth();
  }, [setIsLoggedIn]);

  return isLoggedIn;
};

export default useAuth;
