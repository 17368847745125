import React, { useState, useEffect } from 'react';
import "./BankPaymentPopup.css"; // Make sure this file exists and contains the required styles
import Close from "../../assets/images/close.svg"; // Import the Close icon
import SpinnerLoading from '../Loading/SpinnerLoading';

const BankPaymentPopup = ({ onClose, responseData, amount, userId, signedInUsername }) => {
    return (
        <div className="bank-payment-popup-overlay">
            {responseData == null ? (
            <SpinnerLoading/>
            ) : (
            <div className="bank-payment-popup">
                <div className="popup-header">
                            <h2 className="bankPopupHeader">Deposit</h2>
                            <button className="close" onClick={onClose}>
                                <img src={Close} alt="close" />
                            </button>
                        </div><iframe
                            src={responseData?.redirectUrl}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                            title="bankPopup"
                        ></iframe>
                        <div className="bank-popup-footer">
                            </div>
            </div>)}
        </div>
    );
};

export default BankPaymentPopup;
