import React from "react";
import "./poker.css";
import EventsPageBannerCards from "../../components/EventsPageBannerCards/EventsPageBannerCards";
import Subheader from "../../components/PageSubheader/PageSubheader";
import CategorySection from '../../components/CategorySection/CategorySection';
import { useTranslation } from 'react-i18next';

//Category Images
import SlotBattlesImage from '../../assets/images/CasinoCategories/slot_battles_button_bg.jpg';
import CrashImage from '../../assets/images/CasinoCategories/crash_button_bg.jpg';
import RouletteImage from '../../assets/images/CasinoCategories/roulette_button_bg.jpg';
import DiceImage from '../../assets/images/CasinoCategories/dice_button_bg.jpg';
import HiLoImage from '../../assets/images/CasinoCategories/hilo_button_bg.jpg';

const Poker = () => {  
    const { t } = useTranslation();
    const categories = [
        { text: t('slotBattles'), image: SlotBattlesImage },
        { text: t('crash'), image: CrashImage },
        { text: t('roulette'), image: RouletteImage },
        { text: t('dice'), image: DiceImage },
        { text: 'HiLo', image: HiLoImage },
      ];

    return (
        <div className="poker">
            <EventsPageBannerCards className="poker-banner-cards" TypeOfPage="poker"/>
            <Subheader className="poker-subheader" headerText={t('poker')}/>
            <CategorySection className="poker-categories" sectionName="Poker Categories" categories={categories} />

            <div className="coming-soon">
                <h1>{t('comingSoon')}</h1>
            </div>

        </div>
    )
};    
export default Poker;