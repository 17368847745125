import React from "react";
import "./LogoutConfirmation.css";
import { useTranslation } from "react-i18next";

const LogoutConfirmation = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <div className="logout-popup-mobile">
      <div className="logout-mobile-page-1">
        <div className="logout-popup-header">{t("confirmLogout")}</div>
        <div className="logout-popup-form">
          <p className="logoutSubtext">{t("confirmLogoutText")}</p>
          <div className="logout-button-container">
            <button className="logout-confirm-button" onClick={onConfirm}>
              {t("yes")}
            </button>
            <button className="logout-cancel-button" onClick={onCancel}>
              {t("no")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutConfirmation;
