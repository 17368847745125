import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Settings.css';

const Settings = () => {
    const { t } = useTranslation();

    return (
        <div className="settings-container">
            <div className="settings-tabs">
                <NavLink to="account-info" className={({ isActive }) => (isActive ? 'active-tab' : '')}>
                    {t('accountInformation')}
                </NavLink>
                <NavLink to="activity" className={({ isActive }) => (isActive ? 'active-tab' : '')}>
                    {t('activity')}
                </NavLink>
                <NavLink to="referrals" className={({ isActive }) => (isActive ? 'active-tab' : '')}>
                    {t('referrals')}
                </NavLink>
                <NavLink to="user-settings" className={({ isActive }) => (isActive ? 'active-tab' : '')}>
                    {t('settings')}
                </NavLink>
            </div>
            <div className="settings-content">
                <Outlet />
            </div>
        </div>
    );
};

export default Settings;
