import { getTokenCheckExpiryAndRefreshIfNeeded } from "./auth";

export const getBalances = async () => {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/balance`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          accept: "text/plain",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get balances");
    }

    const data = await response.json(); // Await the response.json() call

    return {
      bonusBalance2dp: data.data.bonusBalance,
      cashBalance2dp: data.data.cashBalance,
      goCreditsBalance2dp: data.data.goCreditsBalance,
      btcWithdrawalAddress: data.data.btcWithdrawalAddress,
    };
  } catch (error) {
    console.error("Error getting balances:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};

export const getPendingBonusBalance = async () => {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/getPendingBonus`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          accept: "text/plain",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get pending bonus balance");
    }

    const data = await response.json(); // Await the response.json() call

    return data;

  } catch (error) {
    console.error("Error getting pending bonus balances:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};

export async function getWalletTransactionRows() {
  try {
    const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

    if (!accessToken) {
      throw new Error("Access token not available");
    }

    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/paginatedWalletTransactions`,
      { method: "GET", headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const parsedData = await response.json();

    return Object.entries(parsedData.data).map(([key, value]) => ({
      serialNumber: value.serialNumber,
      walletType: value.walletType,
      date: new Date(value.timeStamp.split("T")[0]), // Extracting only the date part
      description: value.description,
      amountInGoToken: value.amount,
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function getWalletBetRows() {
  try {
    const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

    if (!accessToken) {
      throw new Error("Access token not available");
    }

    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/betWalletTransactions`,
      { method: "GET", headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const parsedData = await response.json();

    return Object.entries(parsedData.data).map(([key, value]) => ({
      serialNumber: value.serialNumber,
      walletType: value.walletType,
      date: new Date(value.timeStamp.split("T")[0]), // Extracting only the date part
      description: value.description,
      amountInGoToken: value.amount,
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function getWithdrawalRequestRows() {
  try {
    const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

    if (!accessToken) {
      throw new Error("Access token not available");
    }

    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/paginatedWithdrawalRequests`,
      { method: "GET", headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const parsedData = await response.json();

    return Object.entries(parsedData.data).map(([key, value]) => ({
      serialNumber: value.serialNumber,
      date: new Date(value.timeStamp.split("T")[0]), // Extracting only the date part
      description: value.description,
      amount: value.amount,
      status: value.status,
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function createWithdrawalRequest(bodyObj) {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const bodyJson = JSON.stringify(bodyObj);

    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/createWithdrawalRequest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
        body: bodyJson,
      }
    );

    const data = await response.json(); // Await the response.json() call

    if (!response.ok) {
      return data; // Return the error response object
    }

    return data;
  } catch (error) {
    console.error("Error creating withdrawal request: ", error);
    return {
      success: false,
      message: "An error occurred while processing the withdrawal request."
    }; // Return a generic error response object
  }
}


export async function cancelWithdrawalRequest(bodyObj) {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const bodyJson = JSON.stringify(bodyObj);

    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/cancelWithdrawalRequest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
        body: bodyJson,
      }
    );
    if (!response.ok) {
      throw new Error("Failed to cancel withdrawal request.");
    }

    const data = await response.json(); // Await the response.json() call
    return data;
  } catch (error) {
    console.error("Error cancelling withdrawal request: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}


export const login = async (username, password, t) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include', // Include cookies in the request (refreshToken)
      }
    );

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    console.log("Login successful:", data);

    // Check if email is confirmed
    if (!data.data.emailConfirmed) {
      console.log(data)
      return { success: false, message: "Not Validated", data: data.data };
    }

    // Save the access token to localStorage
    localStorage.setItem("accessToken", data.data.accessToken);
    localStorage.setItem("signedInUsername", username);
    localStorage.setItem("countryCode", data.data.countryCode);
    localStorage.setItem("userId", data.data.userId);
    localStorage.setItem("emailAddress", data.data.email);

    return { success: true, data };
  } catch (error) {
    console.error("Error during login:", error);
    return { success: false, message: t('invalidLogin') };
  }
};


export const logout = async () => {
  try {
    console.log("Attempting to logout with URL:", `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/logout`);
    await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/logout`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include', // Include cookies in the request (refreshToken)
      }
    );

    // Clear the access token from localStorage regardless of the outcome
    localStorage.removeItem("accessToken");
    localStorage.removeItem("signedInUsername");
    localStorage.removeItem("countryCode");
    localStorage.removeItem("emailAddress");

    console.log("Logout attempt made");
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

export async function getCountryList() {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/countries`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch country list.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching country list: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function changePassword(id, currentPassword, password, confirmPassword) {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/changePassword`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ id, currentPassword, password, confirmPassword }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to change password.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error changing password: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function createOtp(emailAddress) {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/createOtp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ emailAddress }),
        credentials: 'include'
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create OTP.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating OTP: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function validateOtp(email, validationCode) {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/validateOtp?Email=${encodeURIComponent(email)}&ValidationCode=${encodeURIComponent(validationCode)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to validate OTP.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error validating OTP: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function registerUser(formData, referralUserId = 0, referralUserName = null) {
  const {
    name,
    userName,
    email,
    confirmEmailAddress,
    password,
    confirmPassword,
    dateOfBirth,
    phoneNumber,
    countryId,
  } = formData;

  // Split name into firstName and lastName
  let firstName = name;
  let lastName = "";
  const nameParts = name.split(" ");
  if (nameParts.length > 1) {
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(" ");
  }

  const requestBody = {
    firstName,
    lastName,
    userName,
    email,
    confirmEmailAddress,
    password,
    confirmPassword,
    dateOfBirth,
    phoneNumber,
    countryId,
    goWin: true,
    legId: 1,
    referralUserId,   // Set ReferralUserId to the provided value or 0
    referralUserName, // Set ReferralUserName if provided
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return errorData; // Return the full error response
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error registering user: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}



export async function updateUser(updateData) {
  const requestBody = {
    ...updateData,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/updateUser`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to update user.");
    }

    return data;
  } catch (error) {
    console.error("Error updating user: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}



export async function getUserById(userId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/getUserById?Id=${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch user details.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user details: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function getUserReferrals(userId) {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(`${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/referrals?UserId=${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch user referrals.");
    }

    const data = await response.json();
    console.log(data)
    return data;
  } catch (error) {
    console.error("Error fetching user referrals: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}


export async function getTopPlayedGames() {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/topGames`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch top games.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching top games list: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export async function getTopPlayedLiveGames() {
  const accessToken = await getTokenCheckExpiryAndRefreshIfNeeded();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/topLiveGames`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch top live games.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching top live games list: ", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
}

export const convertCryptoToUsd = async (amountInCurrency, cryptoCurrencyOptionEnumId) => {
  const accessToken = getTokenCheckExpiryAndRefreshIfNeeded();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/cryptoToUSD?cryptoCurrencyAmount=${amountInCurrency}&cryptoCurrencyOptionEnumId=${cryptoCurrencyOptionEnumId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          accept: "text/plain",
        },
      }
    );

    const data = await response.json();
    if (!data.success){
      throw new Error("Failed to fetch currency amount.");
    }
    return {
      usdAmount: data.data.usdAmount
    };
  } catch (error) {
    console.error("Error to fetch currency amount:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};

export const convertUsdToCrypto = async (amountInUsd, cryptoCurrencyOptionEnumId) => {
  const accessToken = getTokenCheckExpiryAndRefreshIfNeeded();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/cryptoCurrencyAmount?amountUSD=${amountInUsd}&cryptoCurrencyOptionEnumId=${cryptoCurrencyOptionEnumId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          accept: "text/plain",
        },
      }
    );

    const data = await response.json();
    if (!data.success){
      throw new Error("Failed to fetch crypto amount.");
    }
    return {
      cryptoCurrencyAmount: data.data.cryptoCurrencyAmount
    };
  } catch (error) {
    console.error("Error to fetch crypto amount:", error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};