import React, { useState, useEffect, useCallback } from "react";
import "./MobilePopup.css";
import Close from "../../assets/images/close.svg";
import BTC from "../../assets/images/DepositCashPopup/btc.svg";
import ETH from "../../assets/images/DepositCashPopup/eth.svg";
import USDC from "../../assets/images/DepositCashPopup/usdc.svg";
import USDT from "../../assets/images/DepositCashPopup/usdt.svg";
import ProceedButton from "../ProceedButton/ProceedButton";
import SubmitRequestButton from "../SubmitRequestButton/SubmitRequestButton";
import InputWithCopyIcon from "../InputWithCopyIcon/InputWithCopyIcon";
import OneTimePasswordNoSecurityWords from "../OneTimePasswordNoSecurityWordsPopup/OneTimePasswordNoSecurityWordsPopup";
import { useTranslation } from "react-i18next";
import { getBalances, createWithdrawalRequest, createOtp, validateOtp, convertUsdToCrypto } from "../../utils/api";
import WalletConnectIcon from "../../assets/images/DepositCashPopup/wallet_connect.svg";
import WalletConnectedIcon from "../../assets/images/DepositCashPopup/wallet_connected.svg";
import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react';
import AlertDialog from '../../components/AlertDialog/AlertDialog'; 
import InfoIcon from "../../assets/images/DepositCashPopup/info.svg"; 
import { getEthBalanceFromWalletConnect } from "../../utils/walletConnect";
import Bank from "../../assets/images/DepositCashPopup/bank.png";
import WithdrawFormMobile from "../DepositCashPopup/WithdrawFormMobile";
import HeaderSelectionMobile from "../DepositCashPopup/HeaderSelectionMobile";

const MobilePopup = ({
    currentPage,
    amount,
    selectedPayment,
    errorMessage,
    paymentErrorMessage,
    responseData,
    isVisible,
    loading,
    handleNextPage,
    handlePreviousPage,
    handleSubmit,
    handleAmountChange,
    handlePaymentSelection,
    toggleDisclaimer,
    onClose,
    CryptoCurrencyOptionEnum,
    currentMode,
    handleModeChange, // Receive mode change handler
    handleSubmitWalletConnect,
    backSubmit,
    handleWalletConnectSubmit,
    loadingDepositWalletConnect,
    depositConnectWalletErrorMessage,
    isDialogOpen,
    handleCloseDialog,
    dialogMessage,
    walletConnectionErrorMessage,
    handlePaymentSelectionWalletConnect,
    selectedPaymentWalletConnect,
    walletAmountUsdtAndUsdc,
    walletAmountUsdtAndUsdcToUsd,
    walletAmountEth,
}) => {
    const { t } = useTranslation();

    // Withdrawal state and functions
    const [btcWithdrawalAddress, setBtcWithdrawalAddress] = useState("");
    const [requestedAmount, setRequestedAmount] = useState("");
    const [receivableAmount, setReceivableAmount] = useState("");
    const [cashBalance, setCashBalance] = useState(0);
    const [withdrawalError, setWithdrawalError] = useState("");
    const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
    const [withdrawalLoading, setWithdrawalLoading] = useState(false);
    const [otpVisible, setOtpVisible] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const [walletAmountUsd, setWalletAmountUsd] = useState("");
    const [walletConnectAmount, setWalletConnectAmount] = useState("");
    const [connectWalletErrorMessage, setConnectWalletErrorMessage] = useState("");
    const [cryptoAmount, setCryptoAmount] = useState(0);
    const [loadingWalletConnect, setLoadingWalletConnect] = useState(false);
    const { open } = useWeb3Modal();
    const [hasFetchedBalance, setHasFetchedBalance] = useState(false);
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
    const [walletConnectAmountEth, setWalletConnectAmountEth] = useState(0);

    const getEthBalance = async () => {
      if (!isConnected){
        return;
      }
      if(hasFetchedBalance){
        return;
      }
  
      const { balanceInEth, usdAmount } = await getEthBalanceFromWalletConnect(CryptoCurrencyOptionEnum.ETH.id, address, walletProvider);
      if(walletAmountEth == null){
        setWalletConnectAmountEth(balanceInEth);
      }
      localStorage.setItem("walletConnectUsdAmount", usdAmount);
      setWalletAmountUsd(usdAmount);
      setHasFetchedBalance(true);
    };

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const { cashBalance2dp, btcWithdrawalAddress: btcWithdrawalAddressFromFetch } = await getBalances();
                setCashBalance(Math.floor(cashBalance2dp));
                setBtcWithdrawalAddress(btcWithdrawalAddressFromFetch);
            } catch (error) {
                console.error("Error fetching balances:", error);
            }
        };

        const storedEmailAddress = localStorage.getItem("emailAddress");
        setEmailAddress(storedEmailAddress);

        fetchBalance();
        if(!hasFetchedBalance){
            getEthBalance();
        }
  }, [hasFetchedBalance, getEthBalance]);
    
    const handleRequestedAmountChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setRequestedAmount("");
            setReceivableAmount("");
        } else {
            const wholeNumber = Math.floor(Math.abs(value)); // Ensure the value is a whole number
            setRequestedAmount(wholeNumber);
            setReceivableAmount((wholeNumber * 0.9).toFixed(2)); // Calculate and set receivable amount
        }
        setFormSubmitted(false);
    };

    const handleWithdrawalSubmit = async (event) => {
        event.preventDefault();

        setFormSubmitted(true);

        if (requestedAmount < 100) {
            return;
        }

        setWithdrawalError(""); // Clear any previous error
        setWithdrawalLoading(true);

        try {
            await createOtp(emailAddress);
            setOtpVisible(true); // Show the OTP component
        } catch (error) {
            console.error("Error creating OTP: ", error);
            setWithdrawalError(t("failedToSendCode"));
            setWithdrawalLoading(false);
        }
    };

    const handleOtpClose = async (otp) => {
        setOtpVisible(false);
        if (otp) {
            setWithdrawalLoading(true);
            try {
                const otpResponse = await validateOtp(emailAddress, otp);
                if (otpResponse.success) {
                    const body = {
                        RequestedAmount: requestedAmount,
                        BTCWithdrawalAddress: btcWithdrawalAddress ?? "",
                    };

                    const response = await createWithdrawalRequest(body);
                    if (response.success) {
                        setCashBalance((prevBalance) => prevBalance - requestedAmount); // Update the balance
                        setWithdrawalSuccess(true);
                        setTimeout(() => {
                            setWithdrawalSuccess(false);
                        }, 3000);
                        setRequestedAmount("");
                        setReceivableAmount("");
                        setFormSubmitted(false);
                    } else {
                        // Show the specific message from the response if available
                        setWithdrawalError(response.message || t("withdrawalFailed"));
                    }
                } else {
                    setWithdrawalError(t("invalidCode"));
                }
            } catch (error) {
                console.error("Error processing withdrawal request: ", error);
                setWithdrawalError(t("withdrawalFailed"));
            } finally {
                setWithdrawalLoading(false);
            }
        } else {
            setWithdrawalLoading(false);
        }
    };

      const handleWalletConnectAmountChange = async (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
          setWalletConnectAmount(value);
          localStorage.setItem("depositWalletConnectAmount", value);
          let storageWalletUsd = 0;
          if(selectedPaymentWalletConnect === "ETH"){
            storageWalletUsd = walletAmountUsd;
          } else if(selectedPaymentWalletConnect === "USDT" || selectedPaymentWalletConnect === "USDC"){
            storageWalletUsd = walletAmountUsdtAndUsdcToUsd;
          }
          if (value === '0' || value === '') {
            setCryptoAmount(0);
            setConnectWalletErrorMessage('');
          } else if (parseFloat(value) <= parseFloat(storageWalletUsd)) {
            const { cryptoCurrencyAmount } = await convertUsdToCrypto(value, CryptoCurrencyOptionEnum[localStorage.getItem("selectedSymbolWalletConnect")]?.id ?? CryptoCurrencyOptionEnum.ETH.id);
            setCryptoAmount(cryptoCurrencyAmount);
            setConnectWalletErrorMessage('');
          } else {
            setConnectWalletErrorMessage(t("insufficientWallet"));
          }
        }
      };
      const handleSelection = (event) => {
        handlePaymentSelection(event);
    };
      const handleWalletConnect = useCallback(async () => {
        setLoadingWalletConnect(true);
        try {
          setHasFetchedBalance(false);
          localStorage.setItem("selectedSymbolWalletConnect", "");
          await open();
        } catch (error) {
          
        } finally {
          setLoadingWalletConnect(false);
        }
      }, [open]);

    return (
        <div className="depositContainer">
            <div className="popup-mobile">
                <div className="popup-header">
                    <button className="close" onClick={handlePreviousPage}>
                        <img src={Close} alt="close" />
                    </button>
                    <HeaderSelectionMobile currentMode={currentMode} handleModeChange={handleModeChange} />
                </div>
                {currentMode === 'deposit' && (
                    <>
                        {currentPage === 1 && (
                            <div className="mobile-page-1">
                                <div className="popup-form">
                                <div className="text-center">
                      <button
                        className="wallet-connect-button"
                        onClick={handleSubmitWalletConnect}
                      >
                        <img
                          src={WalletConnectIcon}
                          className="wallet-connect-icon"
                        />
                        {t("useWalletConnect")}
                      </button>
                      <div 
      className="tooltip-container"
      onMouseEnter={() => setIsVisibleTooltip(true)}
      onMouseLeave={() => setIsVisibleTooltip(false)}
    >
     <img 
    src={InfoIcon}
    className="infoIconMobile" 
    />
      {isVisibleTooltip && <div className="tooltip-text">{t("infoText1")}</div>}
    </div>
                    </div>
                                    <div className="form-choose-payment">
                                        <h2 className="popupPaymentOptions">{t("paymentOptions")}</h2>
                                        <p className="subText">{t("selectCrypto")}</p>
                                        <div className="bank-option-container">
                                <label
                                    className={`bank-button ${selectedPayment === "bank" ? "selected" : ""}`}
                                >
                                    <input
                                        type="radio"
                                        name="options"
                                        value="bank"
                                        checked={selectedPayment === "bank"}
                                        onChange={handleSelection}
                                        disabled={loading}
                                    />
                                    <div className="label-content">
                                        <img src={Bank} className="icon" alt="bank" />
                                        <span className="text">
                                            {t("creditOrDebitCard")}
                                        </span>
                                    </div>
                                </label>
                            </div>
                                        <div className="mobile-button-container">
                                            {Object.values(CryptoCurrencyOptionEnum).map((option) => (
                                                <label
                                                    key={option.id}
                                                    className={`grid-button ${selectedPayment === option.symbol ? "selected" : ""}`}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        value={option.symbol}
                                                        checked={selectedPayment === option.symbol}
                                                        onChange={handlePaymentSelection}
                                                        disabled={loading}
                                                    />
                                                    <div className="label-content">
                                                        <img
                                                            src={
                                                                option.symbol === "BTC"
                                                                    ? BTC
                                                                    : option.symbol === "ETH"
                                                                        ? ETH
                                                                        : option.symbol === "USDC"
                                                                            ? USDC
                                                                            : USDT
                                                            }
                                                            className="icon"
                                                            alt={option.symbol}
                                                        />
                                                        <span className="text">
                                                            {option.symbol} {option.name}
                                                        </span>
                                                    </div>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-enter-amount">
                                        <h2 className="amountHeader">{t("enterAmount")}</h2>
                                        <input
                                            type="number"
                                            className="amountInput"
                                            autoFocus
                                            min="1"
                                            step="1"
                                            value={amount}
                                            onChange={handleAmountChange}
                                            disabled={loading}
                                        />
                                        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                                        {paymentErrorMessage && <p className="errorMessage">{paymentErrorMessage}</p>}
                                        <p className="minimumAmount">*{t("minimumAmount")}</p>
                                    </div>
                                    <hr />
                                </div>
                                <div className="popup-footer">
                                    <ProceedButton
                                        text={t("proceed")}
                                        loading={loading}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        )}
                        {currentPage === 2 && (
                            <div className={isVisible ? "mobile-page-2" : "mobile-page-2-disclaimer"}>
                                <div className="popup-form">
                                    <div className="form-choose-payment">
                                        <h2 className="popupPaymentOptions">{t("pleaseDeposit")}</h2>
                                        <h1 className="depositAmount">
                                            {responseData?.cryptoCurrencyAmount}{" "}
                                            <span className="depositAmountOf">{t("of")}</span>{" "}
                                            {selectedPayment}
                                        </h1>
                                        <h1 className="depositAmountSubtext">{t("toAddress")}</h1>
                                    </div>
                                    <InputWithCopyIcon
                                        CryptoDepositAddress={responseData?.cryptoCurrencyDepositAddress}
                                    />
                                </div>
                                <hr />
                                <div className="popup-footer">
                                    <ProceedButton text={t("confirm")} onClick={handleSubmit} />
                                    <button className="disclaimer-button" onClick={toggleDisclaimer}>
                                        {t("viewDisclaimer")}
                                    </button>
                                    <div
                                        className={
                                            isVisible ? "disclaimer-not-visible" : "disclaimer-visible"
                                        }
                                    >
                                        <p>{t("disclaimerText1")}</p>
                                        <p>{t("disclaimerText2")}</p>
                                        <p>{t("disclaimerText3")}</p>
                                        <p>{t("disclaimerText4")}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentPage === 3 && (
                <div className="mobile-page-3">
                  <div className="popup-form-wallet-connected">
                    <div className="form-wallet-connected">
                      <div className="text-center">
                        <h5>{t("depositWithWalletConnect")}
                        <img
                                src={WalletConnectIcon}
                                className="wallet-connect-icon-2"
                              /></h5>
                              {isConnected && (
                          <div>
                            <h6>
                             {t("wallectConnected")}
                              <img
                                src={WalletConnectedIcon}
                                className="wallet-connected-icon"
                                alt="Wallet Connected Icon"
                              />
                            </h6>
                        <center>
                        <w3m-account-button balance="false"/>
                        </center>
                        <div className="form-choose-payment">
                                        <h2 className="popupPaymentOptions">{t("paymentOptions")}</h2>
                                        <p className="subText">{t("selectCrypto")}</p>
                                        <div className="mobile-button-container">
                                            {Object.values(CryptoCurrencyOptionEnum)
                                                .filter(option => option.symbol !== "BTC")  // Exclude BTC
                                                .map((option) => (
                                                <label
                                                    key={option.id}
                                                    className={`grid-button-wallet-connect ${selectedPaymentWalletConnect === option.symbol ? "selected" : ""}`}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="options"
                                                        value={option.symbol}
                                                        checked={selectedPaymentWalletConnect === option.symbol}
                                                        onChange={handlePaymentSelectionWalletConnect}
                                                        disabled={loadingDepositWalletConnect}
                                                    />
                                                    <div className="label-content-wallet-connect">
                                                        <img
                                                            src={
                                                                option.symbol === "ETH"
                                                                        ? ETH
                                                                        : option.symbol === "USDC"
                                                                            ? USDC
                                                                            : USDT
                                                            }
                                                            className="icon"
                                                            alt={option.symbol}
                                                        />
                                                        <span className="text">
                                                            {option.symbol} {option.name}
                                                        </span>
                                                    </div>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                    <hr />
                          </div>
                        )}
                        {!isConnected && (
                          <div>
                            <button
                              className="wallet-connect-button"
                              onClick={handleWalletConnect}
                            >
                              {loadingWalletConnect ? <div className="proceed-loading-spinner"></div> : <> <img
                                src={WalletConnectIcon}
                                className="wallet-connect-icon"
                              /> {t("connectWallet")}</>}
                            </button>
                          </div>
                        )}
                      </div>
                      {isConnected && (
                        <div className="form-wallet-balance">
                          <h2 className="walletBalance">
                            {t("walletBalance")}
                            <span className="etheriumAmount">
                            {selectedPaymentWalletConnect === "ETH"
                                    ? (walletAmountEth !== null ? walletAmountEth : walletConnectAmountEth)
                                    : selectedPaymentWalletConnect === "USDC"
                                    ? walletAmountUsdtAndUsdc
                                    : walletAmountUsdtAndUsdc
                            }
                          <img className="etheriumIcon"
                                src={selectedPaymentWalletConnect === "ETH"
                                  ? ETH
                                  : selectedPaymentWalletConnect === "USDC"
                                  ? USDC
                                  : USDT
                                }
                              />
                          </span>
                          </h2>
                          <div class="input-container-box">
                          <input
                            type="number"
                            className="amountInput"
                            autoFocus
                            min="1"
                            step="1"
                            value={selectedPaymentWalletConnect === "ETH"
                              ? walletAmountUsd
                              : selectedPaymentWalletConnect === "USDC"
                              ? walletAmountUsdtAndUsdcToUsd
                              : walletAmountUsdtAndUsdcToUsd
                              }
                            disabled={true}
                            name="connectWalletInputAmount"
                          />
                          <div className='input-container-box-icon'>$</div>
                          </div>
                        </div>
                      )}
                      {isConnected && (
                      <div className="form-enter-amount">
                        <h2 className="amountHeader">{t("enterAmount")}
                          <span className="etheriumAmount">
                            {isConnected ? cryptoAmount : 0}
                          <img className="etheriumIcon"
                               src={
                                selectedPaymentWalletConnect === "ETH"
                                  ? ETH
                                  : selectedPaymentWalletConnect === "USDC"
                                  ? USDC
                                  : USDT
                              }
                              />
                          </span>
                        </h2>
                        <div class="input-container-box">
                        <input
                          type="number"
                          className="amountInput"
                          autoFocus
                          min="1"
                          step="1"
                          value={isConnected ? walletConnectAmount : ''}
                          onChange={handleWalletConnectAmountChange}
                          disabled={!isConnected}
                        />
                        <div className='input-container-box-icon'>$</div>
                        </div>
                        <p className="minimumAmount">*{t("minimumAmountWalletConnect")}</p>
                        {connectWalletErrorMessage && isConnected && (
                          <p className="errorMessage">{connectWalletErrorMessage}</p>
                        )}
                        {depositConnectWalletErrorMessage && isConnected && (
                          <p className="errorMessage">{depositConnectWalletErrorMessage}</p>
                        )}
                      </div>
                      )}
                      {walletConnectionErrorMessage && !isConnected && (
                          <p className="errorMessage">{walletConnectionErrorMessage}</p>
                        )}
                    </div>
                  </div>
                  <hr />
                  <div className="popup-footer">
                    <ProceedButton text={t("deposit")} onClick={handleWalletConnectSubmit} loading={loadingDepositWalletConnect}/>
                    <button className="disclaimer-button" onClick={backSubmit}>
                      {t("backText")}
                    </button>
                    <div className="walletConnectText">
                      <p>
                        {t("walletConnectText1")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
                    </>
                )}
                {currentMode === 'withdraw' && (
                    <WithdrawFormMobile />
                )}
            </div>
            <AlertDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialogMessage}
      />
        </div>
    );
};

export default MobilePopup;
