// src/hooks/useOtpValidation.js

import { useState } from 'react';
import { validateOtp, createOtp, getUserById, updateUser } from '../utils/api';

export const useOtpValidation = (t) => {
    const [loading, setLoading] = useState(false);
    const [otpError, setOtpError] = useState('');

    const handleOtpValidate = async (email, enteredOtp, userId, onSuccess) => {
        setLoading(true);
        try {
            const response = await validateOtp(email, enteredOtp);
            if (response.success && response.data.status !== "ValidationFailed") {
                const userDetails = await getUserById(userId);
                const updateData = {
                    id: userDetails.data.id,
                    userName: userDetails.data.userName,
                    firstName: userDetails.data.firstName,
                    lastName: userDetails.data.lastName,
                    email: userDetails.data.email,
                    phoneNumber: userDetails.data.phoneNumber,
                    addressLine1: userDetails.data.addressLine1 || "",
                    addressLine2: userDetails.data.addressLine2,
                    blackCard: userDetails.data.blackCard,
                    blockRemarks: userDetails.data.blockRemarks,
                    city: userDetails.data.city,
                    countryId: userDetails.data.countryId,
                    emailConfirmed: true,
                    goWin: true,
                    infin8Card: userDetails.data.infin8Card,
                    instagramHandle: userDetails.data.instagramHandle,
                    isDeleted: userDetails.data.isDeleted,
                    isDisabled: userDetails.data.isDisabled,
                    latitude: userDetails.data.latitude,
                    longitude: userDetails.data.longitude,
                    passwordChangeRequired: userDetails.data.passwordChangeRequired,
                    profileUpdateRequired: userDetails.data.profileUpdateRequired,
                    region: userDetails.data.region,
                    securityWordsGenerated: userDetails.data.securityWordsGenerated,
                    securityWordsResetRequired: userDetails.data.securityWordsResetRequired,
                    password: null,
                    confirmPassword: null,
                    dateOfBirth: userDetails.data.dateOfBirth,
                    roles: userDetails.data.roles
                };
                const updateResponse = await updateUser(updateData);
                if (updateResponse.success) {
                    onSuccess();
                } else {
                    setOtpError(t('updateFailed'));
                }
            } else {
                setOtpError(t('invalidCode'));
            }
        } catch (error) {
            setOtpError(t('invalidCode'));
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async (email) => {
        setLoading(true);
        try {
            await createOtp(email); // Resend OTP
            setOtpError('');
        } catch (error) {
            setOtpError(t('failedToSendCode'));
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        setLoading, // Ensure setLoading is returned
        otpError,
        handleOtpValidate,
        handleResendOtp
    };
};
