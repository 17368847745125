import { useCallback } from 'react';
import { registerUser } from '../utils/api';

const useRegister = () => {
    const handleRegister = useCallback(async (registerData, referralUserId = null, referralUserName = null) => {
        try {
            // Call the API function
            const response = await registerUser(registerData, referralUserId, referralUserName);

            console.log("Registered with data:", response);

            // Simulate a successful response
            return { message: "Registration successful!", type: "success" };

        } catch (error) {
            console.error("An error occurred during registration:", error);
            return { message: "Registration failed", type: "danger" };
        }
    }, []);

    return { handleRegister };
};

export default useRegister;
