import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./eventButton.css";

const EventButton = ({
  buttonImage,
  gameProvider,
  gameName,
  gameName2,
  rootPage,
  gameTitle,
  hasLive
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  const handleClick = () => {
    const encodedGameName2 = encodeURIComponent(gameName2);
    navigate(`/games/${encodedGameName2}`, { state: { gameProvider, rootPage, buttonImage, gameTitle, hasLive } });
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const buttonStyles = {
    "--button-bg-image": `url(${buttonImage})`
  };

  return (
    <div style={{ position: "relative" }} onClick={handleClick}>
      <div className="live-icon">{t("live")}</div>
      <div className="event-button" style={buttonStyles}>
        {!imageError ? (
          <img
            src={buttonImage}
            alt={gameTitle}
            className="hidden-image"
            onError={handleImageError}
          />
        ) : (
          <span className="button-text">{gameTitle}</span>
        )}
      </div>
    </div>
  );
};

export default EventButton;
