import React from "react";
import "./featuredGamesButton.css";
import { useNavigate } from "react-router-dom";

const FeaturedGamesButton = ({
  buttonImage,
  gameProvider,
  gameName,
  gameName2,
  rootPage,
  slidesToSlide,
  singleButtonWidth,
  buttonHeight,
  gameTitle,
  hasLive
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    const encodedGameName2 = encodeURIComponent(gameName2);
    navigate(`/games/${encodedGameName2}`, { state: { gameProvider, rootPage, buttonImage, gameTitle, hasLive } });
  };

  const buttonStyleInitial = {
    "--button-bg-image": `url(${buttonImage})`,
  };

  const singleAndDoubleButtonStyle = {
    ...buttonStyleInitial,

  };

  return (
    <button
      className="featured-games-button"
      onClick={handleClick}
      style={
        slidesToSlide <= 2 ? singleAndDoubleButtonStyle : buttonStyleInitial
      }
    ></button>
  );
};

export default FeaturedGamesButton;
