import React, { useState, useEffect, useContext, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header/header";
import NavBar from "./NavBar/navbar";
import Footer from "./Footer/footer";
import { Context } from "../App";
import LoginPopup from "../components/LoginPopup/loginPopup";
import RegisterPopup from "../components/RegisterPopup/RegisterPopup";
import LogoutConfirmation from "../components/LogoutConfirmation/LogoutConfirmation";
import LocalizationSelectorPopup from "../components/LocalizationSelectorPopup/LocalizationSelectorPopup";
import useLogin from "../hooks/useLogin";
import useLogout from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";
import useRegister from "../hooks/useRegister";
import "./layout.css";
import { useLanguage } from '../context/LanguageContext';

const Layout = () => {
  const [isLoggedIn, setIsLoggedIn] = useContext(Context);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [showLocalizationPopup, setShowLocalizationPopup] = useState(false);
  const { language, changeLanguage } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const navigate = useNavigate();
  const { login } = useLogin();
  const { logout } = useLogout();
  const { handleRegister } = useRegister();

  useAuth();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    }
  }, [changeLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignInClick = useCallback(() => {
    setShowLoginPopup(true);
  }, []);

  const handleRegisterClick = useCallback(() => {
    setShowRegisterPopup(true);
  }, []);

  const handleCloseLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  const handleCloseRegisterPopup = useCallback(() => {
    setShowRegisterPopup(false);
  }, []);

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true);
    setShowLoginPopup(false);
  };

  const handleLogin = useCallback(async (username, password, rememberMe) => {
    const result = await login(username, password);
    if (result.success) {
      if (!result.data.data.emailConfirmed) {
        return {
          success: false,
          message: "Not validated",
          data: result.data,
        };
      }
      setIsLoggedIn(true);
      setShowLoginPopup(false);
      setHamburgerOpen(false);
      return {
        success: true,
        message: "Login successful",
        data: result.data,
      };
    } else {
      return {
        success: false,
        message: result.message,
        data: result.data,
      };
    }
  }, [login, setIsLoggedIn]);

  const handleLogout = useCallback(() => {
    setShowLogoutConfirmation(true);
  }, []);

  const confirmLogout = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("signedInUsername");
    localStorage.removeItem("emailAddress");
    localStorage.removeItem("userId");
    setIsLoggedIn(false);
    navigate("/");
    setHamburgerOpen(false);
    setShowLogoutConfirmation(false);
    logout();
  }, [logout, navigate, setIsLoggedIn]);

  const cancelLogout = useCallback(() => {
    setShowLogoutConfirmation(false);
  }, []);

  return (
    <div>
      {showLoginPopup && (
        <LoginPopup onClose={handleCloseLoginPopup} onLogin={handleLogin} onSuccessfulLogin={handleSuccessfulLogin} />
      )}
      {showRegisterPopup && (
        <RegisterPopup onClose={handleCloseRegisterPopup} onRegister={handleRegister} />
      )}
      {showLocalizationPopup && (
        <LocalizationSelectorPopup
          setShowLocalizationPopup={setShowLocalizationPopup}
          setSelectedLanguage={changeLanguage}
          selectedLanguage={language}
        />
      )}
      {showLogoutConfirmation && (
        <LogoutConfirmation onConfirm={confirmLogout} onCancel={cancelLogout} />
      )}
      <Header
        isLoggedIn={isLoggedIn}
        handleSignInClick={handleSignInClick}
        handleRegisterClick={handleRegisterClick}
        handleLogout={handleLogout}
        setShowLocalizationPopup={setShowLocalizationPopup}
      />
      <NavBar
        isLoggedIn={isLoggedIn}
        handleSignInClick={handleSignInClick}
        handleRegisterClick={handleRegisterClick}
        handleLogout={handleLogout}
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={setHamburgerOpen}
      />
      <div className={`${language === "de" ? "for-german content" : isMobile ? "mobile-content" : "content"}`}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(Layout);
