import React, { createContext, useState, useEffect, useCallback } from 'react';

const RecentlyPlayedContext = createContext();

const RecentlyPlayedProvider = ({ children }) => {
    const [recentlyPlayed, setRecentlyPlayed] = useState([]);

    useEffect(() => {
        const storedRecentlyPlayed = JSON.parse(localStorage.getItem('recentlyPlayed')) || [];
        setRecentlyPlayed(storedRecentlyPlayed);
    }, []);

    const addRecentlyPlayed = useCallback((game) => {
        setRecentlyPlayed((prev) => {
            const updated = [game, ...prev.filter((g) => g.gameName !== game.gameName)];
            const limited = updated.slice(0, 10); // Limit to last 10 games
            localStorage.setItem('recentlyPlayed', JSON.stringify(limited));
            return limited;
        });
    }, []);

    return (
        <RecentlyPlayedContext.Provider value={{ recentlyPlayed, addRecentlyPlayed }}>
            {children}
        </RecentlyPlayedContext.Provider>
    );
};

export { RecentlyPlayedContext, RecentlyPlayedProvider };
