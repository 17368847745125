import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import "./casino.css";
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

import EventsPageBannerCards from "../../components/EventsPageBannerCards/EventsPageBannerCards";
import Subheader from "../../components/PageSubheader/PageSubheader";
import EventJoinedSection from "../../components/EventJoinedSection/EventJoinedSection";
import CasinoSection from "../../components/CasinoSection/CasinoSection";
import CategorySection from "../../components/CategorySection/CategorySection";
import { useTranslation } from "react-i18next";
import { parseJsonToGames, shuffleArray, generateImageUrl } from "../../utils/gameUtils";
import { loadAllProviderJsonFiles } from '../../utils/jsonLoader';
import { RecentlyPlayedContext } from '../../context/RecentlyPlayedContext';
import { featureGroupsMapping, providers } from '../../utils/constants';
import Worker from '../../utils/searchWorkerWrapper';
import SearchBar from "../../components/SearchBar/SearchBar";

import casinoIconBlue from "../../assets/images/NavBarIcons/Blue/casino-icon-blue.svg";
import { getTopPlayedGames } from "../../utils/api"; // Adjust the path according to your project structure

// Import the Meta component
import Meta from '../../utils/Meta';

const Casino = ({ categories }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { searchBarValue: initialSearchBarValue } = location.state || {};
  const [searchBarValue, setSearchBarValue] = useState(initialSearchBarValue || '');
  let filteredCategories;
  const [allCasinos, setAllCasinos] = useState([]);
  const [limitedCasinos, setLimitedCasinos] = useState([]);
  const [filteredCasinos, setFilteredCasinos] = useState([]);
  const [topGames, setTopGames] = useState([]);

  const { recentlyPlayed } = useContext(RecentlyPlayedContext);

  if (searchBarValue) {
    filteredCategories = categories.filter((item) =>
      item.text.toLowerCase().includes(searchBarValue.toLowerCase())
    );
  }

  const [casinoFeaturedGames, setCasinoFeaturedGames] = useState([]);

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileNameToday = `casinoFeaturedGames_${today}`;

    const cleanupOldEntries = () => {
      // Cleanup entries in localStorage that don't match today's date
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("casinoFeaturedGames_") && key !== fileNameToday) {
          localStorage.removeItem(key);
        }
      });
    };

    cleanupOldEntries(); // Run the cleanup function

    if (allCasinos.length === 0) return; // If the fullGamesList is empty, return early

    const storedGames = localStorage.getItem(fileNameToday); // Try to retrieve the stored games from localStorage using the date-based key

    if (storedGames) {
      setCasinoFeaturedGames(JSON.parse(storedGames)); // If stored games exist, parse and set them as the casino featured games
    } else {
      const shuffledGames = [...allCasinos].sort(() => 0.5 - Math.random()); // If no stored games exist, shuffle the full games list
      const selectedGames = shuffledGames.slice(0, 6); // Select the first 6 games from the shuffled list
      localStorage.setItem(fileNameToday, JSON.stringify(selectedGames)); // Store the selected games in localStorage with the date-based key
      setCasinoFeaturedGames(selectedGames); // Set the selected games as the casino featured games
    }
  }, [allCasinos]);

  const casinosFeaturedGamesWithImages = casinoFeaturedGames.map((game) => ({
    ...game,
    image: game.image.replace('/s2/', '/s3/'),
  }));

  useEffect(() => {
    const rootPage = "casino";

    // Load all JSON files and parse games for each provider
    const jsonContents = loadAllProviderJsonFiles();
    const allParsedGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider)
    );
    setAllCasinos(allParsedGames);

    // Display only the limited number of games initially
    const limitedParsedGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider, { limit: 20 })
    );
    setLimitedCasinos(shuffleArray(limitedParsedGames));

    setFilteredCasinos(shuffleArray(limitedParsedGames));

  }, []);

  // Fetch top games and generate image URLs
  useEffect(() => {
    async function fetchTopGames() {
      try {
        const topGamesData = await getTopPlayedGames();
        const topGames = topGamesData.data.map(gameData => {
          const [provider, gameName] = gameData.gameIdentifier.split(':');
          return {
            image: generateImageUrl(provider, gameName),
            name: gameData.gameIdentifier,
            gameTitle: gameData.gameName,
            provider: provider
          };
        });
        setTopGames(topGames);
      } catch (error) {
        console.error('Error fetching top games: ', error);
      }
    }
    fetchTopGames();
  }, []);

  // Filter the fullGamesList to get the recently played games
  const recentlyPlayedGames = recentlyPlayed
    .map((recent) =>
      allCasinos.find((game) => game.gameName2 === recent.gameName2)
    )
    .filter(Boolean); // Filter out undefined values

  const handleSearch = useCallback((searchTerm) => {
    if (!searchTerm) {
      setFilteredCasinos(limitedCasinos);
      return;
    }

    const worker = new Worker();

    worker.postMessage({ items: allCasinos, searchTerm, keys: ['gameProvider', 'gameTitle'] });

    worker.onmessage = (e) => {
      setFilteredCasinos(e.data);
    };

    worker.onerror = (error) => {
      console.error('Search worker error:', error);
    };

    return () => {
      worker.terminate();
    };
  }, [allCasinos, limitedCasinos]);

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  useEffect(() => {
    debouncedHandleSearch(searchBarValue);
  }, [searchBarValue, debouncedHandleSearch]);

  return (
    <div>
      <Meta
        title="Enjoy Licensed Crypto Casino with Live Games, Slots and more - GO WIN"
        description="GO WIN offers secure, regulated crypto casino experience with wide range of Live Dealer Games, Slots, and more. Sign up to Play your favourite casino games now."
      />

      <EventsPageBannerCards topGames={topGames} />
      <Subheader
        headerText={t("casinoGamesCapital")}
      />
      <div className="home-search-container">
        <SearchBar
          searchBarValue={searchBarValue}
          setSearchBarValue={setSearchBarValue}
        />
      </div>
      <CategorySection
        sectionName={t("casinoCategories")}
        categories={filteredCategories ? filteredCategories : categories}
      />
      <CasinoSection
        sectionName={t("casinoGames")}
        src={casinoIconBlue}
        alt="Casino Icon"
        casinos={
          filteredCasinos ? filteredCasinos : allCasinos
        }
      />
      <EventJoinedSection
        sectionName={t("featuredGames")}
        src={casinoIconBlue}
        alt="Casino Icon"
        events={casinosFeaturedGamesWithImages}
        page="casino"
      />
      <CasinoSection
        sectionName={t("recentlyPlayed")}
        src={casinoIconBlue}
        alt="Casino Icon"
        casinos={recentlyPlayedGames} // Pass recently played games here
      />
    </div>
  );
};

export default Casino;
