import React from "react";
import "./categoryButton.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CategoryButton = ({
  className,
  text,
  buttonImage,
  slidesToSlide,
  singleButtonWidth,
}) => {
  const { t } = useTranslation();

  const styles = {
    "--button-bg-image": `url(${buttonImage})`,

  };

  return (
    <Link
      to={`/categories/${text}`}
      className={`category-button ${className}`}
      style={styles}
    >
      <span>{text === "Slot Battles" ? t("slotBattles") : text === "Horse Racing" ? t("horseRacing") : t(text)}</span>

    </Link>
  );
};

export default CategoryButton;
