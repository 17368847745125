import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DepositHandler = ({ setShowDepositPopup }) => {
    const navigate = useNavigate();

    useEffect(() => {
        setShowDepositPopup(true);
        navigate('/');
    }, [setShowDepositPopup, navigate]);

    return null;
};

export default DepositHandler;
