export const isTokenExpired = (token) => {
  const [, payload] = token.split('.');
  const decodedPayload = JSON.parse(atob(payload));
  const currentTime = Math.floor(Date.now() / 1000);

  //return decodedPayload.exp - 15 * 60 + 10 < currentTime; // forcing token to expire in 30 seconds to test.

  return decodedPayload.exp < currentTime;
};

// Get the access token from local storage
export const getAccessToken = () => localStorage.getItem("accessToken");

// Refresh the access token using the refresh token
export const refreshAccessToken = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const response = await fetch(`${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/generateNewToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include', // Include cookies in the request
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("accessToken", data.data.accessToken);
      return data.data.accessToken;
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
};


// Check token expiry and refresh if needed
export const getTokenCheckExpiryAndRefreshIfNeeded = async () => {
  let token = getAccessToken();
  if (!token) return false;

  if (isTokenExpired(token)) {
    token = await refreshAccessToken();
  }

  return token;
};
