import React from "react";
import "./SpinnerLoading.css";
const SpinnerLoading = () => {
  return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
  );
};

export default SpinnerLoading;
