import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import CustomButton from '../../components/Button/button';
import Eye from '../../assets/images/eye-white.png';
import Hidden from '../../assets/images/hidden-white.png';
import './UserSettings.css';
import { useTranslation } from 'react-i18next';
import TemporaryLanguageList from '../../components/LocalizationSelectorPopup/TemporaryLanguageList';
import { useLanguage } from '../../context/LanguageContext';
import { createOtp, changePassword } from '../../utils/api';
import OneTimePasswordNoSecurityWords from '../../components/OneTimePasswordNoSecurityWordsPopup/OneTimePasswordNoSecurityWordsPopup';
import { scrollToTop, scrollToBottom } from "../../utils/scrollToTop";

const UserSettings = () => {
    const { t } = useTranslation();
    const { language, changeLanguage } = useLanguage();

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [otpVisible, setOtpVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [emailAddress, setEmailAddress] = useState('');

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const storedEmailAddress = localStorage.getItem('emailAddress');
        setUserId(storedUserId);
        setEmailAddress(storedEmailAddress);
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validatePassword = () => {
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;
        if (!newPassword || !confirmNewPassword) {
            setAlertMessage(t('fillInAllFields'));
            setAlertType('danger');
            return false;
        }

        if (newPassword !== confirmNewPassword) {
            setAlertMessage(t('passwordsDoNotMatch'));
            setAlertType('danger');
            return false;
        }

        if (!passwordRegex.test(newPassword)) {
            setAlertMessage(t('invalidPassword'));
            setAlertType('danger');
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        if (!currentPassword) {
            setAlertMessage(t('fillInAllFields'));
            setAlertType('danger');
            return;
        }

        if (!validatePassword()) {
            return;
        }

        setAlertMessage('');
        setLoading(true);

        try {
            await createOtp(emailAddress); // Pass emailAddress to the createOtp API
            setOtpVisible(true); // Show the OTP component
            scrollToTop();
        } catch (error) {
            console.error("Error creating OTP: ", error);
            setAlertMessage(t('failedToSendCode'));
            setAlertType('danger');
        } finally {
            setLoading(false);
        }
    };

    const handleOtpClose = async (otp) => {
        setOtpVisible(false);
        if (otp) {
            setLoading(true);
            try {
                const response = await changePassword(userId, currentPassword, newPassword, confirmNewPassword);
                if (response.success) {
                    setAlertMessage(t('passwordChangedSuccessfully'));
                    setAlertType('success');
                    scrollToBottom();
                } else {
                    setAlertMessage(t('failedToChangePassword'));
                    setAlertType('danger');
                }
            } catch (error) {
                console.error("Error changing password: ", error);
                setAlertMessage(t('failedToChangePassword'));
                setAlertType('danger');
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const handleLanguageChange = useCallback((selectedOption) => {
        changeLanguage(selectedOption.value);
    }, [changeLanguage]);

    // Custom styles for react-select component
    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: '#172644',
            borderColor: 'var(--main-font-color)',
            color: 'white',
            textAlign: 'left',
            fontFamily: 'var(--main-font-family), sans-serif',
            fontWeight: 600,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#172644',
            borderColor: 'var(--main-font-color)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'var(--main-font-color)' : '#172644',
            color: 'white',
            textAlign: 'left',
            fontFamily: 'var(--main-font-family), sans-serif',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: 'var(--main-font-color)',
                color: 'white',
            }
        }),
    };

    const languageOptions = TemporaryLanguageList.map(lang => ({
        value: lang.code,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={lang.icon} alt={lang.name} style={{ width: 20, marginRight: 10 }} />
                {lang.name}
            </div>
        ),
    }));

    return (
        <div className="user-settings-container">
            <h2 className="user-settings-header">{t('userSettings')}</h2>
            <h3 className="user-settings-sub-header">{t('setLanguage')}</h3>
            <div className="user-settings-form-group">
                <label>{t('language')}</label>
                <Select
                    options={languageOptions}
                    onChange={handleLanguageChange}
                    styles={customSelectStyles}
                    className="user-settings-language-selector"
                    value={languageOptions.find(option => option.value === language)}
                />
            </div>

            <h2 className="user-settings-header">{t('security')}</h2>
            <h3 className="user-settings-sub-header">{t('changePassword')}</h3>
            <div className="user-settings-form-group">
                <label>{t('currentPassword')}</label>
                <div className="user-settings-password-input-container">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="user-settings-password-input"
                    />
                    <img
                        src={passwordVisible ? Eye : Hidden}
                        alt="Toggle Password Visibility"
                        className="user-settings-password-toggle-icon"
                        onClick={togglePasswordVisibility}
                    />
                </div>
            </div>
            <div className="user-settings-form-group">
                <label>{t('newPassword')}</label>
                <div className="user-settings-password-input-container">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="user-settings-password-input"
                    />
                    <img
                        src={passwordVisible ? Eye : Hidden}
                        alt="Toggle Password Visibility"
                        className="user-settings-password-toggle-icon"
                        onClick={togglePasswordVisibility}
                    />
                </div>
            </div>
            <div className="user-settings-form-group">
                <label>{t('confirmNewPassword')}</label>
                <div className="user-settings-password-input-container">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className="user-settings-password-input"
                    />
                    <img
                        src={passwordVisible ? Eye : Hidden}
                        alt="Toggle Password Visibility"
                        className="user-settings-password-toggle-icon"
                        onClick={togglePasswordVisibility}
                    />
                </div>
            </div>
            <div className="user-settings-form-group">
                <CustomButton
                    buttonType="loggedOutYellowButton"
                    text={loading ? <div className="otp-loading-spinner"></div> : t('saveChanges')}
                    onClick={handleSave}
                />
            </div>
            <div className="user-settings-form-group">
                {alertMessage && (
                    <div className={`alert ${alertType === 'success' ? 'alert-success' : 'alert-danger'}`}>
                        {alertMessage}
                    </div>
                )}
            </div>
            {otpVisible && <OneTimePasswordNoSecurityWords onClose={handleOtpClose} email={emailAddress} />}
        </div>
    );
};

export default UserSettings;
