import React from "react";
import { useTranslation } from "react-i18next";
import SubmitRequestButton from "../SubmitRequestButton/SubmitRequestButton";
import OneTimePasswordNoSecurityWords from "../OneTimePasswordNoSecurityWordsPopup/OneTimePasswordNoSecurityWordsPopup";
import { useFetchBalances, useHandleWithdrawal } from "./popupUtils";

const WithdrawFormMobile = () => {
    const { t } = useTranslation();
    const { btcWithdrawalAddress, cashBalance, setCashBalance } = useFetchBalances();
    const {
        emailAddress,
        requestedAmount,
        receivableAmount,
        withdrawalError,
        withdrawalSuccess,
        withdrawalLoading,
        otpVisible,
        formSubmitted,
        handleRequestedAmountChange,
        handleWithdrawalSubmit,
        handleOtpClose,
    } = useHandleWithdrawal(setCashBalance);

    return (
        <div className="mobile-page-1">
            <div className="mobile-popup-form">
                <div className="mobile-token-balance-container-div">
                    <span className="cashWalletBalance">{t('cashWalletBalance')}:</span>
                    <span>{cashBalance.toLocaleString()}</span>
                </div>
                <form className="mobile-withdrawal-page-form" onSubmit={handleWithdrawalSubmit}>
                    <div className="mobile-withdrawal-page-form-item">
                        <label htmlFor="btc-withdrawal-address" className="withdrawLabelFont">
                            BTC {t("withdrawalAddress")}
                            {(!btcWithdrawalAddress || btcWithdrawalAddress.trim() === "") && (
                                <div className="btcSubtext">
                                    <a href="/settings/account-info">{t('updateBtcAddress')} </a>
                                </div>
                            )}
                        </label>
                        <input
                            readOnly
                            type="text"
                            name="btc-withdrawal-address"
                            value={btcWithdrawalAddress || ""}
                        />
                        <br />
                    </div>
                    <div className="mobile-withdrawal-page-form-item">
                        <label htmlFor="requested-amount" className="withdrawLabelFont">
                            {t("requestedAmount")}
                            <span className="withdrawalFeeFont">10% {t("withdrawalFee")}</span>
                        </label>
                        <input
                            type="number"
                            name="requested-amount"
                            value={requestedAmount}
                            onChange={handleRequestedAmountChange}
                            step="1"
                            min="0"
                        />
                        <br />
                    </div>
                    <div className="mobile-withdrawal-page-form-item">
                        <label htmlFor="receivable-amount">{t("receivableAmount")}</label>
                        <input
                            type="number"
                            name="receivable-amount"
                            value={receivableAmount}
                            readOnly
                        />
                        <br />
                    </div>
                    <div className="message-container">
                        {requestedAmount < 100 && !formSubmitted && (
                            <div className="withdrawalMinimumAmountText">
                                {t('withdrawalMinimumAmount')}
                            </div>
                        )}
                        {requestedAmount < 100 && formSubmitted && (
                            <div className="alert alert-danger">
                                {t('withdrawalMinimumAmount')}
                            </div>
                        )}
                        <br />
                        {withdrawalError && <div className="alert alert-danger">{withdrawalError}</div>}
                        {withdrawalSuccess && <div className="alert alert-success">{t("successRequest")}</div>}
                    </div>
                    <SubmitRequestButton text={t('submitRequest')} onClick={handleWithdrawalSubmit} loading={withdrawalLoading} disabled={withdrawalLoading} />
                </form>
                <div className="withdrawalDisclaimer">{t('withdrawalDisclaimer')}</div>
            </div>
            {otpVisible && <OneTimePasswordNoSecurityWords onClose={(otp) => handleOtpClose(otp, btcWithdrawalAddress)} email={emailAddress} />}
        </div>
    );
};

export default WithdrawFormMobile;
