import React from "react";
import "./CancelWithdrawalPopup.css";
import { useTranslation } from "react-i18next";

const CancelWithdrawalPopup = ({ onConfirm, onClose, row }) => {
  const { t } = useTranslation();
  return (
    <div className="cancelwithdrawal-popup-mobile">
      <div className="cancelwithdrawal-mobile-page-1">
        <div className="cancelwithdrawal-popup-header">{t("confirmWithdrawalCancel")}</div>
        <div className="cancelwithdrawal-popup-form">
          <p className="cancelwithdrawalSubtext">{t("serial")}# - {row.serialNumber} of {t('amount')} ${row.amount.toLocaleString()}</p>
          <p className="cancelwithdrawalSubtext">{t("cancelWithdrawalSubtext")}</p>
          <div className="cancelwithdrawal-button-container">
            <button className="cancelwithdrawal-confirm-button" onClick={onConfirm}>
              {t("yes")}
            </button>
            <button className="cancelwithdrawal-cancel-button" onClick={onClose}>
              {t("no")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelWithdrawalPopup;
