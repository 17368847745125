import { BrowserProvider, formatEther } from 'ethers';
import { convertCryptoToUsd } from "./api";

export const getEthBalanceFromWalletConnect = async (cryptoCurrencyOptionEnumId, address, walletProvider) => {
    const ethersProvider = new BrowserProvider(walletProvider)
    const balanceInWei  = await ethersProvider.getBalance(address);
    const balanceInEth  = formatEther(balanceInWei);
    const roundedBalanceInEth = Number(balanceInEth).toFixed(10);
    const { usdAmount } = await convertCryptoToUsd(roundedBalanceInEth, cryptoCurrencyOptionEnumId);

    return {
        balanceInEth: roundedBalanceInEth,
        usdAmount: usdAmount
    };
  };