// Redirect.js
import { useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const navigate = useNavigate();

  const handleRedirect = (redirectTo) => {
    navigate(redirectTo);
  };

  return handleRedirect;
};

export default useRedirect;
