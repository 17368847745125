import React from "react";
import "./CancelWithdrawalConfirmationPopup.css";
import { useTranslation } from "react-i18next";

const CancelWithdrawalConfirmationPopup = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="cancel-confirm-popup">
      <div className="cancel-confirm-popup-page-1">
        <div className="cancel-confirm-popup-header">{t("cancelWithdrawalConfirmation")}</div>
        <div className="cancel-confirm-popup-form">
          <p className="cancel-confirm-popup-subtext">{t("cancelWithdrawalConfirmationText")}</p>
        </div>
        <div className="cancel-confirm-popup-button-container">
          <button className="cancel-confirm-popup-confirm-button" onClick={onClose}>
            {t("Ok")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelWithdrawalConfirmationPopup;
