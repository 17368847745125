import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import Carousel from "react-multi-carousel";
import { FixedSizeList as List } from "react-window";
import "react-multi-carousel/lib/styles.css";
import CasinoButton from "../CasinoButton/casinoButton";
import ViewAllButton from "../ViewAllButton/ViewAllButton";
import { useTranslation } from "react-i18next";
import "./CasinoSection.css";
import { useViewport } from "../../hooks/ViewportProvider";
import { useNavigate } from "react-router-dom";

const CasinoSection = React.memo(({ sectionName, src, casinos, alt, searchBarValue, setSearchBarValue, typeOption }) => {
  const [showViewAllButton, setShowViewAllButton] = useState(false);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { width: viewportWidth } = useViewport();
  const navigate = useNavigate();
  const [containerWidth, setContainerWidth] = useState(0);
  const [singleButtonWidth, setSingleButtonWidth] = useState(230);

  const responsive = useMemo(() => ({
    extraLarge: {
      breakpoint: { max: 4000, min: 1600 },
      items: 7,
      partialVisibilityGutter: 80,
    },
    large: {
      breakpoint: { max: 1800, min: 900 },
      items: 6,
      partialVisibilityGutter: 40,
    },
    mediumLarge: {
      breakpoint: { max: 1600, min: 600 },
      items: 5,
      partialVisibilityGutter: 40,
    },
    medium: {
      breakpoint: { max: 1400, min: 600 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    small: {
      breakpoint: { max: 1200, min: 600 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    extraSmall: {
      breakpoint: { max: 1090, min: 0 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    extraExtraSmall: {
      breakpoint: { max: 750, min: 0 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tiny: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
      partialVisibilityGutter: 40,
    },
  }), []);

  const slidesToSlide = useMemo(() => {
    if (viewportWidth <= responsive.tiny.breakpoint.max) return responsive.tiny.items;
    if (viewportWidth <= responsive.extraExtraSmall.breakpoint.max) return responsive.extraExtraSmall.items;
    if (viewportWidth <= responsive.extraSmall.breakpoint.max) return responsive.extraSmall.items;
    if (viewportWidth <= responsive.small.breakpoint.max) return responsive.small.items;
    if (viewportWidth <= responsive.medium.breakpoint.max) return responsive.medium.items;
    if (viewportWidth <= responsive.mediumLarge.breakpoint.max) return responsive.mediumLarge.items;
    if (viewportWidth <= responsive.large.breakpoint.max) return responsive.large.items;
    if (viewportWidth <= responsive.extraLarge.breakpoint.max) return responsive.extraLarge.items;
    return 1;
  }, [viewportWidth, responsive]);

  useEffect(() => {
    if (containerWidth > 0) {
      setSingleButtonWidth(containerWidth - 20);
    }
  }, [containerWidth]);

  const handleResize = useCallback(() => {
    const currentContainerWidth = containerRef.current.offsetWidth;
    setContainerWidth(currentContainerWidth);
    setSingleButtonWidth(currentContainerWidth - 20);
    const totalButtonWidth = casinos.length * 230; // Assuming each button + gap is 230px
    setShowViewAllButton(totalButtonWidth > currentContainerWidth);
  }, [casinos.length]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleViewAllClick = useCallback(() => {
    const state = { searchBarValue: '' };
    if (typeOption) {
      state.typeOption = typeOption;
    }
    navigate('/viewall', { state });
  }, [navigate, typeOption]);

  const VirtualizedCarousel = ({ children, itemCount, itemSize, width }) => {
    const rowRenderer = ({ index, style }) => (
      <div style={{ ...style, width: itemSize }}>
        {children[index]}
      </div>
    );

    return (
      <List
        height={itemSize}
        itemCount={itemCount}
        itemSize={itemSize}
        width={width}
        layout="horizontal"
      >
        {rowRenderer}
      </List>
    );
  };

  return (
    <>
      <div className="category-header">
        <img className="homeCategoryIcon" src={src} alt={alt} />
        {sectionName}
        {showViewAllButton && (
          <ViewAllButton onClick={handleViewAllClick} text={t("viewAll")} />
        )}
      </div>
      <div ref={containerRef} className="casino-section-wrapper">
        <Carousel
          responsive={responsive}
          arrows
          autoPlay={false}
          autoPlaySpeed={500}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable={false}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay={false}
          showDots={false}
          sliderClass=""
          slidesToSlide={slidesToSlide}
          swipeable
          transitionDuration={1000}
          partialVisible={false}
          customList={({ children }) => (
            <VirtualizedCarousel
              itemCount={casinos.length}
              itemSize={250}
              width={containerWidth}
            >
              {children}
            </VirtualizedCarousel>
          )}
        >
          {casinos.map((casino, index) => (
            <CasinoButton
              key={index}
              buttonImage={casino.image}
              gameProvider={casino.gameProvider}
              gameName={casino.gameName}
              gameName2={casino.gameName2}
              rootPage={casino.rootPage}
              slidesToSlide={slidesToSlide}
              singleButtonWidth={singleButtonWidth}
              gameTitle={casino.gameTitle}
              hasLive={casino.hasLive}
            />
          ))}
        </Carousel>
      </div>
    </>
  );
});

export default CasinoSection;
