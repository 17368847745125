import React from "react";
import "./footer.css";
import logo from "../../assets/images/go-win-logo.png";
import { useViewport } from "../../hooks/ViewportProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { width } = useViewport();
  const breakpoint = 900;
  const { t } = useTranslation();

  const MobileFooter = () => (
    <div className="mobile-footer">
      <div className="footer-content">
        {/* <div className="footer-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div> */}
        <div className="footer-subtext">
          <h4>© {t("copyRight")}</h4>
        </div>
        <div className="footer-links">
          <h4>
            <Link to="/terms">&#x2022; {t("termsAndConditions")}</Link>
          </h4>
          <h4>
            <Link to="/bonus-token-reward-program">&#x2022; {t("bonusTokenRewardProgram")}</Link>
          </h4>

        </div>
        <div className="mobile-footer-disclaimer">
          {t("disclaimerFooter")}
        </div>
      </div>
    </div>
  );

  const DesktopFooter = () => (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <Link to="/">
            <img className="footerLogo" src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="footer-links">
          <h4 className="footer-subtext">© {t("copyRight")}</h4>
          <h4 className="termsAndConditions">
            <Link to="/terms">&#x2022; {t("termsAndConditions")}</Link>
          </h4>
          <h4>
            <Link to="/bonus-token-reward-program">&#x2022; {t("bonusTokenRewardProgram")}</Link>
          </h4>

        </div>
        <div className="footer-disclaimer">
          {t("disclaimerFooter")}
        </div>
      </div>
    </div>
  );

  return width < breakpoint ? <MobileFooter /> : <DesktopFooter />;
};

export default Footer;
