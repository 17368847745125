import React, { useState, useEffect } from 'react';
import Close from "../../assets/images/close.svg";
import './loginPopup.css';
import ProceedButton from '../ProceedButton/ProceedButton';
import Eye from "../../assets/images/eye.png";
import Hidden from "../../assets/images/hidden.png";
import { useTranslation } from 'react-i18next';
import RegistrationOneTimePassword from '../RegistrationOneTimePassword/RegistrationOneTimePassword';
import { createOtp, validateOtp, getUserById, updateUser } from '../../utils/api';

const LoginPopup = ({ onClose, onLogin, onSuccessfulLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [otpError, setOtpError] = useState('');
  const [userId, setUserId] = useState(null); // Store userId for OTP validation
  const { t } = useTranslation();

  useEffect(() => {
    const savedUsername = localStorage.getItem('rememberedUsername');
    if (savedUsername) {
      setUsername(savedUsername);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setMessageType('');
    setLoading(true);

    const response = await onLogin(username, password, rememberMe);
    console.log(response);

    if (response.success) {
      setMessage(t('loginSuccessful'));
      setMessageType('success');
      setTimeout(() => {
        onSuccessfulLogin(); // Notify parent component of successful login
      }, 2000);
    } else if (response.message === "Not Validated") {
      setUserId(response.data.userId);
      setEmail(response.data.email);
      await createOtp(response.data.email); // Create OTP for email using response data
      setStep(2); // Move to OTP validation step
    } else {
      setMessage(response.message);
      setMessageType('danger');
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOtpValidate = async (enteredOtp) => {
    setLoading(true);
    try {
      const response = await validateOtp(email, enteredOtp);
      if (response.success && response.data.status !== "ValidationFailed") {
        const userDetails = await getUserById(userId);
        const updateData = {
          ...userDetails.data,
          emailConfirmed: true,
          password: null,
          confirmPassword: null,
        };
        const updateResponse = await updateUser(updateData);
        if (updateResponse.success) {
          // Automatically log in the user after successful OTP validation and update
          const loginResponse = await onLogin(username, password, rememberMe);
          if (loginResponse.success) {
            setMessage(t('loginSuccessful'));
            setMessageType('success');
            setTimeout(() => {
              onSuccessfulLogin(); // Notify parent component of successful login
            }, 2000);
          } else {
            setMessage(loginResponse.message);
            setMessageType('danger');
          }
        } else {
          setOtpError(t('updateFailed'));
        }
      } else {
        setOtpError(t('invalidCode'));
      }
    } catch (error) {
      setOtpError(t('invalidCode'));
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      await createOtp(email); // Resend OTP
      setOtpError('');
    } catch (error) {
      setOtpError(t('failedToSendCode'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-popup-mobile">
      <div className="login-mobile-page-1">
        <div className="login-popup-header">
          <button className="login-close" onClick={onClose}>
            <img src={Close} alt="close" />
          </button>
          <h1>{t('logIn')}</h1>
        </div>
        <div className="login-popup-form">
          {step === 1 && (
            <form onSubmit={handleSubmit} className="login-form">
              <label htmlFor="username" className="login-label">{t('username')}</label>
              <input
                type="text"
                id="username"
                placeholder=""
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="login-input"
              />
              <label htmlFor="password" className="login-label">{t('password')}</label>
              <div className="password-input-container">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="login-input"
                />
                <img
                  src={passwordVisible ? Eye : Hidden}
                  alt="Toggle Password Visibility"
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
              <div className="remember-me">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="remember-me-checkbox"
                />
                <label htmlFor="rememberMe" className="remember-me-label">{t('rememberUsername')}</label>
              </div>
              <ProceedButton text={t('logIn')} onClick={handleSubmit} loading={loading} />
              {message && (
                <div className={`login-message alert alert-${messageType}`}>
                  {message}
                </div>
              )}
            </form>
          )}
          {step === 2 && (
            <>
              <div className="alert alert-success mt-3">
                {t('pleaseVerifyAccount')}
              </div>

              <RegistrationOneTimePassword
                onValidate={handleOtpValidate}
                onResend={handleResendOtp}
                errorMessage={otpError}
                loading={loading}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
