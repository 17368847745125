import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import CasinoButton from "../../components/CasinoButton/casinoButton";
import BackArrow from '../../assets/images/BackArrow.svg';
import SearchBar from "../../components/SearchBar/SearchBar";
import { parseJsonToGames, filterGamesByReleaseDate } from "../../utils/gameUtils";
import { loadAllProviderJsonFiles, loadUpcomingJsonFile } from '../../utils/jsonLoader';
import { useTranslation } from "react-i18next";
import { featureGroupsMapping, providers } from '../../utils/constants';
import { scrollToTop } from '../../utils/scrollToTop';

import './ViewAll.css';

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

const ViewAll = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    searchBarValue: initialSearchBarValue,
    sortOption: initialSortOption,
    typeOption: initialTypeOption,
    selectedCategories: initialSelectedCategories,
    selectedProviders: initialSelectedProviders,
  } = location.state || {};
  const { t } = useTranslation();

  const [games, setGames] = useState([]);
  const [newReleases, setNewReleases] = useState([]);
  const [liveGames, setLiveGames] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(initialSelectedCategories || []);
  const [selectedProviders, setSelectedProviders] = useState(initialSelectedProviders || []);
  const [searchBarValue, setSearchBarValue] = useState(initialSearchBarValue || '');
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage, setGamesPerPage] = useState(36);
  const [sortOption, setSortOption] = useState(initialSortOption || { value: 'Sort By', label: t('sortBy') });
  const [typeOption, setTypeOption] = useState(initialTypeOption || { value: 'Filter By', label: t('filterBy') });
  const [isMobileView, setIsMobileView] = useState(window.matchMedia("(max-width: 768px)").matches);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const removeUnderscores = useCallback((str) => {
    return str.split('_').map(capitalizeFirstLetter).join(' ');
  }, []);

  // Load initial data
  useEffect(() => {
    const rootPage = "casino";
    const jsonContents = loadAllProviderJsonFiles();
    const upcomingJsonContent = loadUpcomingJsonFile();

    const parsedGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider)
    );
    setGames(parsedGames);

    const allGames = Object.values(upcomingJsonContent).flat();
    const newReleaseGames = filterGamesByReleaseDate(allGames, providers, featureGroupsMapping);

    // Parse the new release games
    const parsedNewReleaseGames = providers.flatMap(provider =>
      parseJsonToGames(newReleaseGames, rootPage, featureGroupsMapping, provider)
    );
    setNewReleases(parsedNewReleaseGames);

    // Parse the live games games
    const parsedLiveGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider, { hasLive: true })
    );
    setLiveGames(parsedLiveGames);
  }, []);

  // Extract and sort unique categories and providers
  const categories = useMemo(() => {
    const uniqueCategories = [...new Set(games.map(game => game.category).filter(Boolean))].sort();
    return uniqueCategories.map(category => ({
      value: category,
      label: removeUnderscores(category),
    }));
  }, [games, removeUnderscores]);

  const producers = useMemo(() => {
    const uniqueProducers = [...new Set(games.map(game => game.producer).filter(Boolean))].sort();
    return uniqueProducers.map(producer => ({ value: producer, label: capitalizeFirstLetter(producer) }));
  }, [games]);

  useEffect(() => {
    setCurrentPage(1);
    resetGamesPerPage();
  }, [searchBarValue, selectedCategories, selectedProviders, sortOption, typeOption]);

  const resetGamesPerPage = () => {
    if (window.matchMedia("(max-width: 592px)").matches) {
      setGamesPerPage(10);
    } else {
      setGamesPerPage(36);
    }
  };

  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      if (window.matchMedia("(max-width: 592px)").matches) {
        setGamesPerPage(10); // Number of games per page for mobile view
      } else {
        setGamesPerPage(36); // Number of games per page for desktop view
      }
      setIsMobileView(window.matchMedia("(max-width: 768px)").matches);
    }, 100);

    window.addEventListener('resize', debouncedResizeHandler);
    debouncedResizeHandler(); // Initial check

    return () => {
      window.removeEventListener('resize', debouncedResizeHandler);
    };
  }, []);

  const handleFilterChange = (selectedOptions, filterType) => {
    if (filterType === 'Category') {
      setSelectedCategories(selectedOptions);
    } else if (filterType === 'Provider') {
      setSelectedProviders(selectedOptions);
    }
    resetGamesPerPage();
  };

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption);
    resetGamesPerPage();
  };

  const handleTypeChange = (selectedOption) => {
    setTypeOption(selectedOption);
    resetGamesPerPage();
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontFamily: 'var(--main-font-family)',
      fontSize: '16px',
      fontWeight: 400,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '2px solid var(--main-font-color)',
      color: 'white',
      ':hover': {
        border: '2px solid var(--main-font-color)',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      textAlign: 'start'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',
      textAlign: 'start'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#043b5d',
      border: '2px solid var(--main-font-color)',
      color: 'white',
      textAlign: 'start'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--main-font-color)' : 'transparent',
      color: state.isFocused ? 'black' : 'white',
      ':active': {
        backgroundColor: 'var(--main-font-color)',
        color: 'black',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '1px solid var(--main-font-color)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'rgb(248 190 0)',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'rgb(248 190 0)',
      },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'rgb(248 190 0)',
      },
    }),
  };

  const sortOptions = [
    { value: 'Default', label: `${t('default')}` },
    { value: 'Release Date', label: `${t('releaseDate')}` },
    { value: 'A-Z', label: `${t('aToZ')}` },
    { value: 'Z-A', label: `${t('zToA')}` }
  ];

  const typeOptions = [
    { value: 'Default', label: `${t('default')}` },
    { value: 'New Releases', label: `${t('newReleases')}` },
    { value: 'Live Games', label: `${t('liveGames')}` },
  ];

  const getSortedGames = (games) => {
    if (sortOption.value === 'Default') {
      sortOption.label = t('sortBy');
      return games;
    }

    if (sortOption.value === 'A-Z') {
      return [...games].sort((a, b) => a.gameTitle.localeCompare(b.gameTitle));
    }

    if (sortOption.value === 'Z-A') {
      return [...games].sort((a, b) => b.gameTitle.localeCompare(a.gameTitle));
    }

    if (sortOption.value === 'Release Date') {
      return [...games].sort((a, b) => {
        const dateA = new Date(a.released_at);
        const dateB = new Date(b.released_at);

        if (isNaN(dateA.getTime())) return 1; // a does not have a valid date, place it at the end
        if (isNaN(dateB.getTime())) return -1; // b does not have a valid date, place it at the end

        return dateB - dateA; // Compare dates normally
      });
    }

    return games;
  };

  const getTypeGames = (games) => {
    if (typeOption.value === 'Default') {
      typeOption.label = t('filterBy');
      return games;
    }

    if (typeOption.value === 'New Releases') {
      return newReleases;
    }

    if (typeOption.value === 'Live Games') {
      return liveGames;
    }

    return games;
  };

  const filteredGames = getSortedGames(getTypeGames(games))
    .filter(
      item =>
        (!selectedCategories.length || selectedCategories.some(cat => cat.value === item.category)) &&
        (!selectedProviders.length || selectedProviders.some(prov => prov.value === item.producer))
    )
    .filter(
      item =>
        item.producer.toLowerCase().includes(searchBarValue.toLowerCase()) ||
        item.gameTitle.toLowerCase().includes(searchBarValue.toLowerCase())
    );

  const totalPages = useMemo(() => Math.ceil(filteredGames.length / gamesPerPage), [filteredGames.length, gamesPerPage]);

  const currentGames = useMemo(() => {
    return filteredGames.slice(
      (currentPage - 1) * gamesPerPage,
      currentPage * gamesPerPage
    );
  }, [filteredGames, currentPage, gamesPerPage]);

  const handlePrevPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  }, [currentPage]);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  }, [currentPage, totalPages]);

  const handleLoadMore = useCallback(() => {
    setGamesPerPage(gamesPerPage + 10);
  }, [gamesPerPage]);

  return (
    <div className="view-all-page">
      <header className="view-all-header">
        <div className="header-top-row">
          <div className="header-left">
            <img
              src={BackArrow}
              alt="Back"
              className="back-arrow"
              onClick={() => navigate(-1, { state: { searchBarValue, selectedCategories, selectedProviders, sortOption, typeOption } })}
            />
          </div>
          <div className="header-center">
            <div className="main-header">
              {t('casinoGames')}
            </div>
          </div>
          <div className="header-right"></div>
        </div>
        <div className="filter-icon-container">
          <SearchBar
            className="viewAll-search-bar"
            searchBarValue={searchBarValue}
            setSearchBarValue={setSearchBarValue}
          />

          <div className="filters-container ">
            <div className="filter-select ">
              <Select
                options={categories}
                styles={customStyles}
                isMulti
                placeholder={t('category')}
                value={selectedCategories}
                onChange={(selectedOptions) => handleFilterChange(selectedOptions, 'Category')}
              />
            </div>
            <div className="filter-select">
              <Select
                options={producers}
                styles={customStyles}
                isMulti
                placeholder={t('provider')}
                value={selectedProviders}
                onChange={(selectedOptions) => handleFilterChange(selectedOptions, 'Provider')}
              />
            </div>
            <div className="filter-select">
              <Select
                options={typeOptions}
                placeholder={t('type')}
                styles={customStyles}
                value={typeOption}
                onChange={handleTypeChange}
              />
            </div>
            <div className="filter-select">
              <Select
                options={sortOptions}
                placeholder={t('sortBy')}
                styles={customStyles}
                value={sortOption}
                onChange={handleSortChange}
              />
            </div>
          </div>
        </div>
      </header>
      <div className="view-all-games-container">
        {currentGames.map((casino, index) => (
          <CasinoButton
            key={index}
            buttonImage={casino.image}
            gameProvider={casino.gameProvider}
            gameName={casino.gameName}
            gameName2={casino.gameName2}
            rootPage="viewAll"
            gameTitle={casino.gameTitle}
            hasLive={casino.hasLive}
            searchBarValue={searchBarValue}
            selectedCategories={selectedCategories}
            selectedProviders={selectedProviders}
            sortOption={sortOption}
            typeOption={typeOption}
          />
        ))}
      </div>
      {isMobileView ? (
        <>
          <div className="showing-text">
            {t('showing')} <span className="gold-text">{currentGames.length}</span> {t('of')} <span className="gold-text">{filteredGames.length}</span>
          </div>
          <div className="load-more-container">
            <button onClick={handleLoadMore} className="load-more-button">
              {t('loadMore')}
            </button>
            <button onClick={scrollToTop} className="go-to-top-button">
              {t('goToTop')}
            </button>
          </div>
        </>
      ) : (
        <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>{t('previous')}</button>
          <span>{currentPage} {t('of')} {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>{t('next')}</button>
        </div>
      )}
    </div>
  );
};

export default ViewAll;
