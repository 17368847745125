// src/hooks/useLogin.js
import { useCallback } from 'react';
import useTranslationHelper from './useTranslationHelper';
import { login as loginApi } from '../utils/api';

const useLogin = () => {
    const t = useTranslationHelper();

    const login = useCallback(async (username, password) => {
        return await loginApi(username, password, t);
    }, [t]);

    return { login };
};

export default useLogin;
