import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./EventsPageBannerCards.css";
import { useTranslation } from "react-i18next";

import EventsPageBannerCard from "./EventsPageBannerCard/EventsPageBannerCard";

import PokerImageOne from "../../assets/images/PokerImages/PokerImage1.jpg";
import PokerImageTwo from "../../assets/images/PokerImages/PokerImage2.jpg";
import PokerImageThree from "../../assets/images/PokerImages/PokerImage3.jpg";

//Category Images
import BasketballImage from "../../assets/images/SportsImages/Basketball.jpg";
import FootballImage from "../../assets/images/SportsImages/Football.jpg";
import RugbyImage from "../../assets/images/SportsImages/Rugby.jpg";
import BoxingImage from "../../assets/images/SportsImages/Boxing.jpg";
import HorseRacingImage from "../../assets/images/SportsImages/HorseRacing.jpg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 4,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1400, min: 900 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 900, min: 500 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  smallmobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const SubBannerCards = ({ TypeOfPage, className, topGames }) => {
  const { t } = useTranslation();
  if (TypeOfPage === "sports") {
    return (
      <div className="events-page-banner-cards-container-div">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="sports-container"
          dotListClass=""
          draggable={false}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={BasketballImage}
            text={t("enter")}
            category="sports"
          />
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={FootballImage}
            text={t("enter")}
            category="sports"
          />
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={RugbyImage}
            text={t("enter")}
            category="sports"
          />
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={BoxingImage}
            text={t("enter")}
            category="sports"
          />
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={HorseRacingImage}
            text={t("enter")}
            category="sports"
          />
        </Carousel>
      </div>
    );
  } else if (TypeOfPage === "poker") {
    return (
      <div className="events-page-banner-cards-container-div">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="poker-container"
          dotListClass=""
          draggable={false}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={PokerImageOne}
          />
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={PokerImageTwo}
          />
          <EventsPageBannerCard
            className="banner-card-container"
            buttonImage={PokerImageThree}
          />
        </Carousel>
      </div>
    );
  } else {
    return (
      <div className="events-page-banner-cards-container-div">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable={false}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {topGames.map((game, index) => (
            <EventsPageBannerCard
              key={index}
              buttonImage={game.image}
              gameName={game.name}
              provider={game.provider}
              gameTitle={game.gameTitle}
            />
          ))}
        </Carousel>
      </div>
    );
  }
};

export default SubBannerCards;
