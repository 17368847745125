import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router, useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import Home from "../pages/Home/home";
import Events from "../pages/Events/events";
import Casino from "../pages/Casino/casino";
import Sports from "../pages/Sports/sports";
import Poker from "../pages/Poker/poker";
import GamePage from "../pages/GamePage/GamePage";
import Terms from "../pages/Terms/terms";
import BonusTokenRewardProgram from "../pages/BonusTokenRewardProgram/BonusTokenRewardProgram";
import ViewAll from "../pages/ViewAll/ViewAll";
import Settings from '../pages/Settings/Settings';
import AccountInfo from '../pages/Settings/AccountInfo';
import Activity from '../pages/Settings/Activity';
import Referrals from '../pages/Settings/Referrals';
import UserSettings from '../pages/Settings/UserSettings';
import PrivateRoute from "../components/PrivateRoute/privateRoute";
import AutoLogin from "../pages/AutoLogin/AutoLogin";
import Category from "../pages/Category/Category";
import SponsorRegisterPopup from "../components/SponsorRegisterPopup/SponsorRegisterPopup";
import DepositCashPopup from "../components/DepositCashPopup/DepositCashPopup";
import BankPaymentPopup from "../components/DepositCashPopup/BankPaymentPopup";
import BankPaymentOffersPopup from "../components/DepositCashPopup/BankPaymentOffersPopup";
import PaymentSuccessPopup from "../components/DepositCashPopup/PaymentSuccessPopup";
import PaymentFailedPopup from "../components/DepositCashPopup/PaymentFailedPopup";
import DepositHandler from "../components/DepositCashPopup/depositHandler";
import { decodeSponsorUsername } from "../utils/codeSponsorUsername";
import useRegister from "../hooks/useRegister";

// Category Images
import SlotBattlesImage from "../assets/images/CasinoCategories/slot_battles_button_bg.jpg";
import CrashImage from "../assets/images/CasinoCategories/crash_button_bg.jpg";
import RouletteImage from "../assets/images/CasinoCategories/roulette_button_bg.jpg";
import CasualImage from "../assets/images/CasinoCategories/casual_button_bg.jpg";
import CrapsImage from "../assets/images/CasinoCategories/craps_button_bg.jpg";
import CardImage from "../assets/images/CasinoCategories/card_button_bg.jpg";
import ScratchImage from "../assets/images/CasinoCategories/scratch_button_bg.jpg";
import LotteryImage from "../assets/images/CasinoCategories/lottery_button_bg.jpg";
import MinesImage from "../assets/images/CasinoCategories/mines_button_bg.jpg";
import FishingImage from "../assets/images/CasinoCategories/fishing_button_bg.jpg";
import PokerImage from "../assets/images/CasinoCategories/poker_button_bg.jpg";
import VideoPokerImage from "../assets/images/CasinoCategories/videoPoker_button_bg.jpg";
import VirtualSportsImage from "../assets/images/CasinoCategories/virtualSports_button_bg.jpg";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const RefHandler = ({ setShowSponsorPopup, setSponsorUsername, navigate }) => {
  const { encodedText } = useParams();

  useEffect(() => {
    if (encodedText) {
      const decodedUsername = decodeSponsorUsername(encodedText);
      if (decodedUsername) {
        setSponsorUsername(decodedUsername);
        setShowSponsorPopup(true);
        navigate('/'); // Navigate to the homepage after extracting the sponsor username
      }
    }
  }, [encodedText, setShowSponsorPopup, setSponsorUsername, navigate]);

  return null;
};

function AppRouter() {
  const [showSponsorPopup, setShowSponsorPopup] = useState(false);
  const [showDepositPopup, setShowDepositPopup] = useState(false);
  const [showBankPaymentPopup, setShowBankPaymentPopup] = useState(false);
  const [showBankPaymentOffersPopup, setShowBankPaymentOffersPopup] = useState(false);
  const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState(false);
  const [showPaymentFailedPopup, setShowPaymentFailedPopup] = useState(false);
  const [amount, setAmount] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [sponsorUsername, setSponsorUsername] = useState(null);
  const navigate = useNavigate();
  const { handleRegister } = useRegister();

  const englishCategories = [
    { text: "Slot Battles", image: SlotBattlesImage },
    { text: "crash", image: CrashImage },
    { text: "roulette", image: RouletteImage },
    { text: "casual", image: CasualImage },
    { text: "craps", image: CrapsImage },
    { text: "card", image: CardImage },
    { text: "scratch", image: ScratchImage },
    { text: "lottery", image: LotteryImage },
    { text: "mines", image: MinesImage },
    { text: "fishing", image: FishingImage },
    { text: "pokerLower", image: PokerImage },
    { text: "videoPoker", image: VideoPokerImage },
    { text: "virtualSports", image: VirtualSportsImage },
  ];

  const handleSponsorPopupClose = () => {
    setShowSponsorPopup(false);
    setSponsorUsername(null);
  };

  const handleDepositPopupClose = () => {
    setShowDepositPopup(false);
  };

  const handleBankPaymentPopupClose = () => {
    setShowBankPaymentPopup(false);
  };

  const handleBankPayment = (amount, responseData) => {
    setAmount(amount);
    setResponseData(responseData);
    setShowBankPaymentPopup(true);
    setShowDepositPopup(false); // Close the deposit popup
  };

  const handleBankPaymentOffersPopupClose = () => {
    setShowBankPaymentOffersPopup(false);
  };

  const handleBankPaymentOffers = (amount, responseData) => {
    setAmount(amount);
    setResponseData(responseData);
    setShowBankPaymentOffersPopup(true);
    setShowDepositPopup(false); // Close the deposit popup
    setShowBankPaymentPopup(false); // Close the bank popup
  };

  const handleClosePaymentSuccessPopup = () => {
    setShowPaymentSuccessPopup(false);
    navigate('/');
  };

  const handleClosePaymentFailedPopup = () => {
    setShowPaymentFailedPopup(false);
    navigate('/');
  };

  const userId = localStorage.getItem("userId");
  const signedInUsername = localStorage.getItem("signedInUsername");

  return (
    <>
      <ScrollToTop />
      {showSponsorPopup && (
        <SponsorRegisterPopup
          onClose={handleSponsorPopupClose}
          sponsorUsername={sponsorUsername}
          onRegister={handleRegister} // Pass the handleRegister function
        />
      )}
      {showDepositPopup && (
        <DepositCashPopup
          onClose={handleDepositPopupClose}
          onBankPayment={handleBankPaymentOffers}
        />
      )}
      {showBankPaymentPopup && (
        <BankPaymentPopup
          onClose={handleBankPaymentPopupClose}
          amount={amount}
          responseData={responseData}
          userId={userId}
          signedInUsername={signedInUsername}
        />
      )}
      {showBankPaymentOffersPopup && (
        <BankPaymentOffersPopup
          onBankPayment={handleBankPayment}
          onClose={handleBankPaymentOffersPopupClose}
          amount={amount}
          responseData={responseData}
          userId={userId}
          signedInUsername={signedInUsername}
        />
      )}
      {showPaymentSuccessPopup && (
        <PaymentSuccessPopup onClose={handleClosePaymentSuccessPopup} />
      )}
      {showPaymentFailedPopup && (
        <PaymentFailedPopup onClose={handleClosePaymentFailedPopup} />
      )}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home categories={englishCategories} />} />
          <Route path="livegames" element={<Events />} />
          <Route path="casino" element={<Casino categories={englishCategories} />} />
          <Route path="sports" element={<Sports />} />
          <Route path="poker" element={<Poker />} />
          <Route path="ref/:encodedText" element={<RefHandler setShowSponsorPopup={setShowSponsorPopup} setSponsorUsername={setSponsorUsername} navigate={navigate} />} />

          <Route element={<PrivateRoute />}>
            <Route path="deposit" element={<DepositHandler setShowDepositPopup={setShowDepositPopup} />} />
            <Route path="games/:gameName2" element={<GamePage />} />
            <Route path="/settings" element={<Settings />}>
              <Route path="account-info" element={<AccountInfo />} />
              <Route path="activity" element={<Activity />} />
              <Route path="referrals" element={<Referrals />} />
              <Route path="user-settings" element={<UserSettings />} />
            </Route>
          </Route>
          <Route path="terms" element={<Terms />} />
          <Route path="bonus-token-reward-program" element={<BonusTokenRewardProgram />} />
          <Route path="categories/:category" element={<Category />} />
          <Route path="/viewall" element={<ViewAll />} />
          <Route path="auto-login" element={<AutoLogin />} />
          <Route path="payment-success" element={<PaymentSuccessPopup onClose={handleClosePaymentSuccessPopup} />} />
          <Route path="payment-failed" element={<PaymentFailedPopup onClose={handleClosePaymentFailedPopup} />} />
        </Route>
      </Routes>
    </>
  );
}

const Routers = () => (
  <Router>
    <AppRouter />
  </Router>
);

export default Routers;
