import React from "react";
import "./HomeBanner.css";
import logo from '../../assets/images/go-win-logo.png'
import heroImageLeft from '../../assets/images/hero-banner-image-1.png'
import heroImageRight from '../../assets/images/hero-banner-image-2.png'
import { useViewport } from '../../hooks/ViewportProvider';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { width } = useViewport();
  const breakpoint = 900;
  const { t } = useTranslation();

  const MobileNav = () => (
    <div className="homepage-mobile">
      <div className="homepage-hero">
        <div className="hero-background">
          <div className="hero-image-left">
            <img alt="Online Poker Games at GO WIN" src={heroImageLeft}></img>
          </div>
          <div className="hero-image-right">
            <img alt="Play your Favorite Games only at GOWIN" src={heroImageRight}></img>
          </div>
        </div>
        <div className="hero-center">
          <span><h1>{t('welcomeTo')}</h1></span>
          <div className="homepage-logo">
            <img alt="GO WIN official logo" src={logo}></img>
          </div>
          <span><h1 className="headerBelowLogo">{t('fortuneFavoursTheBold')}</h1></span>
        </div>
      </div>
    </div>
  );

  const DesktopNav = () => (
    <div className="homepage">
      <div className="homepage-hero">
        <div className="hero-background">
          <div className="hero-image-left">
            <img alt="Online Poker Games at GO WIN" src={heroImageLeft}></img>
          </div>
          <div className="hero-image-right">
            <img alt="Play your Favorite Games only at GOWIN" src={heroImageRight}></img>
          </div>
        </div>
        <div className="hero-center">
          <span><h1>{t('welcomeTo')}</h1></span>
          <div className="homepage-logo">
            <img alt="GO WIN official logo" src={logo}></img>
          </div>
          <span><h1 className="headerBelowLogo">{t('fortuneFavoursTheBold')}</h1></span>
        </div>
      </div>
    </div>
  );

  return width < breakpoint ? <MobileNav /> : <DesktopNav />;

};

export default Home;