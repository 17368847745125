import React from 'react';
import styled from 'styled-components';
import useTranslationHelper from '../../hooks/useTranslationHelper';

// Function to determine CSS class based on value
function getCssClass(value) {
    if (value > 0) return "positive";
    return "negative";
}

// Styled component for cells
const StyledCell = styled.div`
  &.positive {
    color: #17d449 !important;
    font-weight: 600;
  }
  &.negative {
    color: #ff002b;
    font-weight: 600;
  }
`;

const ReferralColumnsProvider = ({ children }) => {
    const t = useTranslationHelper();

    const columns = [
        {
            name: t('username'),
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: t('depositTime'),
            selector: (row) => new Date(row.dateOfDeposit).toLocaleDateString(),
            sortable: true,
        },
        {
            name: t('directBonusAmount'),
            selector: (row) => row.commissionEarned,
            sortable: true,
            cell: (row) => (
                <StyledCell className={getCssClass(row.commissionEarned)}>
                    {row.commissionEarned.toLocaleString()}
                </StyledCell>
            ),
        },
    ];

    return children(columns);
};

export default ReferralColumnsProvider;
