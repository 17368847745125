import React, { useRef, useEffect } from 'react';
import { useViewport } from "../../hooks/ViewportProvider";
import "./PageSubheader.css";

const PageSubheader = ({
  className,
  headerText,
  searchBarValue,
  setSearchBarValue,
}) => {
  const { width } = useViewport();
  const breakpoint = 900;
  const subheaderText = headerText;
  const searchBarRef = useRef(null);

  useEffect(() => {
    if (searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [searchBarValue]);

  const MobileSubheader = () => {
    return (
      <div className={`sub-header mobile ${className}`}>
        <div className="title-container">
          <div className="title">{subheaderText}</div>
        </div>
        {/* <div className="search-bar-container">
          <SearchBar
            className="search-bar"
            searchBarValue={searchBarValue}
            setSearchBarValue={setSearchBarValue}
            inputRef={searchBarRef}
          />
        </div> */}
      </div>
    );
  };


  const DesktopSubheader = () => {
    return (
      <>
        <div className={`sub-header ${className}`}>
          <div className="title-container">
            <div className="title">{headerText}</div>
          </div>
        </div>
        {/* <SearchBar
          className="search-bar"
          searchBarValue={searchBarValue}
          setSearchBarValue={setSearchBarValue}
          inputRef={searchBarRef}
        /> */}
      </>
    );
  };

  return width < breakpoint ? <MobileSubheader /> : <DesktopSubheader />;
};

export default PageSubheader;
