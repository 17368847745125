import React, { useRef, useState, useEffect, useMemo, useReducer } from "react";
import { useSpring, animated } from "react-spring";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EventJoinedButton from "../EventJoinedButton/eventJoinedButton";
import FeaturedGamesButton from "../FeaturedGamesButton/featuredGamesButton";
import { useTranslation } from "react-i18next";
import ViewAllButton from "../ViewAllButton/ViewAllButton";
import { useViewport } from "../../hooks/ViewportProvider";
import "./EventJoinedSection.css";

const initialState = {
  containerWidth: 0,
  singleButtonWidth: 400,
  elementHeight: 0,
  showViewAllButton: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTAINER_WIDTH":
      return {
        ...state,
        containerWidth: action.payload,
      };
    case "SET_ELEMENT_HEIGHT":
      return {
        ...state,
        elementHeight: action.payload,
      };
    case "SET_SHOW_VIEW_ALL_BUTTON":
      return {
        ...state,
        showViewAllButton: action.payload,
      };
    default:
      return state;
  }
};

const EventJoinedSection = ({ sectionName, src, events, alt, page }) => {
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef(null);
  const { width: viewportWidth } = useViewport();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const gap = 20;
  const elementWidth = 400 + gap;

  const responsive = useMemo(
    () => ({
      extraLarge: {
        breakpoint: { max: 4000, min: 1600 },
        items: 4,
        partialVisibilityGutter: 80,
      },
      large: {
        breakpoint: { max: 1800, min: 900 },
        items: 3,
        partialVisibilityGutter: 40,
      },
      medium: {
        breakpoint: { max: 1400, min: 600 },
        items: 2,
        partialVisibilityGutter: 40,
      },
      small: {
        breakpoint: { max: 1000, min: 600 },
        items: 2,
        partialVisibilityGutter: 40,
      },
      mobileSmall: {
        breakpoint: { max: 650, min: 0 },
        items: 1,
        partialVisibilityGutter: 40,
      },
    }),
    []
  );

  useEffect(() => {
    if (viewportWidth <= 800 && viewportWidth > 715) {
      dispatch({ type: "SET_SINGLE_BUTTON_WIDTH", payload: 335 });
    } else if (
      viewportWidth <= 715 &&
      viewportWidth > responsive.mobileSmall.breakpoint.max
    ) {
      dispatch({ type: "SET_SINGLE_BUTTON_WIDTH", payload: 275 });
    }
  }, [viewportWidth, responsive.mobileSmall.breakpoint.max]);

  const slidesToSlide = useMemo(() => {
    switch (true) {
      case viewportWidth <= responsive.mobileSmall.breakpoint.max:
        return responsive.mobileSmall.items;
      case viewportWidth <= responsive.small.breakpoint.max:
        return responsive.small.items;
      case viewportWidth <= responsive.medium.breakpoint.max:
        return responsive.medium.items;
      case viewportWidth <= responsive.large.breakpoint.max:
        return responsive.large.items;
      case viewportWidth <= responsive.extraLarge.breakpoint.max:
        return responsive.extraLarge.items;
      default:
        return 1;
    }
  }, [viewportWidth, responsive]);

  const aspectRatio = 1;

  useEffect(() => {
    const elementHeight = 400; // Fixed height for buttons
    dispatch({
      type: "SET_ELEMENT_HEIGHT",
      payload: elementHeight,
    });
  }, [state.singleButtonWidth, slidesToSlide, aspectRatio, gap]);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.offsetWidth;
      dispatch({ type: "SET_CONTAINER_WIDTH", payload: containerWidth });
      const totalButtonWidth = events.length * elementWidth;
      dispatch({ type: "SET_SHOW_VIEW_ALL_BUTTON", payload: totalButtonWidth > containerWidth });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [events.length, elementWidth]);

  const handleViewAllClick = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const numRows = viewportWidth <= 768 ? Math.ceil((events.length / 2) / slidesToSlide) : Math.ceil(events.length / slidesToSlide);
  const rowHeight = viewportWidth <= 768 ? 185 : 420;

  const calculatedHeight = events.length === 0
    ? 20 // Set the height to 20 if there are no events
    : (showAll ? numRows * rowHeight + gap : state.elementHeight + gap);

  const springProps = useSpring({
    from: { height: state.elementHeight + gap },
    to: { height: calculatedHeight },
    config: { duration: 500 },
  });

  return (
    <>
      <div className="category-header">
        <img className="homeCategoryIcon" src={src} alt={alt} />
        {sectionName}
        {state.showViewAllButton && (
          <ViewAllButton
            onClick={handleViewAllClick}
            text={showAll ? t("viewLess") : t("viewAll")}
          />
        )}
      </div>
      <animated.div
        ref={containerRef}
        style={{ ...springProps, overflow: "hidden" }}
        className={
          slidesToSlide === 1
            ? "event-joined-section-wrapper centering-single-buttons"
            : "event-joined-section-wrapper"
        }
      >
        {showAll ? (
          <div
            className={`${viewportWidth <= 650 ? "event-joined-section-grid-view-mobile" : "event-joined-section-grid-view"
              }`}
          >
            {events.map((event, index) =>
              page === "events" ? (
                <EventJoinedButton
                  key={index}
                  buttonImage={event.image}
                  slidesToSlide={slidesToSlide}
                  singleButtonWidth={state.singleButtonWidth}
                  buttonHeight={state.elementHeight}
                />
              ) : (
                <FeaturedGamesButton
                  key={index}
                  buttonImage={event.image}
                  gameProvider={event.gameProvider}
                  gameName={event.gameName}
                  gameName2={event.gameName2}
                  rootPage={event.rootPage}
                  slidesToSlide={slidesToSlide}
                  singleButtonWidth={state.singleButtonWidth}
                  buttonHeight={state.elementHeight}
                  gameTitle={event.gameTitle}
                  hasLive={event.hasLive}
                />
              )
            )}
          </div>
        ) : (
          <Carousel
            responsive={responsive}
            arrows
            autoPlay={false}
            autoPlaySpeed={500}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay={false}
            showDots={false}
            sliderClass=""
            slidesToSlide={slidesToSlide}
            swipeable
            transitionDuration={1000}
          >
            {events.map((event, index) =>
              page === "events" ? (
                <EventJoinedButton
                  key={index}
                  buttonImage={event.image}
                  slidesToSlide={slidesToSlide}
                  singleButtonWidth={state.singleButtonWidth}
                  buttonHeight={state.elementHeight}
                />
              ) : (
                <FeaturedGamesButton
                  key={index}
                  buttonImage={event.image}
                  gameProvider={event.gameProvider}
                  gameName={event.gameName}
                  gameName2={event.gameName2}
                  rootPage={event.rootPage}
                  slidesToSlide={slidesToSlide}
                  singleButtonWidth={state.singleButtonWidth}
                  buttonHeight={state.elementHeight}
                  gameTitle={event.gameTitle}
                  hasLive={event.hasLive}
                />
              )
            )}
          </Carousel>
        )}
      </animated.div>
    </>
  );
};

export default EventJoinedSection;
