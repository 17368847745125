// src/components/RegisterPopup/RegisterPopup.js

import React, { useState, useEffect } from 'react';
import Close from "../../assets/images/close.svg";
import './RegisterPopup.css';
import Eye from '../../assets/images/eye.png';
import Hidden from '../../assets/images/hidden.png';
import { useTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCountryList, registerUser } from '../../utils/api';
import RegistrationOneTimePassword from '../../components/RegistrationOneTimePassword/RegistrationOneTimePassword';
import { useOtpValidation } from '../../hooks/useOtpValidation'; // Import the custom hook

const RegisterPopup = ({ onClose, onRegister }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState(null);
  const [country, setCountry] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [step, setStep] = useState(1); // Track registration step
  const [countryOptions, setCountryOptions] = useState([]); // Correctly define state
  const [userId, setUserId] = useState(null); // Store userId after registration
  const { t } = useTranslation();

  const { loading, setLoading, otpError, handleOtpValidate, handleResendOtp } = useOtpValidation(t); // Use the custom hook

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await getCountryList();
        const options = data.data.map(country => ({
          value: country.id,
          label: country.name,
        }));
        setCountryOptions(options);
      } catch (error) {
        console.error("Error fetching country list: ", error);
      }
    };

    fetchCountries();
  }, []);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontFamily: 'var(--main-font-family)',
      fontSize: '14px',
      textAlign: 'start',
      fontWeight: 400,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ddd',
      borderRadius: '5px',
      boxSizing: 'border-box',
      fontSize: '14px',
      marginBottom: '10px',
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
      textAlign: 'start',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f5f5f5' : '#e0e0e0',
      color: state.isSelected ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: 'var(--main-font-color)',
        color: '#fff',
      }
    }),
  };

  const validate = () => {
    if (!name.trim()) {
      return t('nameRequired');
    } else if (!/^[a-zA-Z]+\s[a-zA-Z]+$/.test(name)) {
      return t('invalidName');
    }

    if (!username.trim()) {
      return t('usernameRequired');
    } else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(username)) {
      return t('invalidUsername');
    }

    if (!email.trim()) {
      return t('emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return t('emailInvalid');
    }

    if (!confirmEmail.trim()) {
      return t('confirmEmailRequired');
    } else if (confirmEmail !== email) {
      return t('emailsDoNotMatch');
    }

    if (!password) {
      return t('passwordRequired');
    } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/.test(password)) {
      return t('invalidPassword');
    }

    if (!confirmPassword) {
      return t('confirmPasswordRequired');
    } else if (confirmPassword !== password) {
      return t('passwordsDoNotMatch');
    }

    if (!phoneNumber.trim()) {
      return t('phoneNumberRequired');
    } else if (!/^\+?\d+$/.test(phoneNumber)) {
      return t('invalidPhoneNumber');
    }

    if (!dob) {
      return t('dateOfBirthRequired');
    } else {
      const age = new Date().getFullYear() - dob.getFullYear();
      if (age < 18) {
        return t('ageRequirement');
      }
    }

    if (!country) {
      return t('countryRequired');
    }

    if (!termsAccepted) {
      return t('acceptTerms');
    }

    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setMessageType('');

    const validationError = validate();
    if (validationError) {
      setMessage(validationError);
      setMessageType('danger');
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const registerData = {
        name,
        userName: username,
        email,
        confirmEmailAddress: confirmEmail,
        password,
        confirmPassword,
        dateOfBirth: dob,
        phoneNumber,
        countryId: country.value
      };
      const response = await registerUser(registerData);

      if (response.success) {
        setUserId(response.data.userId); // Store the userId
        setStep(2); // Move to OTP verification step
        await handleResendOtp(email); // Create OTP for email
      } else {
        let errorMessage = '';

        if (response.errors && response.errors.length > 0) {
          errorMessage = response.errors.map(error => error.errorMessage).join(' ');
        } else {
          errorMessage = response.message;
        }

        setMessage(errorMessage);
        setMessageType('danger');
      }
    } catch (error) {
      setMessage(t('registrationFailed'));
      setMessageType('danger');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="register-popup-mobile">
      <div className="register-mobile-page-1">
        <div className="register-popup-header">
          {step === 1 && (
            <button className="register-close" onClick={onClose}>
              <img src={Close} alt="close" />
            </button>
          )}
          <h1>{t('register')}</h1>
        </div>
        <div className="register-popup-content">
          {step === 1 && (
            <form onSubmit={handleSubmit} className="register-form">
              <div className="form-row">
                <div className="form-item">
                  <label htmlFor="name" className="register-label">{t('fullName')}</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="register-input"
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="username" className="register-label">{t('username')}</label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="register-input"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-item">
                  <label htmlFor="email" className="register-label">{t('email')}</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="register-input"
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="confirmEmail" className="register-label">{t('confirmEmail')}</label>
                  <input
                    type="email"
                    id="confirmEmail"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                    className="register-input"
                    onPaste={(e) => e.preventDefault()} // Prevent paste
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-item">
                  <label htmlFor="password" className="register-label">{t('password')}</label>
                  <div className="password-input-container">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="register-input"
                    />
                    <img
                      src={passwordVisible ? Eye : Hidden}
                      alt="Toggle Password Visibility"
                      className="password-toggle-icon"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="confirmPassword" className="register-label">{t('confirmPassword')}</label>
                  <div className="password-input-container">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      className="register-input"
                    />
                    <img
                      src={passwordVisible ? Eye : Hidden}
                      alt="Toggle Password Visibility"
                      className="password-toggle-icon"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-item">
                  <label htmlFor="dob" className="register-label">{t('dateOfBirth')}</label>
                  <DatePicker
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    className="register-input"
                    placeholderText={t('selectDate')}
                    required
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="phoneNumber" className="register-label">{t('phoneNumber')}</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    className="register-input"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-item" style={{ width: '100%' }}>
                  <label htmlFor="country" className="register-label">{t('country')}</label>
                  <Select
                    id="country"
                    value={country}
                    onChange={(selectedOption) => setCountry(selectedOption)}
                    options={countryOptions}
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder={t('selectCountry')}
                    isSearchable
                    menuPlacement="top" // Expand upwards
                    required
                  />
                </div>
              </div>
              <div className="terms-acceptance">
                <input
                  type="checkbox"
                  id="termsAccepted"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  className="terms-acceptance-checkbox"
                  required
                />
                <label htmlFor="termsAccepted" className="terms-acceptance-label">
                  <Trans i18nKey="termsOfServiceConfirmation">
                    By signing up I attest that I am at least 18 years old and have read the <a href="/terms" target="_blank" rel="noopener noreferrer" className="terms-link">Terms of Service</a>
                  </Trans>
                </label>
              </div>

              <button className="validate-button" type="submit" disabled={loading}>
                {loading ? <div className="otp-loading-spinner"></div> : t('register')}
              </button>
              {message && (
                <div className={`alert alert-${messageType} mt-3`}>
                  {message}
                </div>
              )}
            </form>
          )}

          {step === 2 && (
            <>
              <h2 className="success-message">
                {t('successfullyRegistered')}
              </h2>
              <div className="alert alert-success mt-3">
                {t('thankYouForRegistering')}
              </div>
              <RegistrationOneTimePassword
                onValidate={(otp) => handleOtpValidate(email, otp, userId, () => setStep(3))}
                onResend={() => handleResendOtp(email)}
                errorMessage={otpError}
                loading={loading}
              />
            </>
          )}

          {step === 3 && (
            <div className="registration-success">
              <h2 className="success-message">{t('accountVerified')}</h2>
              <div className="alert alert-success mt-3">
                {t('youMayNowSignIn')}
              </div>
              <button className="validate-button" onClick={onClose}>
                {t('ok')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterPopup;
