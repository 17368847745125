import React from 'react';
import './SubmitRequestButton.css';

const SubmitRequestButton = ({ onClick, text, loading }) => {
  return (
    <button className="submit-request-button" onClick={onClick}>
      {loading ? <div className="loading-spinner"></div> : <>{text} <span className="arrow">→</span></>}
    </button>
  );
};

export default SubmitRequestButton;
