import React, { useState } from 'react';
import './UserDropdown.css';
import userIcon from '../../assets/images/user-icon.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const UserDropdown = ({ handleLogout }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSettingsClick = () => {
        navigate('/settings/account-info');
    };

    return (
        <div className="userDropdown" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            <button className="userDropdownButton">
                <img src={userIcon} alt="User" className="userIcon" />
            </button>
            <div className={`userDropdownContent ${isOpen ? 'open' : ''}`}>
                <div className="userOption" onClick={handleSettingsClick}>{t('accountSettings')}</div>
                <div className="userOption" onClick={handleLogout}>{t('logout')}</div>
            </div>
        </div>
    );
};

export default UserDropdown;
