import React from 'react';
import './LocalizationSelectorPopup.css';
import Close from "../../assets/images/close.svg";
import TemporaryLanguageList from './TemporaryLanguageList';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


const LocalizationSelectorPopup = ({ setShowLocalizationPopup, setSelectedLanguage, selectedLanguage }) => {
  const { t } = useTranslation();
  function handleLanguageClick(language) {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setShowLocalizationPopup(false);
  }

  return (
    <div className="localization-selector-popup-overlay">
      <div className="localization-selector-content">
        <div className="localization-selector-header">
          <button
            onClick={() => {
              setShowLocalizationPopup(false);
            }}
          >
            <img className="language-close" src={Close} alt="Back button" />
          </button>
          <h1>{t('changeLanguage')}</h1>
        </div>
        <div className="localization-selector-languages-container-div">
          {TemporaryLanguageList.map((language) => {
            const isSelected = language.code.toUpperCase() === (selectedLanguage || '').toUpperCase();
            return (
              <button
                key={language.name}
                onClick={() => handleLanguageClick(language.code)}
                className={isSelected ? 'selected-language' : ''}
              >
                <img src={language.icon} alt={`${language.name} flag`} className="language-flag" />
                <span className="language-name">{language.name}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocalizationSelectorPopup;
