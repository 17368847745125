import React, { useState } from 'react';
import '../DropdownButton/DropdownButton.css';
import GoTokensCoin from '../../assets/images/go_tokens_coin.svg';
import { getBalances } from '../../utils/api';
import { useTranslation } from 'react-i18next';

const MobileDropdownButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [bonusBalance, setBonusBalance] = useState('');
    const [cashBalance, setCashBalance] = useState('');
    const { t } = useTranslation();

    const handleToggle = async () => {
        setIsOpen(prevState => !prevState);
        if (!isOpen) {
            try {
                const { bonusBalance2dp, cashBalance2dp } = await getBalances();
                setBonusBalance(Math.floor(bonusBalance2dp).toLocaleString());
                setCashBalance(Math.floor(cashBalance2dp).toLocaleString());
            } catch (error) {
                console.error('Error getting balances:', error);
            }
        }
    };

    return (
        <div className="dropdown-mobile">
            <button className="dropdownButton" onClick={handleToggle}>
                <span>{t('balances')}</span>
                <img src={GoTokensCoin} alt="Bonus Tokens Coin" className="svgIcon" />
                <svg
                    className="arrowIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    width="20"
                    height="20"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 011.414 1.414l-4 4a1 1 01-1.414 0l-4-4a1 1 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            {isOpen && (
                <div className="dropdownContent open">
                    <div className="balanceItem">
                        <span>{cashBalance}</span>
                        <small>{t('cashWalletUpper')}</small>
                    </div>
                    <div className="balanceItem">
                        <span>{bonusBalance}</span>
                        <small>{t('bonusTokensUpper')}</small>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileDropdownButton;
