import { useState, useEffect } from "react";
import { getBalances, createWithdrawalRequest, createOtp, validateOtp } from "../../utils/api";

export const useFetchBalances = () => {
    const [btcWithdrawalAddress, setBtcWithdrawalAddress] = useState("");
    const [cashBalance, setCashBalance] = useState(0);

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const { cashBalance2dp, btcWithdrawalAddress: btcWithdrawalAddressFromFetch } = await getBalances();
                setCashBalance(Math.floor(cashBalance2dp));
                setBtcWithdrawalAddress(btcWithdrawalAddressFromFetch);
            } catch (error) {
                console.error("Error fetching balances:", error);
            }
        };

        fetchBalance();
    }, []);

    return { btcWithdrawalAddress, cashBalance, setCashBalance };
};

export const useHandleWithdrawal = (setCashBalance) => {
    const emailAddress = localStorage.getItem("emailAddress");
    const [requestedAmount, setRequestedAmount] = useState("");
    const [receivableAmount, setReceivableAmount] = useState("");
    const [withdrawalError, setWithdrawalError] = useState("");
    const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
    const [withdrawalLoading, setWithdrawalLoading] = useState(false);
    const [otpVisible, setOtpVisible] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleRequestedAmountChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setRequestedAmount("");
            setReceivableAmount("");
        } else {
            const wholeNumber = Math.floor(Math.abs(value)); // Ensure the value is a whole number
            setRequestedAmount(wholeNumber);
            setReceivableAmount((wholeNumber * 0.9).toFixed(2)); // Calculate and set receivable amount
        }
        setFormSubmitted(false);
    };

    const handleWithdrawalSubmit = async (event) => {
        event.preventDefault();

        setFormSubmitted(true);

        if (requestedAmount < 100) {
            return;
        }

        setWithdrawalError(""); // Clear any previous error
        setWithdrawalLoading(true);

        try {
            await createOtp(emailAddress);
            setOtpVisible(true); // Show the OTP component
        } catch (error) {
            console.error("Error creating OTP: ", error);
            setWithdrawalError("Failed to send code.");
            setWithdrawalLoading(false);
        }
    };

    const handleOtpClose = async (otp, btcWithdrawalAddress) => {
        setOtpVisible(false);
        if (otp) {
            setWithdrawalLoading(true);
            try {
                const otpResponse = await validateOtp(emailAddress, otp);
                if (otpResponse.success) {
                    const body = {
                        RequestedAmount: requestedAmount,
                        BTCWithdrawalAddress: btcWithdrawalAddress ?? "",
                    };

                    const response = await createWithdrawalRequest(body);
                    if (response.success) {
                        setCashBalance(prevBalance => prevBalance - requestedAmount); // Update the balance
                        setWithdrawalSuccess(true);
                        setTimeout(() => {
                            setWithdrawalSuccess(false);
                        }, 3000);
                        setRequestedAmount("");
                        setReceivableAmount("");
                        setFormSubmitted(false);
                    } else {
                        // Show the specific message from the response if available
                        setWithdrawalError(response.message || "Withdrawal failed.");
                    }
                } else {
                    setWithdrawalError("Invalid code.");
                }
            } catch (error) {
                console.error("Error processing withdrawal request: ", error);
                setWithdrawalError("Withdrawal failed.");
            } finally {
                setWithdrawalLoading(false);
            }
        } else {
            setWithdrawalLoading(false);
        }
    };

    return {
        emailAddress,
        requestedAmount,
        receivableAmount,
        withdrawalError,
        withdrawalSuccess,
        withdrawalLoading,
        otpVisible,
        formSubmitted,
        handleRequestedAmountChange,
        handleWithdrawalSubmit,
        handleOtpClose,
    };
};
