import React, { useContext, useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Context } from "../../App";
import LoginPopup from "../LoginPopup/loginPopup";
import useLogin from '../../hooks/useLogin';

const PrivateRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useContext(Context);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const { login } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
    }
  }, [isLoggedIn]);

  const handleLogin = async (username, password, rememberMe) => {
    const result = await login(username, password);
    if (result.success) {
      if (result.data.data.emailConfirmed) {
        setIsLoggedIn(true);
        setLoginSuccessful(true); // Set login as successful
        return {
          success: true,
          message: "Login successful",
          data: result.data,
        };
      } else {
        return { message: "Not Validated", data: result.data, type: "otp" };
      }
    } else {
      console.error(result.message);
      return { message: result.message, data: result.data, type: "error" };
    }
  };

  const handleClosePopup = () => {
    if (!loginSuccessful) { // Only navigate back if login was not successful
      navigate(-1); // Navigate back one step in the browser history
    }
    setShowLoginPopup(false);
  };

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true);
    setLoginSuccessful(true);
    setShowLoginPopup(false);
  };

  return (
    <>
      {isLoggedIn ? (
        <Outlet />
      ) : (
        showLoginPopup && (
          <LoginPopup
            onClose={handleClosePopup}
            onLogin={handleLogin}
            onSuccessfulLogin={handleSuccessfulLogin}
          />
        )
      )}
    </>
  );
};

export default PrivateRoute;
