import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import "./EventsPageBannerCard.css";

const EventsPageBannerCard = ({ className, buttonImage, text, category, gameName, provider, gameTitle }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const encodedGameName = encodeURIComponent(gameName);
    navigate(`/games/${encodedGameName}`, {
      state: {
        gameProvider: provider,
        gameTitle: gameTitle,
        buttonImage: buttonImage,
        rootPage: 'casino'
      }
    });
  };

  const buttonStyle = {
    "--button-bg-image": `url(${buttonImage})`,
  };

  const showText = category === "sports";

  return (
    <button
      className={`events-page-sub-banner-card ${className}`}
      style={buttonStyle}
      onClick={handleCardClick}
    >
      {showText && <span>{text}</span>}
    </button>
  );
};

EventsPageBannerCard.propTypes = {
  className: PropTypes.string,
  buttonImage: PropTypes.string.isRequired,
  text: PropTypes.string,
  category: PropTypes.string,
  gameName: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

export default EventsPageBannerCard;
