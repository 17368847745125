import React, { useState } from "react";
import DepositCashPopup from "../DepositCashPopup/DepositCashPopup"; // Import the DepositCashPopup component
import BankPaymentPopup from "../DepositCashPopup/BankPaymentPopup"; // Import the BankPaymentPopup component
import BankPaymentOffersPopup from "../DepositCashPopup/BankPaymentOffersPopup"; // Import the BankPaymentOffersPopup component

const PopupHandler = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isBankPopupOpen, setIsBankPopupOpen] = useState(false);
  const [isBankOffersPopupOpen, setIsBankOffersPopupOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [responseData, setResponseData] = useState(null);
  const userId = localStorage.getItem("userId");
  const signedInUsername = localStorage.getItem("signedInUsername");

  const handleDepositCashClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleBankPayment = (amount, responseData) => {
    setAmount(amount);
    setResponseData(responseData);
    setIsPopupOpen(false); // Close the deposit popup
    setIsBankOffersPopupOpen(false); // Close the bank offers popup
    setIsBankPopupOpen(true); // Open the bank payment popup
  };

  const handleCloseBankPaymentPopup = () => {
    setIsBankPopupOpen(false);
  };

  const handleBankOffersPayment = (amount, responseData) => {
    setAmount(amount);
    setResponseData(responseData);
    setIsPopupOpen(false); // Close the deposit popup
    setIsBankOffersPopupOpen(true); // Open the bank payment popup
  };

  const handleCloseBankOffersPaymentPopup = () => {
    setIsBankOffersPopupOpen(false);
  };
  return (
    <>
      {children(handleDepositCashClick)}
      {isPopupOpen && (
        <DepositCashPopup onClose={handleClosePopup} onBankPayment={handleBankOffersPayment} />
      )}
      {isBankPopupOpen && (
        <BankPaymentPopup
          onClose={handleCloseBankPaymentPopup}
          amount={amount}
          responseData={responseData}
          userId={userId}
          signedInUsername={signedInUsername}
        />
      )}
      {isBankOffersPopupOpen && (
        <BankPaymentOffersPopup
          onBankPayment={handleBankPayment}
          onClose={handleCloseBankOffersPaymentPopup}
          amount={amount}
          responseData={responseData}
          userId={userId}
          signedInUsername={signedInUsername}
        />
      )}
    </>
  );
};

export default PopupHandler;
