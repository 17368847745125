import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PartnerButton from '../../components/PartnerButton/partnerButton';
import './PartnersSection.css';

const PartnersSection = React.memo(({ sectionName, partners }) => {
    const navigate = useNavigate();

    const responsive = useMemo(() => ({
        largeDesktop: {
            breakpoint: { max: 4000, min: 1600 },
            items: 7,
            partialVisibilityGutter: 40,
        },
        desktop: {
            breakpoint: { max: 1600, min: 900 },
            items: 5,
            partialVisibilityGutter: 40,
        },
        smallDesktop: {
            breakpoint: { max: 1100, min: 600 },
            items: 3,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 2,
            partialVisibilityGutter: 40,
        },
    }), []);

    const handlePartnerClick = (provider) => {
        navigate('/viewAll', { state: { selectedProviders: [{ value: provider, label: provider }] } });
    };

    return (
        <div className="partners-section">
            <div className="category-header">{sectionName}</div>
            <div className="partners-list-wrapper">
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay
                    autoPlaySpeed={1000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    customTransition="all 1s linear"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay={false}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    transitionDuration={1000}
                >
                    {[...partners, ...partners].map((partner, index) => (
                        <PartnerButton
                            key={index}
                            buttonImage={partner.image}
                            altText={partner.partnerName}
                            onClick={() => handlePartnerClick(partner.partnerName)}
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
});

export default PartnersSection;
