import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './casinoButton.css';

const CasinoButton = ({
  buttonImage,
  gameProvider,
  gameName,
  gameName2,
  rootPage,
  gameTitle,
  hasLive,
  searchBarValue,
  selectedCategories,
  selectedProviders,
  sortOption,
  typeOption,
  category
}) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  const handleClick = () => {
    const encodedGameName2 = encodeURIComponent(gameName2);
    navigate(`/games/${encodedGameName2}`, {
      state: {
        gameProvider,
        rootPage,
        buttonImage,
        gameTitle,
        hasLive,
        searchBarValue,
        selectedCategories,
        selectedProviders,
        sortOption,
        typeOption,
        category
      }
    });
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const buttonStyles = {
    "--button-bg-image": `url(${buttonImage})`
  };

  return (
    <div
      className="casino-button"
      onClick={handleClick}
      style={buttonStyles}
    >
      {!imageError ? (
        <img
          src={buttonImage}
          alt={gameTitle}
          className="hidden-image"
          onError={handleImageError}
        />
      ) : (
        <span className="button-text">{gameTitle}</span>
      )}
    </div>
  );
}

export default CasinoButton;
