import React, { useState, useEffect, useMemo } from "react";
import "./App.css";
import { ViewportProvider } from "./hooks/ViewportProvider";
import background from "./assets/images/background.svg";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { getTokenCheckExpiryAndRefreshIfNeeded } from "./utils/auth";
import Routers from "./Routers/routers";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { Helmet } from 'react-helmet';

export const Context = React.createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const contextValue = useMemo(() => [isLoggedIn, setIsLoggedIn], [isLoggedIn]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.launcher.a8r.games/connector.js";
    script.async = true;
    document.body.appendChild(script);

    const checkAuth = async () => {
      const token = await getTokenCheckExpiryAndRefreshIfNeeded();
      setIsLoggedIn(!!token);
    };

    checkAuth();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const projectId = "7559b8e9ac3f4f6cc77a68fe58c19b13";

  // 2. Set chains
  const mainnet = {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  };

  // 3. Create a metadata object
  const metadata = {
    name: "GoWinStage",
    description: "",
    url: "https://gowinfrontendgowin-staging.azurewebsites.net", // origin must match your domain & subdomain
    icons: ["https://gowinfrontendgowin-staging.azurewebsites.net/favicon.ico"],
  };
  
  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: "...", // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
  });

  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

  return (
    <Context.Provider value={contextValue}>
      <ViewportProvider>
        <div className="App" style={{ backgroundImage: `url(${background})` }}>
          <Helmet> {process.env.REACT_APP_EDUCATION_API_URL === 'https://education-api-staging2.azurewebsites.net' && ( <meta name="robots" content="noindex, nofollow" /> )} </Helmet>
          <Routers />
        </div>
      </ViewportProvider>
    </Context.Provider>
  );
}

export default App;
