import React from "react";
import './terms.css';
import { useViewport } from '../../hooks/ViewportProvider';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { width } = useViewport();
  const breakpoint = 900;
  const { t } = useTranslation();

  const MobileTerms = () => (
    <div className="mobile-terms">
      <p className="terms-header"><strong>{t('termsAndConditionsHeader')}</strong></p>
      <h1><br /><strong>{t('introductionTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText1')}</p>
      <p>{t('termsAndConditionsText2')}</p>
      <p>{t('termsAndConditionsText3')}</p>
      <p>{t('termsAndConditionsText4')}</p>
      <h1><strong>{t('generalTermsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText5')}</p>
      <p>{t('termsAndConditionsText6')}</p>
      <p>{t('termsAndConditionsText7')}</p>
      <p>{t('termsAndConditionsText8')}</p>
      <p>{t('termsAndConditionsText9')}</p>

      <h1><strong>{t('yourObligationsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText10')}</p>
      <p>{t('termsAndConditionsText11')}</p>
      <p>{t('termsAndConditionsText12')}</p>
      <p>{t('termsAndConditionsText13')}</p>
      <p>{t('termsAndConditionsText14')}</p>
      <p>{t('termsAndConditionsText15')}</p>
      <p>{t('termsAndConditionsText16')}</p>
      <p>{t('termsAndConditionsText17')}</p>
      <p>{t('termsAndConditionsText18')}</p>
      <p>{t('termsAndConditionsText19')}</p>
      <p>{t('termsAndConditionsText20')}</p>
      <p>{t('termsAndConditionsText21')}</p>
      <p>{t('termsAndConditionsText22')}</p>

      <h1><strong>{t('restrictedUseTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText23')}</p>
      <p>{t('termsAndConditionsText24')}</p>
      <p>{t('termsAndConditionsText25')}</p>
      <p>{t('termsAndConditionsText26')}</p>
      <p>{t('termsAndConditionsText27')}</p>
      <p>{t('termsAndConditionsText28')}</p>
      <p>{t('termsAndConditionsText29')}</p>
      <p>{t('termsAndConditionsText30')}</p>
      <p>{t('termsAndConditionsText31')}</p>
      <p>{t('termsAndConditionsText32')}</p>
      <p>{t('termsAndConditionsText33')}</p>
      <p>{t('termsAndConditionsText34')}</p>
      <p>{t('termsAndConditionsText35')}</p>
      <p>{t('termsAndConditionsText36')}</p>
      <p>{t('termsAndConditionsText37')}</p>

      <h1><strong>{t('registrationTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText38')}</p>
      <p>{t('termsAndConditionsText39')}</p>
      <p>{t('termsAndConditionsText40')}</p>
      <p>{t('termsAndConditionsText41')}</p>
      <p>{t('termsAndConditionsText42')}</p>
      <p>{t('termsAndConditionsText43')}</p>
      <p>{t('termsAndConditionsText44')}</p>
      <p>{t('termsAndConditionsText45')}</p>
      <p>{t('termsAndConditionsText46')}</p>
      <p>{t('termsAndConditionsText47')}</p>
      <p>{t('termsAndConditionsText48')}</p>
      <p>{t('termsAndConditionsText49')}</p>
      <p>{t('termsAndConditionsText50')}</p>
      <p>{t('termsAndConditionsText51')}</p>
      <p>{t('termsAndConditionsText52')}</p>
      <p>{t('termsAndConditionsText53')}</p>
      <p>{t('termsAndConditionsText54')}</p>
      <p>{t('termsAndConditionsText55')}</p>
      <p>{t('termsAndConditionsText56')}</p>
      <p>{t('termsAndConditionsText57')}</p>
      <p>{t('termsAndConditionsText58')}</p>
      <p>{t('termsAndConditionsText59')}</p>
      <p>{t('termsAndConditionsText60')}</p>
      <p>{t('termsAndConditionsText61')}</p>
      <p>{t('termsAndConditionsText62')}</p>
      <p>{t('termsAndConditionsText63')}</p>
      <p>{t('termsAndConditionsText64')}</p>
      <p>{t('termsAndConditionsText65')}</p>
      <p>{t('termsAndConditionsText66')}</p>
      <p>{t('termsAndConditionsText67')}</p>
      <p>{t('termsAndConditionsText68')}</p>
      <p>{t('termsAndConditionsText69')}</p>
      <p>{t('termsAndConditionsText70')}</p>
      <p>{t('termsAndConditionsText71')}</p>
      <p>{t('termsAndConditionsText72')}</p>
      <h1><strong>{t('yourAccountTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText73')}</p>
      <p>{t('termsAndConditionsText74')}</p>
      <p>{t('termsAndConditionsText75')}</p>
      <p>{t('termsAndConditionsText76')}</p>
      <p>{t('termsAndConditionsText77')}</p>
      <p>{t('termsAndConditionsText78')}</p>
      <p>{t('termsAndConditionsText79')}</p>
      <p>{t('termsAndConditionsText80')}</p>
      <p>{t('termsAndConditionsText81')}</p>
      <p>{t('termsAndConditionsText82')}</p>
      <p>{t('termsAndConditionsText83')}</p>
      <p>{t('termsAndConditionsText84')}</p>
      <p>{t('termsAndConditionsText85')}</p>
      <p>{t('termsAndConditionsText86')}</p>
      <p>{t('termsAndConditionsText87')}</p>
      <p>{t('termsAndConditionsText88')}</p>
      <p>{t('termsAndConditionsText89')}</p>
      <p>{t('termsAndConditionsText90')}</p>
      <p>{t('termsAndConditionsText91')}</p>
      <h1><strong>{t('inactiveAccountsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText92')}</p>
      <p>{t('termsAndConditionsText93')}</p>
      <p>{t('termsAndConditionsText94')}</p>
      <p>{t('termsAndConditionsText95')}</p>
      <h1><strong>{t('depositOfFundsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText96')}</p>
      <p>{t('termsAndConditionsText97')}</p>
      <p>{t('termsAndConditionsText98')}</p>
      <p>{t('termsAndConditionsText99')}</p>
      <p>{t('termsAndConditionsText100')}</p>
      <p>{t('termsAndConditionsText101')}</p>
      <p>{t('termsAndConditionsText102')}</p>
      <p>{t('termsAndConditionsText103')}</p>
      <p>{t('termsAndConditionsText104')}</p>
      <p>{t('termsAndConditionsText105')}</p>
      <p>{t('termsAndConditionsText106')}</p>
      <p>{t('termsAndConditionsText107')}</p>
      <p>{t('termsAndConditionsText108')}</p>
      <p>{t('termsAndConditionsText109')}</p>
      <p>{t('termsAndConditionsText110')}</p>
      <p>{t('termsAndConditionsText111')}</p>
      <p>{t('termsAndConditionsText112')}</p>

      <h1><strong>{t('withdrawalOfFundsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText113')}</p>
      <p>{t('termsAndConditionsText114')}</p>
      <p>{t('termsAndConditionsText115')}</p>
      <p>{t('termsAndConditionsText116')}</p>
      <p>{t('termsAndConditionsText117')}</p>
      <p>{t('termsAndConditionsText118')}</p>
      <p>{t('termsAndConditionsText119')}</p>
      <p>{t('termsAndConditionsText120')}</p>
      <h1><strong>{t('paymentTransactionsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText121')}</p>
      <p>{t('termsAndConditionsText122')}</p>
      <p>{t('termsAndConditionsText123')}</p>
      <p>{t('termsAndConditionsText124')}</p>
      <p>{t('termsAndConditionsText125')}</p>
      <p>{t('termsAndConditionsText126')}</p>
      <h1><strong>{t('errorsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText127')}</p>
      <p>{t('termsAndConditionsText128')}</p>
      <p>{t('termsAndConditionsText129')}</p>
      <p>{t('termsAndConditionsText130')}</p>
      <p>{t('termsAndConditionsText131')}</p>
      <p>{t('termsAndConditionsText132')}</p>
      <p>{t('termsAndConditionsText133')}</p>
      <p>{t('termsAndConditionsText134')}</p>
      <p>{t('termsAndConditionsText135')}</p>
      <p>{t('termsAndConditionsText136')}</p>
      <p>{t('termsAndConditionsText137')}</p>
      <p>{t('termsAndConditionsText138')}</p>
      <p>{t('termsAndConditionsText139')}</p>
      <p>{t('termsAndConditionsText140')}</p>
      <p>{t('termsAndConditionsText141')}</p>
      <p>{t('termsAndConditionsText142')}</p>
      <p>{t('termsAndConditionsText143')}</p>
      <p>{t('termsAndConditionsText144')}</p>
      <p>{t('termsAndConditionsText145')}</p>
      <p>{t('termsAndConditionsText146')}</p>
      <p>{t('termsAndConditionsText147')}</p>
      <p>{t('termsAndConditionsText148')}</p>
      <p>{t('termsAndConditionsText149')}</p>
      <p>{t('termsAndConditionsText150')}</p>
      <p>{t('termsAndConditionsText151')}</p>
      <p>{t('termsAndConditionsText152')}</p>
      <p>{t('termsAndConditionsText153')}</p>
      <p>{t('termsAndConditionsText154')}</p>
      <p>{t('termsAndConditionsText155')}</p>
      <p>{t('termsAndConditionsText156')}</p>
      <p>{t('termsAndConditionsText157')}</p>
      <p>{t('termsAndConditionsText158')}</p>
      <p>{t('termsAndConditionsText159')}</p>
      <p>{t('termsAndConditionsText160')}</p>
      <p>{t('termsAndConditionsText161')}</p>
      <p>{t('termsAndConditionsText162')}</p>

      <h1><strong>{t('rulesOfPlayTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText163')}</p>
      <p>{t('termsAndConditionsText164')}</p>
      <p>{t('termsAndConditionsText165')}</p>
      <p>{t('termsAndConditionsText166')}</p>
      <p>{t('termsAndConditionsText167')}</p>
      <p>{t('termsAndConditionsText168')}</p>
      <p>{t('termsAndConditionsText169')}</p>
      <p>{t('termsAndConditionsText170')}</p>
      <p>{t('termsAndConditionsText171')}</p>
      <p>{t('termsAndConditionsText172')}</p>
      <p>{t('termsAndConditionsText173')}</p>
      <p>{t('termsAndConditionsText174')}</p>
      <p>{t('termsAndConditionsText175')}</p>
      <p>{t('termsAndConditionsText176')}</p>
      <p>{t('termsAndConditionsText177')}</p>
      <p>{t('termsAndConditionsText178')}</p>
      <p>{t('termsAndConditionsText179')}</p>
      <p>{t('termsAndConditionsText180')}</p>
      <p>{t('termsAndConditionsText181')}</p>
      <p>{t('termsAndConditionsText182')}</p>
      <p>{t('termsAndConditionsText183')}</p>
      <p>{t('termsAndConditionsText184')}</p>
      <p>{t('termsAndConditionsText185')}</p>
      <p>{t('termsAndConditionsText186')}</p>
      <p>{t('termsAndConditionsText187')}</p>
      <p>{t('termsAndConditionsText188')}</p>
      <p>{t('termsAndConditionsText189')}</p>
      <p>{t('termsAndConditionsText190')}</p>
      <p>{t('termsAndConditionsText191')}</p>
      <p>{t('termsAndConditionsText192')}</p>
      <p>{t('termsAndConditionsText193')}</p>
      <h1><strong>{t('communicationsAndNoticesTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText194')}</p>
      <p>{t('termsAndConditionsText195')}</p>
      <p>{t('termsAndConditionsText196')}</p>
      <p>{t('termsAndConditionsText197')}</p>
      <p>{t('termsAndConditionsText198')}</p>
      <p>{t('termsAndConditionsText199')}</p>
      <p>{t('termsAndConditionsText200')}</p>
      <p>{t('termsAndConditionsText201')}</p>

      <h1><strong>{t('mattersBeyondOurControlTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText202')}</p>
      <p>{t('termsAndConditionsText203')}</p>

      <h1><strong>{t('liabilityTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText204')}</p>
      <p>{t('termsAndConditionsText205')}</p>
      <p>{t('termsAndConditionsText206')}</p>
      <p>{t('termsAndConditionsText207')}</p>
      <p>{t('termsAndConditionsText208')}</p>
      <p>{t('termsAndConditionsText209')}</p>
      <p>{t('termsAndConditionsText210')}</p>
      <p>{t('termsAndConditionsText211')}</p>
      <p>{t('termsAndConditionsText212')}</p>
      <p>{t('termsAndConditionsText213')}</p>
      <p>{t('termsAndConditionsText214')}</p>

      <h1><strong>{t('gamblingByUnderAgeTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText215')}</p>
      <p>{t('termsAndConditionsText216')}</p>
      <p>{t('termsAndConditionsText217')}</p>
      <p>{t('termsAndConditionsText218')}</p>
      <p>{t('termsAndConditionsText219')}</p>
      <p>{t('termsAndConditionsText220')}</p>
      <p>{t('termsAndConditionsText221')}</p>
      <p>{t('termsAndConditionsText222')}</p>
      <p>{t('termsAndConditionsText223')}</p>
      <p>{t('termsAndConditionsText224')}</p>
      <p>{t('termsAndConditionsText225')}</p>
      <p>{t('termsAndConditionsText226')}</p>
      <h1><strong>{t('fraudTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText227')}</p>
      <p>{t('termsAndConditionsText228')}</p>
      <p>{t('termsAndConditionsText229')}</p>
      <p>{t('termsAndConditionsText230')}</p>
      <p>{t('termsAndConditionsText231')}</p>
      <p>{t('termsAndConditionsText232')}</p>
      <p>{t('termsAndConditionsText233')}</p>
      <p>{t('termsAndConditionsText234')}</p>
      <p>{t('termsAndConditionsText235')}</p>
      <p>{t('termsAndConditionsText236')}</p>
      <p>{t('termsAndConditionsText237')}</p>

      <h1><strong>{t('intellectualPropertyTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText238')}</p>
      <p>{t('termsAndConditionsText239')}</p>
      <p>{t('termsAndConditionsText240')}</p>
      <p>{t('termsAndConditionsText241')}</p>
      <p>{t('termsAndConditionsText242')}</p>
      <p>{t('termsAndConditionsText243')}</p>
      <p>{t('termsAndConditionsText244')}</p>
      <p>{t('termsAndConditionsText245')}</p>

      <h1><strong>{t('yourLicenseTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText246')}</p>
      <p>{t('termsAndConditionsText247')}</p>
      <p>{t('termsAndConditionsText248')}</p>
      <p>{t('termsAndConditionsText249')}</p>
      <p>{t('termsAndConditionsText250')}</p>
      <h1><strong>{t('yourConductAndSafetyTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText251')}</p>
      <p>{t('termsAndConditionsText252')}</p>
      <p>{t('termsAndConditionsText253')}</p>
      <p>{t('termsAndConditionsText254')}</p>
      <div>
        <ul>
          <li>{t('termsAndConditionsText255')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText256')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText257')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText258')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText259')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText260')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText261')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText262')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText263')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText264')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText265')}</li>
        </ul>
      </div>
      <div>
        <ul>
          <li>{t('termsAndConditionsText266')}</li>
        </ul>
      </div>
      <p>{t('termsAndConditionsText267')}</p>
      <p>{t('termsAndConditionsText268')}</p>
      <h1><strong>{t('linksToOtherWebsitesTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText269')}</p>
      <p>{t('termsAndConditionsText270')}</p>
      <p>{t('termsAndConditionsText271')}</p>
      <p>{t('termsAndConditionsText272')}</p>
      <p>{t('termsAndConditionsText273')}</p>
      <h1><strong>{t('complaintsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText274')}</p>
      <p>{t('termsAndConditionsText275')}</p>
      <p>{t('termsAndConditionsText276')}</p>
      <p>{t('termsAndConditionsText277')}</p>
      <p>{t('termsAndConditionsText278')}</p>
      <p>{t('termsAndConditionsText279')}</p>
      <p>{t('termsAndConditionsText280')}</p>
      <p>{t('termsAndConditionsText281')}</p>
      <p>{t('termsAndConditionsText282')}</p>
      <p>{t('termsAndConditionsText283')}</p>
      <h1><strong>{t('assignmentTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText284')}</p>
      <p>{t('termsAndConditionsText285')}</p>

      <h1><strong>{t('severabilityTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText286')}</p>
      <p>{t('termsAndConditionsText287')}</p>

      <h1><strong>{t('breachOfTermsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText288')}</p>
      <h1><strong>{t('generalProvisionsTitle')}</strong>&nbsp;</h1>
      <p>{t('termsAndConditionsText289')}</p>
      <p>{t('termsAndConditionsText290')}</p>
      <p>{t('termsAndConditionsText291')}</p>
      <p>{t('termsAndConditionsText292')}</p>
      <p>{t('termsAndConditionsText293')}</p>
      <p>{t('termsAndConditionsText294')}</p>
      <p>{t('termsAndConditionsText295')}</p>
      <p>{t('termsAndConditionsText296')}</p>
      <p>{t('termsAndConditionsText297')}</p>
      <p>{t('termsAndConditionsText298')}</p>
    </div>
  );

  const DesktopTerms = () => (
    <div className="terms">
      <p className="terms-header"><strong>{t('termsAndConditionsHeader')}</strong></p>
      <div className="terms-content">
        <h1><br /><strong>{t('introductionTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText1')}</p>
        <p>{t('termsAndConditionsText2')}</p>
        <p>{t('termsAndConditionsText3')}</p>
        <p>{t('termsAndConditionsText4')}</p>
        <h1><strong>{t('generalTermsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText5')}</p>
        <p>{t('termsAndConditionsText6')}</p>
        <p>{t('termsAndConditionsText7')}</p>
        <p>{t('termsAndConditionsText8')}</p>
        <p>{t('termsAndConditionsText9')}</p>

        <h1><strong>{t('yourObligationsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText10')}</p>
        <p>{t('termsAndConditionsText11')}</p>
        <p>{t('termsAndConditionsText12')}</p>
        <p>{t('termsAndConditionsText13')}</p>
        <p>{t('termsAndConditionsText14')}</p>
        <p>{t('termsAndConditionsText15')}</p>
        <p>{t('termsAndConditionsText16')}</p>
        <p>{t('termsAndConditionsText17')}</p>
        <p>{t('termsAndConditionsText18')}</p>
        <p>{t('termsAndConditionsText19')}</p>
        <p>{t('termsAndConditionsText20')}</p>
        <p>{t('termsAndConditionsText21')}</p>
        <p>{t('termsAndConditionsText22')}</p>

        <h1><strong>{t('restrictedUseTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText23')}</p>
        <p>{t('termsAndConditionsText24')}</p>
        <p>{t('termsAndConditionsText25')}</p>
        <p>{t('termsAndConditionsText26')}</p>
        <p>{t('termsAndConditionsText27')}</p>
        <p>{t('termsAndConditionsText28')}</p>
        <p>{t('termsAndConditionsText29')}</p>
        <p>{t('termsAndConditionsText30')}</p>
        <p>{t('termsAndConditionsText31')}</p>
        <p>{t('termsAndConditionsText32')}</p>
        <p>{t('termsAndConditionsText33')}</p>
        <p>{t('termsAndConditionsText34')}</p>
        <p>{t('termsAndConditionsText35')}</p>
        <p>{t('termsAndConditionsText36')}</p>
        <p>{t('termsAndConditionsText37')}</p>

        <h1><strong>{t('registrationTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText38')}</p>
        <p>{t('termsAndConditionsText39')}</p>
        <p>{t('termsAndConditionsText40')}</p>
        <p>{t('termsAndConditionsText41')}</p>
        <p>{t('termsAndConditionsText42')}</p>
        <p>{t('termsAndConditionsText43')}</p>
        <p>{t('termsAndConditionsText44')}</p>
        <p>{t('termsAndConditionsText45')}</p>
        <p>{t('termsAndConditionsText46')}</p>
        <p>{t('termsAndConditionsText47')}</p>
        <p>{t('termsAndConditionsText48')}</p>
        <p>{t('termsAndConditionsText49')}</p>
        <p>{t('termsAndConditionsText50')}</p>
        <p>{t('termsAndConditionsText51')}</p>
        <p>{t('termsAndConditionsText52')}</p>
        <p>{t('termsAndConditionsText53')}</p>
        <p>{t('termsAndConditionsText54')}</p>
        <p>{t('termsAndConditionsText55')}</p>
        <p>{t('termsAndConditionsText56')}</p>
        <p>{t('termsAndConditionsText57')}</p>
        <p>{t('termsAndConditionsText58')}</p>
        <p>{t('termsAndConditionsText59')}</p>
        <p>{t('termsAndConditionsText60')}</p>
        <p>{t('termsAndConditionsText61')}</p>
        <p>{t('termsAndConditionsText62')}</p>
        <p>{t('termsAndConditionsText63')}</p>
        <p>{t('termsAndConditionsText64')}</p>
        <p>{t('termsAndConditionsText65')}</p>
        <p>{t('termsAndConditionsText66')}</p>
        <p>{t('termsAndConditionsText67')}</p>
        <p>{t('termsAndConditionsText68')}</p>
        <p>{t('termsAndConditionsText69')}</p>
        <p>{t('termsAndConditionsText70')}</p>
        <p>{t('termsAndConditionsText71')}</p>
        <p>{t('termsAndConditionsText72')}</p>
        <h1><strong>{t('yourAccountTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText73')}</p>
        <p>{t('termsAndConditionsText74')}</p>
        <p>{t('termsAndConditionsText75')}</p>
        <p>{t('termsAndConditionsText76')}</p>
        <p>{t('termsAndConditionsText77')}</p>
        <p>{t('termsAndConditionsText78')}</p>
        <p>{t('termsAndConditionsText79')}</p>
        <p>{t('termsAndConditionsText80')}</p>
        <p>{t('termsAndConditionsText81')}</p>
        <p>{t('termsAndConditionsText82')}</p>
        <p>{t('termsAndConditionsText83')}</p>
        <p>{t('termsAndConditionsText84')}</p>
        <p>{t('termsAndConditionsText85')}</p>
        <p>{t('termsAndConditionsText86')}</p>
        <p>{t('termsAndConditionsText87')}</p>
        <p>{t('termsAndConditionsText88')}</p>
        <p>{t('termsAndConditionsText89')}</p>
        <p>{t('termsAndConditionsText90')}</p>
        <p>{t('termsAndConditionsText91')}</p>
        <h1><strong>{t('inactiveAccountsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText92')}</p>
        <p>{t('termsAndConditionsText93')}</p>
        <p>{t('termsAndConditionsText94')}</p>
        <p>{t('termsAndConditionsText95')}</p>
        <h1><strong>{t('depositOfFundsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText96')}</p>
        <p>{t('termsAndConditionsText97')}</p>
        <p>{t('termsAndConditionsText98')}</p>
        <p>{t('termsAndConditionsText99')}</p>
        <p>{t('termsAndConditionsText100')}</p>
        <p>{t('termsAndConditionsText101')}</p>
        <p>{t('termsAndConditionsText102')}</p>
        <p>{t('termsAndConditionsText103')}</p>
        <p>{t('termsAndConditionsText104')}</p>
        <p>{t('termsAndConditionsText105')}</p>
        <p>{t('termsAndConditionsText106')}</p>
        <p>{t('termsAndConditionsText107')}</p>
        <p>{t('termsAndConditionsText108')}</p>
        <p>{t('termsAndConditionsText109')}</p>
        <p>{t('termsAndConditionsText110')}</p>
        <p>{t('termsAndConditionsText111')}</p>
        <p>{t('termsAndConditionsText112')}</p>

        <h1><strong>{t('withdrawalOfFundsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText113')}</p>
        <p>{t('termsAndConditionsText114')}</p>
        <p>{t('termsAndConditionsText115')}</p>
        <p>{t('termsAndConditionsText116')}</p>
        <p>{t('termsAndConditionsText117')}</p>
        <p>{t('termsAndConditionsText118')}</p>
        <p>{t('termsAndConditionsText119')}</p>
        <p>{t('termsAndConditionsText120')}</p>
        <h1><strong>{t('paymentTransactionsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText121')}</p>
        <p>{t('termsAndConditionsText122')}</p>
        <p>{t('termsAndConditionsText123')}</p>
        <p>{t('termsAndConditionsText124')}</p>
        <p>{t('termsAndConditionsText125')}</p>
        <p>{t('termsAndConditionsText126')}</p>
        <h1><strong>{t('errorsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText127')}</p>
        <p>{t('termsAndConditionsText128')}</p>
        <p>{t('termsAndConditionsText129')}</p>
        <p>{t('termsAndConditionsText130')}</p>
        <p>{t('termsAndConditionsText131')}</p>
        <p>{t('termsAndConditionsText132')}</p>
        <p>{t('termsAndConditionsText133')}</p>
        <p>{t('termsAndConditionsText134')}</p>
        <p>{t('termsAndConditionsText135')}</p>
        <p>{t('termsAndConditionsText136')}</p>
        <p>{t('termsAndConditionsText137')}</p>
        <p>{t('termsAndConditionsText138')}</p>
        <p>{t('termsAndConditionsText139')}</p>
        <p>{t('termsAndConditionsText140')}</p>
        <p>{t('termsAndConditionsText141')}</p>
        <p>{t('termsAndConditionsText142')}</p>
        <p>{t('termsAndConditionsText143')}</p>
        <p>{t('termsAndConditionsText144')}</p>
        <p>{t('termsAndConditionsText145')}</p>
        <p>{t('termsAndConditionsText146')}</p>
        <p>{t('termsAndConditionsText147')}</p>
        <p>{t('termsAndConditionsText148')}</p>
        <p>{t('termsAndConditionsText149')}</p>
        <p>{t('termsAndConditionsText150')}</p>
        <p>{t('termsAndConditionsText151')}</p>
        <p>{t('termsAndConditionsText152')}</p>
        <p>{t('termsAndConditionsText153')}</p>
        <p>{t('termsAndConditionsText154')}</p>
        <p>{t('termsAndConditionsText155')}</p>
        <p>{t('termsAndConditionsText156')}</p>
        <p>{t('termsAndConditionsText157')}</p>
        <p>{t('termsAndConditionsText158')}</p>
        <p>{t('termsAndConditionsText159')}</p>
        <p>{t('termsAndConditionsText160')}</p>
        <p>{t('termsAndConditionsText161')}</p>
        <p>{t('termsAndConditionsText162')}</p>

        <h1><strong>{t('rulesOfPlayTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText163')}</p>
        <p>{t('termsAndConditionsText164')}</p>
        <p>{t('termsAndConditionsText165')}</p>
        <p>{t('termsAndConditionsText166')}</p>
        <p>{t('termsAndConditionsText167')}</p>
        <p>{t('termsAndConditionsText168')}</p>
        <p>{t('termsAndConditionsText169')}</p>
        <p>{t('termsAndConditionsText170')}</p>
        <p>{t('termsAndConditionsText171')}</p>
        <p>{t('termsAndConditionsText172')}</p>
        <p>{t('termsAndConditionsText173')}</p>
        <p>{t('termsAndConditionsText174')}</p>
        <p>{t('termsAndConditionsText175')}</p>
        <p>{t('termsAndConditionsText176')}</p>
        <p>{t('termsAndConditionsText177')}</p>
        <p>{t('termsAndConditionsText178')}</p>
        <p>{t('termsAndConditionsText179')}</p>
        <p>{t('termsAndConditionsText180')}</p>
        <p>{t('termsAndConditionsText181')}</p>
        <p>{t('termsAndConditionsText182')}</p>
        <p>{t('termsAndConditionsText183')}</p>
        <p>{t('termsAndConditionsText184')}</p>
        <p>{t('termsAndConditionsText185')}</p>
        <p>{t('termsAndConditionsText186')}</p>
        <p>{t('termsAndConditionsText187')}</p>
        <p>{t('termsAndConditionsText188')}</p>
        <p>{t('termsAndConditionsText189')}</p>
        <p>{t('termsAndConditionsText190')}</p>
        <p>{t('termsAndConditionsText191')}</p>
        <p>{t('termsAndConditionsText192')}</p>
        <p>{t('termsAndConditionsText193')}</p>
        <h1><strong>{t('communicationsAndNoticesTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText194')}</p>
        <p>{t('termsAndConditionsText195')}</p>
        <p>{t('termsAndConditionsText196')}</p>
        <p>{t('termsAndConditionsText197')}</p>
        <p>{t('termsAndConditionsText198')}</p>
        <p>{t('termsAndConditionsText199')}</p>
        <p>{t('termsAndConditionsText200')}</p>
        <p>{t('termsAndConditionsText201')}</p>

        <h1><strong>{t('mattersBeyondOurControlTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText202')}</p>
        <p>{t('termsAndConditionsText203')}</p>

        <h1><strong>{t('liabilityTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText204')}</p>
        <p>{t('termsAndConditionsText205')}</p>
        <p>{t('termsAndConditionsText206')}</p>
        <p>{t('termsAndConditionsText207')}</p>
        <p>{t('termsAndConditionsText208')}</p>
        <p>{t('termsAndConditionsText209')}</p>
        <p>{t('termsAndConditionsText210')}</p>
        <p>{t('termsAndConditionsText211')}</p>
        <p>{t('termsAndConditionsText212')}</p>
        <p>{t('termsAndConditionsText213')}</p>
        <p>{t('termsAndConditionsText214')}</p>

        <h1><strong>{t('gamblingByUnderAgeTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText215')}</p>
        <p>{t('termsAndConditionsText216')}</p>
        <p>{t('termsAndConditionsText217')}</p>
        <p>{t('termsAndConditionsText218')}</p>
        <p>{t('termsAndConditionsText219')}</p>
        <p>{t('termsAndConditionsText220')}</p>
        <p>{t('termsAndConditionsText221')}</p>
        <p>{t('termsAndConditionsText222')}</p>
        <p>{t('termsAndConditionsText223')}</p>
        <p>{t('termsAndConditionsText224')}</p>
        <p>{t('termsAndConditionsText225')}</p>
        <p>{t('termsAndConditionsText226')}</p>
        <h1><strong>{t('fraudTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText227')}</p>
        <p>{t('termsAndConditionsText228')}</p>
        <p>{t('termsAndConditionsText229')}</p>
        <p>{t('termsAndConditionsText230')}</p>
        <p>{t('termsAndConditionsText231')}</p>
        <p>{t('termsAndConditionsText232')}</p>
        <p>{t('termsAndConditionsText233')}</p>
        <p>{t('termsAndConditionsText234')}</p>
        <p>{t('termsAndConditionsText235')}</p>
        <p>{t('termsAndConditionsText236')}</p>
        <p>{t('termsAndConditionsText237')}</p>

        <h1><strong>{t('intellectualPropertyTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText238')}</p>
        <p>{t('termsAndConditionsText239')}</p>
        <p>{t('termsAndConditionsText240')}</p>
        <p>{t('termsAndConditionsText241')}</p>
        <p>{t('termsAndConditionsText242')}</p>
        <p>{t('termsAndConditionsText243')}</p>
        <p>{t('termsAndConditionsText244')}</p>
        <p>{t('termsAndConditionsText245')}</p>

        <h1><strong>{t('yourLicenseTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText246')}</p>
        <p>{t('termsAndConditionsText247')}</p>
        <p>{t('termsAndConditionsText248')}</p>
        <p>{t('termsAndConditionsText249')}</p>
        <p>{t('termsAndConditionsText250')}</p>
        <h1><strong>{t('yourConductAndSafetyTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText251')}</p>
        <p>{t('termsAndConditionsText252')}</p>
        <p>{t('termsAndConditionsText253')}</p>
        <p>{t('termsAndConditionsText254')}</p>
        <div>
          <ul>
            <li>{t('termsAndConditionsText255')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText256')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText257')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText258')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText259')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText260')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText261')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText262')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText263')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText264')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText265')}</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>{t('termsAndConditionsText266')}</li>
          </ul>
        </div>
        <p>{t('termsAndConditionsText267')}</p>
        <p>{t('termsAndConditionsText268')}</p>
        <h1><strong>{t('linksToOtherWebsitesTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText269')}</p>
        <p>{t('termsAndConditionsText270')}</p>
        <p>{t('termsAndConditionsText271')}</p>
        <p>{t('termsAndConditionsText272')}</p>
        <p>{t('termsAndConditionsText273')}</p>
        <h1><strong>{t('complaintsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText274')}</p>
        <p>{t('termsAndConditionsText275')}</p>
        <p>{t('termsAndConditionsText276')}</p>
        <p>{t('termsAndConditionsText277')}</p>
        <p>{t('termsAndConditionsText278')}</p>
        <p>{t('termsAndConditionsText279')}</p>
        <p>{t('termsAndConditionsText280')}</p>
        <p>{t('termsAndConditionsText281')}</p>
        <p>{t('termsAndConditionsText282')}</p>
        <p>{t('termsAndConditionsText283')}</p>
        <h1><strong>{t('assignmentTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText284')}</p>
        <p>{t('termsAndConditionsText285')}</p>

        <h1><strong>{t('severabilityTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText286')}</p>
        <p>{t('termsAndConditionsText287')}</p>

        <h1><strong>{t('breachOfTermsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText288')}</p>
        <h1><strong>{t('generalProvisionsTitle')}</strong>&nbsp;</h1>
        <p>{t('termsAndConditionsText289')}</p>
        <p>{t('termsAndConditionsText290')}</p>
        <p>{t('termsAndConditionsText291')}</p>
        <p>{t('termsAndConditionsText292')}</p>
        <p>{t('termsAndConditionsText293')}</p>
        <p>{t('termsAndConditionsText294')}</p>
        <p>{t('termsAndConditionsText295')}</p>
        <p>{t('termsAndConditionsText296')}</p>
        <p>{t('termsAndConditionsText297')}</p>
        <p>{t('termsAndConditionsText298')}</p>
      </div>
    </div>
  );


  return width < breakpoint ? <MobileTerms /> : <DesktopTerms />;

}

export default Terms