import React from "react";
import "./PaymentSuccessPopup.css";
import Close from "../../assets/images/close.svg"; // Import the Close icon
import Success from "../../assets/images/green_check.png";
const PaymentSuccessPopup = ({ onClose }) => {
    return (
        <div className="payment-success-popup-overlay">
            <div className="payment-success-popup">
                <div className="popup-header">
                    <h2 className="bankPopupHeader">Payment Successful</h2>
                    <button className="close" onClick={onClose}>
                        <img src={Close} alt="close" />
                    </button>
                </div>
                <div className="success-message">
                    <img className="success-tick-image" src={Success} alt="Success" />
                    <h3 className="payment-success-header">Payment Successful!</h3>
                    <p className="payment-success-subtext">Thank you for your deposit.</p>
                </div>
                <div className="buttonSection">
                    <button className="payment-success-close" onClick={onClose}><span>Close</span></button>
                </div>

            </div>
        </div>


    );
};

export default PaymentSuccessPopup;
