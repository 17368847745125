import React, { useMemo, useCallback, useReducer, useRef, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSpring, animated } from "react-spring";
import CategoryButton from "../CategoryButton/categoryButton";
import ViewAllButton from "../../components/ViewAllButton/ViewAllButton";
import { useTranslation } from "react-i18next";
import { useViewport } from "../../hooks/ViewportProvider";
import "./CategorySection.css";

const initialState = {
  containerWidth: 0,
  singleButtonWidth: 300,
  elementHeight: 0,
  initialHeight: 0,
  showViewAllButton: false,
  columns: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CONTAINER_WIDTH":
      return {
        ...state,
        containerWidth: action.payload,
        singleButtonWidth: action.payload - 20,
      };
    case "SET_ELEMENT_HEIGHT":
      return {
        ...state,
        elementHeight: action.payload,
      };
    case "SET_INITIAL_HEIGHT":
      return {
        ...state,
        initialHeight: action.payload,
      };
    case "SET_SHOW_VIEW_ALL_BUTTON":
      return {
        ...state,
        showViewAllButton: action.payload,
      };
    case "SET_COLUMNS":
      return {
        ...state,
        columns: action.payload,
      };
    default:
      return state;
  }
};

const CategorySection = React.memo(({ className, sectionName, categories }) => {
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { width: viewportWidth } = useViewport();
  const gap = 20;
  const elementWidth = 320; // width + gap
  const [state, dispatch] = useReducer(reducer, initialState);

  const responsive = useMemo(
    () => ({
      extraLarge: {
        breakpoint: { max: 4000, min: 1600 },
        items: Math.min(categories.length, 5),
        partialVisibilityGutter: 0,
      },
      large: {
        breakpoint: { max: 1680, min: 900 },
        items: Math.min(categories.length, 4),
        partialVisibilityGutter: 0,
      },
      medium: {
        breakpoint: { max: 1380, min: 600 },
        items: Math.min(categories.length, 3),
        partialVisibilityGutter: 0,
      },
      small: {
        breakpoint: { max: 1080, min: 400 },
        items: Math.min(categories.length, 2),
        partialVisibilityGutter: 0,
      },
      tiny: {
        breakpoint: { max: 670, min: 0 },
        items: Math.min(categories.length, 2),
        partialVisibilityGutter: 0,
      },
    }),
    [categories.length]
  );

  const slidesToSlide = useMemo(() => {
    switch (true) {
      case viewportWidth <= responsive.tiny.breakpoint.max:
        return responsive.tiny.items;
      case viewportWidth <= responsive.small.breakpoint.max:
        return responsive.small.items;
      case viewportWidth <= responsive.medium.breakpoint.max:
        return responsive.medium.items;
      case viewportWidth <= responsive.large.breakpoint.max:
        return responsive.large.items;
      case viewportWidth <= responsive.extraLarge.breakpoint.max:
        return responsive.extraLarge.items;
      default:
        return 1;
    }
  }, [viewportWidth, responsive]);

  const aspectRatio = 75 / 300;

  useEffect(() => {
    const initialHeight = slidesToSlide === 1 ? 300 * aspectRatio + gap : 75 + gap;
    dispatch({
      type: "SET_INITIAL_HEIGHT",
      payload: initialHeight,
    });
    dispatch({
      type: "SET_ELEMENT_HEIGHT",
      payload: initialHeight,
    });
  }, [state.singleButtonWidth, slidesToSlide, aspectRatio, gap]);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.offsetWidth;
      dispatch({ type: "SET_CONTAINER_WIDTH", payload: containerWidth });
      const totalButtonWidth = categories.length * elementWidth;
      dispatch({ type: "SET_SHOW_VIEW_ALL_BUTTON", payload: totalButtonWidth > containerWidth });
      const columns = viewportWidth <= 768 ? 2 : Math.floor(containerWidth / elementWidth);
      dispatch({ type: "SET_COLUMNS", payload: columns });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [categories.length, elementWidth, viewportWidth]);

  const handleViewAllClick = useCallback(() => {
    setShowAll((prevShowAll) => !prevShowAll);
  }, []);

  useEffect(() => {
    const numRows = showAll ? Math.ceil(categories.length / state.columns) : 1;
    const maxHeight = numRows * (75 + gap);
    dispatch({
      type: "SET_ELEMENT_HEIGHT",
      payload: maxHeight,
    });
  }, [showAll, viewportWidth, categories.length, state.columns, gap]);

  const springProps = useSpring({
    from: { height: state.initialHeight },
    to: { height: state.elementHeight },
    config: { duration: 500 },
  });

  const dynamicGridStyle = showAll ? {
    gridTemplateColumns: `repeat(${state.columns}, 1fr)`,
  } : {};

  return (
    <div className={`${className}`}>
      <div className="category-header">
        {sectionName}
        {state.showViewAllButton && (
          <ViewAllButton
            onClick={handleViewAllClick}
            text={showAll ? t("viewLess") : t("viewAll")}
          />
        )}
      </div>
      <animated.div
        ref={containerRef}
        style={{ ...springProps, overflow: "hidden" }}
        className="category-section-wrapper"
      >
        {showAll ? (
          <div className="category-section category-grid-view" style={dynamicGridStyle}>
            {categories.map((category, index) => (
              <CategoryButton
                className="category-button-container"
                key={index}
                text={category.text}
                buttonImage={category.image}
                slidesToSlide={slidesToSlide}
                singleButtonWidth={state.singleButtonWidth}
              />
            ))}
          </div>
        ) : (
          <Carousel
            responsive={responsive}
            arrows
            autoPlay={false}
            autoPlaySpeed={500}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay={false}
            showDots={false}
            partialVisible={true}
            sliderClass=""
            slidesToSlide={slidesToSlide > 3 ? 2 : slidesToSlide}
            swipeable
            transitionDuration={1000}
          >
            {categories.map((category, index) => (
              <CategoryButton
                className="category-button-container"
                key={index}
                text={category.text}
                buttonImage={category.image}
                slidesToSlide={slidesToSlide}
                singleButtonWidth={state.singleButtonWidth}
              />
            ))}
          </Carousel>
        )}
      </animated.div>
    </div>
  );
});

export default CategorySection;