import React from 'react';
import './ProceedButton.css';

const ProceedButton = ({ onClick, text, loading }) => {
  return (
    <button className="proceed-button" onClick={onClick} disabled={loading}>
      {loading ? <div className="proceed-loading-spinner"></div> : <>{text} <span className="arrow">→</span></>}
    </button>
  );
};

export default ProceedButton;
