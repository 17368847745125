import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Utility function to scroll to the top
export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

// Utility function to scroll to the center
export const scrollToCenter = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const centerPosition = (scrollHeight - clientHeight) / 2;
    window.scrollTo({ top: centerPosition, behavior: 'smooth' });
};

// Utility function to scroll to the bottom
export const scrollToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    window.scrollTo({ top: scrollHeight, behavior: 'smooth' });
};

// Component to scroll to the top on route changes
export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToTop();
    }, [pathname]);

    return null;
};

// Component to scroll to the center on route changes
export const ScrollToCenter = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToCenter();
    }, [pathname]);

    return null;
};

// Component to scroll to the bottom on route changes
export const ScrollToBottom = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToBottom();
    }, [pathname]);

    return null;
};
