import React, { useState, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import "./events.css";
import EventsPageBannerCards from "../../components/EventsPageBannerCards/EventsPageBannerCards";
import Subheader from "../../components/PageSubheader/PageSubheader";
import EventSection from '../../components/EventSection/EventSection';
import EventJoinedSection from '../../components/EventJoinedSection/EventJoinedSection';
import { useTranslation } from 'react-i18next';
import { parseJsonToGames, filterGamesByReleaseDate, shuffleArray, generateImageUrl } from '../../utils/gameUtils';
import { loadAllProviderJsonFiles, loadUpcomingJsonFile } from '../../utils/jsonLoader';
import { featureGroupsMapping, providers } from '../../utils/constants';
import { RecentlyPlayedContext } from '../../context/RecentlyPlayedContext';
import eventsIconBlue from "../../assets/images/NavBarIcons/Blue/events-icon-blue.svg";
import SearchBar from "../../components/SearchBar/SearchBar";
import { getTopPlayedLiveGames } from "../../utils/api";
import Meta from '../../utils/Meta';

const Events = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { searchBarValue: initialSearchBarValue } = location.state || {};
  const [searchBarValue, setSearchBarValue] = useState(initialSearchBarValue || '');
  const [liveGames, setLiveGames] = useState([]);
  const [newLiveGames, setNewLiveGames] = useState([]);
  const [fullGamesList, setFullGamesList] = useState([]);
  const [topGames, setTopGames] = useState([]); // Updated state for top games
  const { recentlyPlayed } = useContext(RecentlyPlayedContext);
  const liveGamesSortOption = { value: 'Live Games', label: `${t('liveGames')}` };
  const liveGamesNewReleaseSortOption = { value: 'Release Date', label: `${t('releaseDate')}` };

  useEffect(() => {
    const rootPage = 'livegames';

    const jsonContents = loadAllProviderJsonFiles(); // Load all JSON files
    const upcomingJsonContent = loadUpcomingJsonFile();

    // Load live games
    const parsedLiveGames = providers.flatMap(provider =>
      parseJsonToGames(jsonContents.flat(), rootPage, featureGroupsMapping, provider, { hasLive: true })
    );
    setLiveGames(parsedLiveGames);

    // Load new live games with additional filter of has_live: true
    const allGames = Object.values(upcomingJsonContent).flat();
    const newReleaseGames = filterGamesByReleaseDate(allGames, providers, featureGroupsMapping);
    const parsedNewLiveGames = providers.flatMap(provider =>
      parseJsonToGames(newReleaseGames, rootPage, featureGroupsMapping, provider, { hasLive: true })
    );
    setNewLiveGames(parsedNewLiveGames);

    // Set the full games list
    setFullGamesList(shuffleArray(parsedLiveGames));
  }, []);

  // Fetch top games and generate image URLs
  useEffect(() => {
    async function fetchTopGames() {
      try {
        const topGamesData = await getTopPlayedLiveGames();
        const topGames = topGamesData.data
          .map(gameData => {
            const [provider, gameName] = gameData.gameIdentifier.split(':');
            return {
              image: generateImageUrl(provider, gameName),
              name: gameData.gameIdentifier,
              gameTitle: gameData.gameName,
              provider: provider
            };
          });
        setTopGames(topGames);
      } catch (error) {
        console.error('Error fetching top games: ', error);
      }
    }
    fetchTopGames();
  }, []);

  // Filter the fullGamesList to get the recently played live games
  const recentlyPlayedLiveGames = recentlyPlayed
    .map((recent) =>
      fullGamesList.find((game) => game.gameName === recent.gameName)
    )
    .filter(Boolean); // Filter out undefined values

  const recentlyPlayedLiveGamesWithImages = recentlyPlayedLiveGames.map((game) => ({
    ...game,
    image: game.image.replace('/s2/', '/s3/'),
  }));

  const filteredLiveGames = searchBarValue
    ? liveGames.filter((item) =>
      item.gameTitle.toLowerCase().includes(searchBarValue.toLowerCase())
    )
    : liveGames;

  const filteredNewLiveGames = searchBarValue
    ? newLiveGames.filter((item) =>
      item.gameTitle.toLowerCase().includes(searchBarValue.toLowerCase())
    )
    : newLiveGames;

  const filteredJoinedGames = searchBarValue
    ? recentlyPlayedLiveGamesWithImages.filter((item) =>
      item.gameTitle.toLowerCase().includes(searchBarValue.toLowerCase())
    )
    : recentlyPlayedLiveGamesWithImages;

  return (
    <div>
      <Meta
        title="GO Win: Play Live Casino - Blackjack, Roulette & Win Cash - Join Now!"
        description="Experience live crypto casino games with Gowin Cash. Play blackjack, roulette, and more with real dealers, and win cash prizes. Join now and start winning!"
      />
      <EventsPageBannerCards topGames={topGames} />
      <Subheader
        headerText={t('liveGamesCapital')}
      />
      <div className="home-search-container">
        <SearchBar
          searchBarValue={searchBarValue}
          setSearchBarValue={setSearchBarValue}
        />
      </div>
      <EventSection
        sectionName={t('liveGames')}
        src={eventsIconBlue}
        alt="Events Icon"
        events={filteredLiveGames}
        typeOption={liveGamesSortOption}
      />
      <EventSection
        sectionName={t('newLiveGames')}
        src={eventsIconBlue}
        alt="Events Icon"
        events={filteredNewLiveGames}
        typeOption={liveGamesSortOption}
        sortOption={liveGamesNewReleaseSortOption}
      />
      <EventJoinedSection
        sectionName={t('liveGamesYouJoined')}
        src={eventsIconBlue}
        alt="Events Icon"
        events={filteredJoinedGames}
        page="events"
      />
    </div>
  );
};

export default Events;
