// flagIcons.js
import enFlag from '../assets/images/Flags/en.png';
import arFlag from '../assets/images/Flags/ar.png';
import deFlag from '../assets/images/Flags/de.png';
import esFlag from '../assets/images/Flags/es.png';
import frFlag from '../assets/images/Flags/fr.png';
import itFlag from '../assets/images/Flags/it.png';
import jpFlag from '../assets/images/Flags/jp.png';
import krFlag from '../assets/images/Flags/kr.png';
import plFlag from '../assets/images/Flags/pl.png';
import ptFlag from '../assets/images/Flags/pt.png';
import ruFlag from '../assets/images/Flags/ru.png';
import thFlag from '../assets/images/Flags/th.png';
import trFlag from '../assets/images/Flags/tr.png';
import vnFlag from '../assets/images/Flags/vn.png';
import cnFlag from '../assets/images/Flags/cn.png';

export const getFlagIcon = (languageCode) => {
  if (!languageCode) return null;

  switch (languageCode.toUpperCase()) {
    case 'EN':
      return enFlag;
    case 'AR':
      return arFlag;
    case 'DE':
      return deFlag;
    case 'ES':
      return esFlag;
    case 'FR':
      return frFlag;
    case 'IT':
      return itFlag;
    case 'JP':
      return jpFlag;
    case 'KR':
      return krFlag;
    case 'PL':
      return plFlag;
    case 'PT':
      return ptFlag;
    case 'RU':
      return ruFlag;
    case 'TH':
      return thFlag;
    case 'TR':
      return trFlag;
    case 'VN':
      return vnFlag;
    case 'CN':
      return cnFlag;
    default:
      return null; // Fallback if language code doesn't match
  }
};
