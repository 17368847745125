import React from 'react';
import '../DropdownButton/DropdownButton.css';
import userIcon from '../../assets/images/user-icon.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MobileUserDropdown = ({ isOpen, toggleDropdown, handleLogout }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSettings = () => {
        navigate('/settings/account-info');
        toggleDropdown();
    };

    const handleLogoutClick = () => {
        handleLogout();
        toggleDropdown();
    };

    return (
        <div className="dropdown-mobile">
            <button className="dropdownButton" onClick={toggleDropdown}>
                <span>{t('account')}</span>
                <img src={userIcon} alt="User" className="svgIcon" />
                <svg
                    className="arrowIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    width="20"
                    height="20"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 011.414 1.414l-4 4a1 1 01-1.414 0l-4-4a1 1 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>

            {isOpen && (
                <div className="dropdownContent open">
                    <div className="balanceItem" onClick={handleSettings}>{t('accountSettings')}</div>
                    <div className="balanceItem" onClick={handleLogoutClick}>{t('logout')}</div>
                </div>
            )}
        </div>
    );
};

export default MobileUserDropdown;
