// src/hooks/useLogout.js
import { useCallback } from 'react';
import { logout as logoutApi } from '../utils/api';

const useLogout = () => {
    const logout = useCallback(async () => {
        await logoutApi();
    }, []);

    return { logout };
};

export default useLogout;