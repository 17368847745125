import React from "react";
import "./PaymentFailedPopup.css";
import Close from "../../assets/images/close.svg"; // Import the Close icon
import Failed from "../../assets/images/red_cancel.png";
const PaymentFailedPopup = ({ onClose }) => {
    return (
        <div className="payment-failed-popup-overlay">
            <div className="payment-failed-popup">
                <div className="popup-header">
                    <h2 className="bankPopupHeader">Payment Failed</h2>
                    <button className="close" onClick={onClose}>
                        <img src={Close} alt="close" />
                    </button>
                </div>
                <div className="failed-message">
                    <img className="failed-cross-image" src={Failed} alt="Failed" />
                    <h3 className="payment-failed-header">Payment Failed</h3>
                    <p className="payment-failed-subtext">Payment was unsuccessful.</p>
                </div>
                <div className="buttonSection">
                    <button className="payment-failed-close" onClick={onClose}><span>Close</span></button>
                </div>

            </div>
        </div>


    );
};

export default PaymentFailedPopup;
