// src/components/Meta.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description }) => {
    return (
        <Helmet
            onChangeClientState={(newState) => {
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', description || '');
                }
            }}>
            <title>{title}</title>

        </Helmet>
    );
};

export default Meta;
