import React from "react";
import './BonusTokenRewardProgram.css';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Meta from '../../utils/Meta';

const BonusTokenRewardProgram = () => {
    const { t } = useTranslation();

    return (
        <><Meta
            title="Earn Direct Bonus on every Deposit with GOWIN Affiliate | 2024"
            description="Earn direct bonus on every deposit with the GOWIN Affiliate program. Join now to access exclusive rewards and incentives for your referrals. Share to Earn more."
        />

            <div className="bonus-token-reward-program">
                <h1 className="main-title">{t('bonusTokenRewardTitle')}</h1>
                <div className="bonus-token-reward-program-content">
                    <h2>{t('bonusTokenRewardOverviewTitle')}</h2>
                    <p>{t('bonusTokenRewardWelcomeMessage')}</p>

                    <h2>{t('bonusTokenRewardHowItWorksTitle')}</h2>
                    <p>{t('bonusTokenRewardHowItWorksText')}</p>

                    <h2>{t('bonusTokenRewardCalculationFormulaTitle')}</h2>
                    <p>{t('bonusTokenRewardCalculationFormulaText')}</p>
                    <p className="formula">{t('bonusTokenRewardFormula')}</p>

                    <h2>{t('bonusTokenRewardExampleTitle')}</h2>
                    <p>{t('bonusTokenRewardExampleText')}</p>
                    <p className="formula">{t('bonusTokenRewardExampleCalculation')}</p>

                    <h2>{t('bonusTokenRewardImportantNotesTitle')}</h2>
                    <ul>
                        <li>{t('bonusTokenRewardImportantNote1')}</li>
                        <li>{t('bonusTokenRewardImportantNote2')}</li>
                    </ul>

                    <h2>{t('bonusTokenRewardMinRedemptionTitle')}</h2>
                    <p>{t('bonusTokenRewardMinRedemptionText')}</p>

                    <h2>{t('bonusTokenRewardTermsConditionsTitle')}</h2>
                    <ul>
                        <li>{t('bonusTokenRewardTerm1')}</li>
                        <li>{t('bonusTokenRewardTerm2')}</li>
                        <li>{t('bonusTokenRewardTerm3')}</li>
                        <li>{t('bonusTokenRewardTerm4')}</li>
                    </ul>

                    <h2>{t('bonusTokenRewardFAQTitle')}</h2>
                    <Accordion className="custom-accordion" allowMultipleExpanded="true" allowZeroExpanded="true">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {t('bonusTokenRewardFAQ1Question')}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{t('bonusTokenRewardFAQ1Answer')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {t('bonusTokenRewardFAQ2Question')}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{t('bonusTokenRewardFAQ2Answer')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {t('bonusTokenRewardFAQ3Question')}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{t('bonusTokenRewardFAQ3Answer')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {t('bonusTokenRewardFAQ4Question')}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>{t('bonusTokenRewardFAQ4Answer')}</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

                    <h2>{t('bonusTokenRewardConclusionTitle')}</h2>
                    <p>{t('bonusTokenRewardConclusionText')}</p>

                    <p className="happy-playing">{t('bonusTokenRewardHappyPlaying')}</p>
                    <p className="signature">{t('bonusTokenRewardSignature')}</p>
                </div>
            </div>
        </>
    );
};

export default BonusTokenRewardProgram;
