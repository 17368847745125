import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./AutoLogin.css";
import { useTranslation } from "react-i18next";

const AutoLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const validateToken = async (token) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_EDUCATION_API_URL}/GoWin/validateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          throw new Error("Token validation failed");
        }

        const data = await response.json();
        console.log("Token validation successful");
        const accessToken = data.data.accessToken;
        const username = data.data.userName;
        localStorage.setItem("signedInUsername", username);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("countryCode", data.data.countryCode);
        localStorage.setItem("userId", data.data.userId);
        localStorage.setItem("emailAddress", data.data.email);
        return true;
      } catch (error) {
        console.error("Error during token validation:", error);
        return false;
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const initialAccessToken = searchParams.get("token");

    if (initialAccessToken) {
      validateToken(initialAccessToken).then(isValid => {
        setIsLoading(false); // Stop loading when validation is complete
        navigate("/");
      });
    } else {
      setIsLoading(false); // Stop loading if no token is found
      navigate("/");
    }
  }, [location, navigate]);

  return (
    isLoading ? (
      <div className="loading-wheel">
        <div className="spinner"></div>
        <div className="loading-text">{t("autoLoggingIn")}</div>
      </div>
    ) : null
  );
};

export default AutoLogin;
